<template>
  <div>
    {{ /*view selected trade modal*/ }}
    <b-modal
      id="tradeModal"
      v-model="undoInitiateTradeModal"
      :title="$t('equity_bond.titles.undo_initiate_reversal')"
      @hidden="resetTradeModal"
      size="xl"
      :ok-title="$t('equity_bond.modals.undo_initiate_reversal_modal_ok')"
      :cancel-title="$t('equity_bond.modals.modal_cancel')"
      @ok="tradeOkOperation"
      :body-bg-variant="'light'"
      :header-bg-variant="'dark'"
      class="tradeModal"
      :no-enforce-focus="true"
    >
      <template #modal-header="{ close }">
        <h5 style="color: white;">{{ $t('equity_bond.titles.undo_initiate_reversal') }}</h5>
        <p v-if="!checkVerify">
          <b-icon icon="exclamation-circle-fill" variant="warning"></b-icon>
          <b style="color: white;"> {{$t('equity_bond.modals.trade_not_verified')}}</b>
        </p>

        <p v-else>
          <b-icon icon="bookmark-check-fill" variant="success"></b-icon>
          <b style="color: white;"> {{$t('equity_bond.modals.verified_trade')}} </b>
        </p>
      </template>

      <b-overlay :show="tradeModalLoading" rounded="sm" no-fade>
        <b-container>
          <validation-observer ref="tradeValidation">
            <b-form>
              <!-- /*******************************Order Details***************************************************************** */ -->
              <b-row class="mt-1">
                <b-card :title="$t('equity_bond.titles.order_details')" style="width: 100% !important;">
                  <hr />
                  <b-row>
                    <div class="d-flex" style="width: 100%;">
                      <!-- order date-->
                      <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                        <b-form-group :label="$t('equity_bond.trade_form.order_date')">
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.order_date')"
                            rules="required"
                          >
                            <flat-pickr
                              :disabled="true"
                              :placeholder="$t('equity_bond.trade_form.order_date')"
                              v-model="tradeForm.orderDate"
                              onClose="testClose"
                              class="form-control"
                              :config="dateConfig"
                              ref="orderDate"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- order time-->
                      <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                        <b-form-group :label="$t('equity_bond.trade_form.order_time')">
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.order_time')"
                            rules="required"
                          >
                            <cleave
                              id="orderTime"
                              v-model="tradeForm.orderTime"
                              class="form-control"
                              :raw="false"
                              :options="cleaveOptions.time"
                              :placeholder="$t('equity_bond.trade_form.order_time')"
                              :state="errors.length > 0 ? false : null"
                              :disabled="true"
                              ref="orderTime"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Order duration date -->
                      <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                        <b-form-group :label="$t('equity_bond.trade_form.order_duration_date')" v-if="orderDurationInputsVisible">
                          <validation-provider
                            #default="{ errors }"
                            name="order duration"
                            rules="required"
                          >
                            <b-form-input
                              id="orderDurationDate"
                              v-model="tradeForm.orderDurationDate"
                              name="orderDurationDate"
                              :state="errors.length > 0 ? false : null"
                              placeholder="Order Duration Date"
                              autofocus
                              :disabled="true"
                              ref="orderDurationDate"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Order duration time -->
                      <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                        <b-form-group :label="$t('equity_bond.trade_form.order_duration_time')" v-if="orderDurationInputsVisible">
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.order_duration_time')"
                            rules="required"
                          >
                            <b-form-input
                              id="orderDurationTime"
                              v-model="tradeForm.orderDurationTime"
                              name="orderDurationTime"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="$t('equity_bond.trade_form.order_duration_time')"
                              autofocus
                              :disabled="true"
                              ref="orderDurationTime"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </div>
                    <!-- entry type -->
                    <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                      <b-form-group :label="$t('equity_bond.trade_form.entry_type')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.entry_type')"
                          rules="required"
                        >
                          <v-select
                            :disabled="true"
                            v-model="tradeForm.entryType"
                            :options="optEntryTypes"
                            :clearable="false"
                            :placeholder="$t('equity_bond.trade_form.select_entry_type')"
                            :reduce="(val) => val"
                            ref="entryType"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- buy/sell -->
                    <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                      <b-form-group :label="$t('equity_bond.trade_form.buy_sell')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.buy_sell')"
                          rules="required"
                        >
                          <v-select
                            :disabled="true"
                            v-model="tradeForm.buySell"
                            :options="optbuySells"
                            :clearable="false"
                            :placeholder="$t('equity_bond.trade_form.select_buy_sell')"
                            :reduce="(val) => val"
                            ref="buySell"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- order type-->
                    <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                      <b-form-group :label="$t('equity_bond.trade_form.order_type')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.order_type')"
                          rules="required"
                        >
                          <v-select
                            :disabled="true"
                            v-model="tradeForm.orderType"
                            :options="optOrderTypes"
                            :clearable="false"
                            :placeholder="$t('equity_bond.trade_form.select_order_type')"
                            :reduce="(val) => val"
                            ref="orderType"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- time in force-->
                    <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                      <b-form-group :label="$t('equity_bond.trade_form.time_in_force')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.time_in_force')"
                          rules="required"
                        >
                          <v-select
                            :disabled="true"
                            v-model="tradeForm.timeInForce"
                            :options="optTimeInForces"
                            :clearable="false"
                            :placeholder="$t('equity_bond.trade_form.select_time_in_force')"
                            :reduce="(val) => val"
                            ref="timeInForce"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- cpty order date-->
                    <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                      <b-form-group
                      :label="$t('equity_bond.trade_form.cpty_order_date')"
                        v-if="tradeForm.instrumentType == 'FI'"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.cpty_order_date')"
                          :rules="
                            tradeForm.instrumentType == 'FI' ? 'required' : ''
                          "
                        >
                          <flat-pickr
                            :disabled="true"
                            :placeholder="$t('equity_bond.trade_form.cpty_order_date')"
                            v-model="tradeForm.cptyOrderDate"
                            onClose="testClose"
                            class="form-control"
                            :config="dateConfig"
                            :state="errors.length > 0 ? false : null"
                            ref="cptyOrderDate"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- cpty order time-->
                    <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                      <b-form-group
                      :label="$t('equity_bond.trade_form.cpty_order_time')"
                        v-if="tradeForm.instrumentType == 'FI'"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.cpty_order_time')"
                          :rules="
                            tradeForm.instrumentType == 'FI' ? 'required' : ''
                          "
                        >
                          <cleave
                            id="orderTime"
                            v-model="tradeForm.cptyOrderTime"
                            class="form-control"
                            :raw="false"
                            :options="cleaveOptions.time"
                            :placeholder="$t('equity_bond.trade_form.cpty_order_time')"
                            :state="errors.length > 0 ? false : null"
                            :disabled="true"
                            ref="cptyOrderTime"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Stop Price -->
                    <b-col
                      cols="3"
                      xl="3"
                      lg="3"
                      md="3"
                      sm="12"
                      v-if="
                        tradeForm.orderType == 'Stop' ||
                          tradeForm.orderType == 'Trailing Stop'
                      "
                    >
                      <b-form-group :label="$t('equity_bond.trade_form.stop_price')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.stop_price')"
                          :rules="
                            tradeForm.orderType == 'Stop'
                              ? 'required'
                              : '' || tradeForm.orderType == 'Trailing Stop'
                              ? 'required'
                              : ''
                          "
                        >
                          <cleave
                            id="stopPrice"
                            :state="errors.length > 0 ? false : null"
                            v-model="tradeForm.stopPrice"
                            class="form-control"
                            :raw="true"
                            :options="cleaveOptions.number"
                            :placeholder="$t('equity_bond.trade_form.stop_price')"
                            :disabled="true"
                            ref="stopPrice"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- Trailing Amount -->
                    <b-col
                      cols="3"
                      xl="3"
                      lg="3"
                      md="3"
                      sm="12"
                      v-if="tradeForm.orderType == 'Trailing Stop'"
                    >
                      <b-form-group :label="$t('equity_bond.trade_form.trailing_amount')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.trailing_amount')"
                          :rules="
                            tradeForm.orderType == 'Trailing Stop'
                              ? 'required'
                              : ''
                          "
                        >
                          <b-form-input
                            :disabled="true"
                            id="trailingAmount"
                            v-model="tradeForm.trailingAmount"
                            name="trailingAmount"
                            type="number"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="$t('equity_bond.trade_form.trailing_amount')"
                            autofocus
                            ref="trailingAmount"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <hr />
                    </b-col>
                    <div class="d-flex" style="width: 100%;">
                      <!-- enable connected OCO orders-->
                      <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="order date"
                          >
                            <b-form-checkbox
                              v-model="tradeForm.enableConnectedOCOOrders"
                              :disabled="true"
                            >
                            {{ $t('equity_bond.trade_form.enable_oco') }}
                            </b-form-checkbox>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Take Profit Limit -->
                      <b-col
                        cols="3"
                        xl="3"
                        lg="3"
                        md="3"
                        sm="12"
                        v-if="tradeForm.enableConnectedOCOOrders"
                      >
                        <b-form-group :label="$t('equity_bond.trade_form.take_profit_limit')">
                          <validation-provider
                            #default="{ errors }"
                            :label="$t('equity_bond.trade_form.take_profit_limit')"
                            :rules="
                              tradeForm.enableConnectedOCOOrders
                                ? 'required'
                                : ''
                            "
                          >
                            <cleave
                              id="takeProfitLimit"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.takeProfitLimit"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="$t('equity_bond.trade_form.take_profit_limit')"
                              :disabled="true"
                              ref="takeProfitLimit"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Stop Loss Stop -->
                      <b-col
                        cols="3"
                        xl="3"
                        lg="3"
                        md="3"
                        sm="12"
                        v-if="tradeForm.enableConnectedOCOOrders"
                      >
                        <b-form-group :label="$t('equity_bond.trade_form.stop_loss_stop')">
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.stop_loss_stop')"
                            :rules="
                              tradeForm.enableConnectedOCOOrders
                                ? 'required'
                                : ''
                            "
                          >
                            <cleave
                              id="stopLossStop"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.stopLossStop"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="$t('equity_bond.trade_form.stop_loss_stop')"
                              :disabled="true"
                              ref="stopLossStop"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- Stop Loss Limit -->
                      <b-col
                        cols="3"
                        xl="3"
                        lg="3"
                        md="3"
                        sm="12"
                        v-if="tradeForm.enableConnectedOCOOrders"
                      >
                        <b-form-group :label="$t('equity_bond.trade_form.stop_loss_limit')">
                          <validation-provider
                            #default="{ errors }"
                            :label="$t('equity_bond.trade_form.stop_loss_limit')"
                            :rules="
                              tradeForm.enableConnectedOCOOrders
                                ? 'required'
                                : ''
                            "
                          >
                            <cleave
                              id="stopLossLimit"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.stopLossLimit"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="$t('equity_bond.trade_form.stop_loss_limit')"
                              :disabled="true"
                              ref="stopLossLimit"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </div>
                  </b-row>
                </b-card>
              </b-row>
              <b-row>
                <!-- /******************************* Security Details***************************************************************** */ -->
                <b-col cols="4" sm="12" md="4" lg="4" xl="4" class="p-0">
                  <b-card
                    :title="$t('equity_bond.titles.security_details')"
                    style="width: 100% !important;"
                  >
                    <!-- instrument type-->
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group :label="$t('equity_bond.trade_form.instrument_type')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.instrument_type')"
                          rules="required"
                        >
                          <v-select
                            :disabled="true"
                            v-model="tradeForm.instrumentType"
                            :options="optInstrumentTypes"
                            :class="
                              tradeForm.instrumentType == 'EQ'
                                ? 'eq_color'
                                : 'fi_color'
                            "
                            :clearable="false"
                            :placeholder="$t('equity_bond.trade_form.select_instrument_type')"
                            :reduce="(val) => val"
                            ref="instrumentType"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.cost_center')"
                      >
                      <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.cost_center')"
                          rules="required"
                        >

                    <v-select
                      :disabled="true"
                      v-model="tradeForm.costCenter"
                      :options="optCostCenters"
                      :class="
                        tradeForm.instrumentType == 'EQ'
                          ? 'eq_color'
                          : 'fi_color'
                      "
                      :clearable="false"
                      :placeholder="$t('equity_bond.trade_form.select_cost_center')"
                      :reduce="(val) => val.CostCenter"
                      label = 'CostCenter'

                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                  </b-form-group>
                    </b-col>



                    <!-- security desc-->
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group :label="$t('equity_bond.trade_form.security_desc')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.security_desc')"
                          rules="required"
                        >
                          <v-select
                            :disabled="true"
                            v-model="tradeForm.securityDesc"
                            :options="optSecurityDescriptions"
                            :clearable="false"
                            :placeholder="$t('equity_bond.trade_form.select_security_desc')"
                            :reduce="(val) => val"
                            ref="securityDesc"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- quantity -->
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group :label="$t('equity_bond.trade_form.quantity')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.quantity')"
                          rules="required"
                        >

                          <cleave
                            id="quantity"
                            :state="errors.length > 0 ? false : null"
                            v-model="tradeForm.quantity"
                            class="form-control"
                            :raw="true"
                            :options="cleaveQuantityOptions.number"
                            :placeholder="$t('equity_bond.trade_form.quantity')"
                            @blur.native="calculateAmount"
                            :disabled="true"
                            ref="quantity"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- client price -->
                    <div class="d-flex">
                      <b-col cols="8" xl="8" lg="8" md="8" sm="6">
                        <b-form-group :label="$t('equity_bond.trade_form.client_price')">
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.client_price')"
                            rules="required"
                          >
                            <cleave
                              id="clientPrice"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.clientPrice"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="$t('equity_bond.trade_form.client_price')"
                              :disabled="true"
                              ref="clientPrice"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col cols="4" xl="4" lg="4" md="4" sm="6" class="pl-0">
                        <b-form-group :label="$t('equity_bond.trade_form.price_type')">
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.price_type')"
                            rules="required"
                          >
                            <v-select
                              :disabled="true"
                              v-model="tradeForm.clientPriceType"
                              :options="optClientPriceTypes"
                              :clearable="false"
                              :placeholder="$t('equity_bond.trade_form.price_type')"
                              :reduce="(val) => val"
                              ref="clientPriceType"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </div>
                    <!-- amount -->
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group :label="$t('equity_bond.trade_form.amount')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.amount')"
                          rules="required"
                        >
                          <cleave
                            id="amount"
                            :state="errors.length > 0 ? false : null"
                            v-model="tradeForm.amount"
                            class="form-control"
                            :raw="true"
                            :options="cleaveOptions.number"
                            :placeholder="$t('equity_bond.trade_form.amount')"
                            :disabled="true"
                            ref="amount"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <!-- counterparty price -->
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group
                        :label="$t('equity_bond.trade_form.counterparty_price')"
                        v-if="tradeForm.instrumentType == 'FI'"
                      >
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.counterparty_price')"
                          rules="required"
                        >
                          <b-form-input
                            :disabled="true"
                            id="counterpartyPrice"
                            v-model="tradeForm.counterpartyPrice"
                            name="counterpartyPrice"
                            type="number"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="$t('equity_bond.trade_form.counterparty_price')"
                            autofocus
                            ref="counterpartyPrice"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>

                    <div
                      class="d-flex"
                      style="width: 100% !important;"
                      v-if="tradeForm.instrumentType == 'FI'"
                    >
                      <!-- Clean-->
                      <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                        <b-form-group>
                          <validation-provider
                            #default="{ errors }"
                            name="clean"
                          >
                            <b-form-checkbox v-model="tradeForm.clean">
                              {{$t('equity_bond.trade_form.clean')}}
                            </b-form-checkbox>
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <!-- accrued -->
                      <b-col cols="9" xl="9" lg="9" md="9" sm="12">
                        <b-form-group :label="$t('equity_bond.trade_form.accrued')" v-if="tradeForm.clean">
                          <validation-provider
                            #default="{ errors }"
                            :name="$t('equity_bond.trade_form.accrued')"
                          >
                          <cleave
                              id="accrued"
                              :state="errors.length > 0 ? false : null"
                              v-model="tradeForm.accrued"
                              class="form-control"
                              :raw="true"
                              :options="cleaveOptions.number"
                              :placeholder="
                                $t('equity_bond.trade_form.accrued')
                              "
                              :disabled="true"
                              ref="accrued"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </div>

                    <!-- client -->
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group :label="$t('equity_bond.trade_form.client')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.client')"
                          rules="required"
                        >
                          <v-select
                            :disabled="true"
                            v-model="tradeForm.client"
                            :options="optClients"
                            :clearable="false"
                            :placeholder="$t('equity_bond.trade_form.select_client')"
                            :reduce="(val) => val"
                            ref="client"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- client account -->
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group :label="$t('equity_bond.trade_form.client_account')">
                        <validation-provider
                          #default="{ errors }"
                          :label="$t('equity_bond.trade_form.client_account')"
                          rules="required"
                        >
                          <b-form-input
                            :disabled="true"
                            id="clientAccount"
                            v-model="tradeForm.clientAccount"
                            name="clientAccount"
                            :state="errors.length > 0 ? false : null"
                            :label="$t('equity_bond.trade_form.client_account')"
                            autofocus
                            ref="clientAccount"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- ISIN -->
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group :label="$t('equity_bond.trade_form.isin')">
                        <validation-provider
                          #default="{ errors }"
                          name="ISIN"
                          rules="required"
                        >
                          <b-form-input
                            :disabled="true"
                            id="isin"
                            v-model="tradeForm.isin"
                            :name="$t('equity_bond.trade_form.isin')"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="$t('equity_bond.trade_form.isin')"
                            autofocus
                            ref="isin"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- exchange traded -->
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group :label="$t('equity_bond.trade_form.exchange_traded')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.exchange_traded')"
                          rules="required"
                        >
                          <v-select
                            :disabled="true"
                            v-model="tradeForm.exchangeTraded"
                            :options="optExchangeTrades"
                            :clearable="false"
                            :placeholder="$t('equity_bond.trade_form.select_exchange_traded')"
                            :reduce="(val) => val"
                            ref="exchangeTraded"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- counter party -->
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group :label="$t('equity_bond.trade_form.counterparty')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.counterparty')"
                          rules="required"
                        >
                          <v-select
                            :disabled="true"
                            v-model="tradeForm.counterparty"
                            :options="optCounterParties"
                            :clearable="false"
                            :placeholder="$t('equity_bond.trade_form.select_counterparty')"
                            :reduce="(val) => val"
                            ref="counterparty"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- cpty account -->
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group :label="$t('equity_bond.trade_form.cpty_account')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.cpty_account')"
                          rules="required"
                        >
                          <b-form-input
                            :disabled="true"
                            id="isin"
                            v-model="tradeForm.cptyAccount"
                            name="cpty account"
                            :state="errors.length > 0 ? false : null"
                            :placeholder="$t('equity_bond.trade_form.cpty_account')"
                            autofocus
                            ref="cptyAccount"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- counter party trader -->
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group :label="$t('equity_bond.trade_form.counterparty_trader')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.counterparty_trader')"
                          rules="required"
                        >
                          <v-select
                            :disabled="true"
                            v-model="tradeForm.counterpartyTrader"
                            :options="optCounterPartyTraders"
                            :clearable="false"
                            :placeholder="$t('equity_bond.trade_form.select_counterparty_trader')"
                            :reduce="(val) => val"
                            ref="counterpartyTrader"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- sfc user -->
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group :label="$t('equity_bond.trade_form.sfc_user')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.sfc_user')"
                          rules="required"
                        >
                          <v-select
                            :disabled="true"
                            v-model="tradeForm.scfUser"
                            :options="optScfUsers"
                            :clearable="false"
                            :placeholder="$t('equity_bond.trade_form.select_sfc_user')"
                            :reduce="(val) => val"
                            ref="scfUser"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <!-- acting capacity -->
                    <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                      <b-form-group :label="$t('equity_bond.trade_form.acting_capacity')">
                        <validation-provider
                          #default="{ errors }"
                          :name="$t('equity_bond.trade_form.acting_capacity')"
                          rules="required"
                        >
                          <v-select
                            :disabled="true"
                            v-model="tradeForm.actingCapaticy"
                            :options="optActingCapacities"
                            :clearable="false"
                            :placeholder="$t('equity_bond.trade_form.select_acting_capacity')"
                            :reduce="(val) => val"
                            ref="actingCapaticy"
                          />
                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-card>
                </b-col>

                <b-col cols="8" sm="12" md="8" lg="8" xl="8" class="p-0">
                  <!-- /******************************* Trade Date & Time***************************************************************** */ -->
                  <b-col cols="12" sm="12" mf="12" lg="12" xl="12" class="pr-0">
                    <b-card
                      :title="$t('equity_bond.titles.trade_date_time')"
                      style="width: 100% !important;"
                    >
                      <div class="d-flex">
                        <!-- Trade Date -->
                        <b-col cols="4" xl="4" lg="4" md="4" sm="12">
                          <b-form-group :label="$t('equity_bond.trade_form.trade_date')">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.trade_date')"
                              rules="required"
                            >
                              <flat-pickr
                                :disabled="true"
                                :placeholder="$t('equity_bond.trade_form.trade_date')"
                                v-model="tradeForm.tradeDate"
                                onClose="testClose"
                                class="form-control"
                                :config="dateConfig"
                                ref="tradeDate"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- Settlement Date -->
                        <b-col cols="4" xl="4" lg="4" md="4" sm="12">
                          <b-form-group :label="$t('equity_bond.trade_form.settlement_date')">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.settlement_date')"
                              rules="required"
                            >
                              <flat-pickr
                                :disabled="true"
                                :placeholder="$t('equity_bond.trade_form.settlement_date')"
                                v-model="tradeForm.settlementDate"
                                onClose="testClose"
                                class="form-control"
                                :config="dateConfig"
                                ref="settlementDate"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- execution time -->
                        <b-col cols="4" xl="4" lg="4" md="4" sm="12">
                          <b-form-group :label="$t('equity_bond.trade_form.execution_time')">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.execution_time')"
                              rules="required"
                            >
                              <cleave
                                id="executionTime"
                                v-model="tradeForm.executionTime"
                                class="form-control"
                                :raw="false"
                                :options="cleaveOptions.time"
                                :placeholder="$t('equity_bond.trade_form.execution_time')"
                                :state="errors.length > 0 ? false : null"
                                :disabled="true"
                                ref="executionTime"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </div>
                      <div class="d-flex" style="width: 100%;">
                        <!-- cpty execution time -->
                        <b-col
                          cols="4"
                          xl="4"
                          lg="4"
                          md="4"
                          sm="12"
                          v-if="tradeForm.instrumentType == 'FI'"
                        >
                          <b-form-group :label="$t('equity_bond.trade_form.cpty_execution_time')">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.cpty_execution_time')"
                              :rules="
                                tradeForm.instrumentType == 'FI'
                                  ? 'required'
                                  : ''
                              "
                            >
                              <cleave
                                id="cptyExecutionTime"
                                v-model="tradeForm.cptyExecutionTime"
                                class="form-control"
                                :raw="false"
                                :options="cleaveOptions.time"
                                :placeholder="$t('equity_bond.trade_form.cpty_execution_time')"
                                :state="errors.length > 0 ? false : null"
                                :disabled="true"
                                ref="cptyExecutionTime"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </div>
                    </b-card>
                  </b-col>

                  <!-- /******************************* Trade Details***************************************************************** */ -->

                  <b-col cols="12" sm="12" mf="12" lg="12" xl="12" class="pr-0">
                    <b-card
                      :title="$t('equity_bond.titles.trade_details')"
                      style="width: 100% !important;"
                    >
                      <div class="d-flex">
                        <!-- Client Rate -->
                        <b-col cols="5" xl="5" lg="5" md="5" sm="12">
                          <b-form-group :label="$t('equity_bond.trade_form.client_rate')">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.client_rate')"
                              rules="required"
                            >
                              <b-input-group append="Bps">
                                <b-input-group-prepend is-text>
                                  <b-form-checkbox
                                    plain
                                    name="checkbox-input"
                                    v-model="
                                      tradeForm.calculateCommissionFromRate1
                                    "
                                    :disabled="true"
                                  />
                                </b-input-group-prepend>

                                <cleave
                                  id="clientRate"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="tradeForm.clientRate"
                                  class="form-control"
                                  :raw="true"
                                  :options="cleave6DecimalOptions.number"
                                  :placeholder="$t('equity_bond.trade_form.client_rate')"
                                  :disabled="true"
                                  ref="clientRate"
                                />
                              </b-input-group>

                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- Client Commission -->
                        <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                          <b-form-group :label="$t('equity_bond.trade_form.client_comission')">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.client_comission')"
                            >
                              <cleave
                                id="clientCommission"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.clientCommission"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="$t('equity_bond.trade_form.client_comission')"
                                :disabled="true"
                                @blur.native="calculateClientRate"
                                ref="clientCommission"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- Client amount -->
                        <b-col cols="4" xl="4" lg="4" md="4" sm="12">
                          <b-form-group :label="$t('equity_bond.trade_form.client_amount')">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.client_amount')"
                              rules="required"
                            >
                              <cleave
                                id="clientAmmount"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.clientAmount"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="$t('equity_bond.trade_form.client_amount')"
                                :disabled="true"
                                ref="clientAmount"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </div>
                      <div class="d-flex">
                        <!-- Counterparty Rate -->
                        <b-col cols="5" xl="5" lg="5" md="5" sm="12">
                          <b-form-group :label="$t('equity_bond.trade_form.counterparty_rate')">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.counterparty_rate')"
                              rules="required"
                            >
                              <b-input-group append="Bps">
                                <b-input-group-prepend is-text>
                                  <b-form-checkbox
                                    plain
                                    name="checkbox-input"
                                    v-model="
                                      tradeForm.calculateCommissionFromRate2
                                    "
                                    :disabled="true"
                                  />
                                </b-input-group-prepend>
                                <cleave
                                  id="counterpartyRate"
                                  :state="errors.length > 0 ? false : null"
                                  v-model="tradeForm.counterpartyRate"
                                  class="form-control"
                                  :raw="true"
                                  :options="cleave6DecimalOptions.number"
                                  :placeholder="$t('equity_bond.trade_form.counterparty_rate')"
                                  :disabled="true"
                                  ref="counterpartyRate"
                                />
                              </b-input-group>

                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- cpty comission -->
                        <b-col cols="4" xl="4" lg="4" md="4" sm="12">
                          <b-form-group :label="$t('equity_bond.trade_form.cpty_comission')">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.cpty_comission')"
                            >
                              <cleave
                                id="cptyComission"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.cptyCommission"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="$t('equity_bond.trade_form.cpty_comission')"
                                :disabled="true"
                                @blur.native="calculateCounterpartyRate"
                                ref="cptyCommission"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- counterparty amount -->
                        <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                          <b-form-group :label="$t('equity_bond.trade_form.counterparty_amount')">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.counterparty_amount')"
                              rules="required"
                            >
                              <cleave
                                id="counterpartyAmount"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.counterpartyAmount"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="$t('equity_bond.trade_form.counterparty_amount')"
                                :disabled="true"
                                ref="counterpartyAmount"
                              />

                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </div>
                      <div class="d-flex">
                        <!-- profit -->
                        <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                          <b-form-group :label="$t('equity_bond.trade_form.profit')">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.profit')"
                              rules="required"
                            >
                              <cleave
                                id="profit"
                                :state="errors.length > 0 ? false : null"
                                v-model="tradeForm.profit"
                                class="form-control"
                                :raw="true"
                                :options="cleaveOptions.number"
                                :placeholder="$t('equity_bond.trade_form.profit')"
                                :disabled="true"
                                ref="profit"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- order taken via -->
                        <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                          <b-form-group :label="$t('equity_bond.trade_form.order_taken_via')">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.order_taken_via')"
                              rules="required"
                            >
                              <v-select
                                :disabled="true"
                                v-model="tradeForm.orderTakenVia"
                                :options="optOrderTakenVias"
                                :clearable="false"
                                :placeholder="$t('equity_bond.trade_form.select_order_taken_via')"
                                :reduce="(val) => val"
                                ref="orderTakenVia"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- order taken through -->
                        <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                          <b-form-group :label="$t('equity_bond.trade_form.order_given_through')">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.order_given_through')"
                              rules="required"
                            >
                              <v-select
                                :disabled="true"
                                v-model="tradeForm.orderGivenThrough"
                                :options="optOrderGivenThroughs"
                                :clearable="false"
                                :placeholder="$t('equity_bond.trade_form.select_order_given_through')"
                                :reduce="(val) => val"
                                ref="orderGivenThrough"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- tax -->
                        <b-col cols="3" xl="3" lg="3" md="3" sm="12">
                          <b-form-group
                          :label="$t('equity_bond.trade_form.tax')"
                            v-if="tradeForm.instrumentType == 'EQ'"
                          >
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.tax')"
                            >
                              <b-form-input
                                :disabled="true"
                                id="tax"
                                v-model.number="tradeForm.tax"
                                name="tax"
                                type="number"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="$t('equity_bond.trade_form.tax')"
                                autofocus
                                style="border: 1px solid #191970;"
                                ref="tax"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </div>

                      <div>
                        <!-- note -->
                        <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                          <b-form-group :label="$t('equity_bond.trade_form.note')">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.note')"
                            >
                              <b-form-textarea
                                id="textarea-no-resize"
                                :placeholder="$t('equity_bond.trade_form.note')"
                                rows="3"
                                no-resize
                                v-model="tradeForm.note"
                                :disabled="true"
                                ref="note"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </div>
                      <div>
                        <!-- trade reporting needed -->
                        <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                          <b-form-group>
                            <validation-provider
                              #default="{ errors }"
                              name="trade reporting needed"
                            >
                              <b-form-checkbox
                                v-model="tradeForm.tradeReportingNeeded"
                                :disabled="true"
                              >
                              {{$t('equity_bond.trade_form.trade_reporting_needed')}}
                              </b-form-checkbox>
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </div>
                    </b-card>
                  </b-col>

                  <!-- /******************************* Settlement Details***************************************************************** */ -->
                  <b-col cols="12" sm="12" mf="12" lg="12" xl="12" class="pr-0">
                    <b-card
                      :title="$t('equity_bond.titles.settlement_details')"
                      style="width: 100% !important;"
                    >
                      <div class="d-flex">
                        <!-- UTI  -->
                        <b-col cols="6" xl="6" lg="6" md="6" sm="12">
                          <b-form-group :label="$t('equity_bond.trade_form.uti')">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.uti')"
                            >
                              <b-form-input
                                :disabled="true"
                                id="UTI"
                                v-model="tradeForm.uti"
                                name="UTI"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="$t('equity_bond.trade_form.uti')"
                                autofocus
                                ref="uti"
                              />

                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                        <!-- Unique Link ID -->
                        <b-col cols="6" xl="6" lg="6" md="6" sm="12">
                          <b-form-group :label="$t('equity_bond.trade_form.unique_link_id')">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.unique_link_id')"
                            >
                              <b-form-input
                                :disabled="true"
                                id="uniqueLinkID"
                                v-model.number="tradeForm.uniqueLinkID"
                                type="number"
                                name="uniqueLinkID"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="$t('equity_bond.trade_form.unique_link_id')"
                                autofocus
                                ref="uniqueLinkID"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </div>

                      <div class="d-flex">
                        <!-- Trade Start  -->
                        <!-- <b-col cols="6" xl="6" lg="6" md="6" sm="12">
                            <b-form-group label="Trade Start">
                              <validation-provider
                                #default="{ errors }"
                                name="trade start"
                              >
                                <flat-pickr
                                  :disabled="true"
                                  placeholder="Trade Start"
                                  v-model="tradeForm.tradeStart"
                                  onClose="testClose"
                                  class="form-control"
                                  :config="dateConfig"
                                />
  
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col> -->
                        <!-- Trade end -->
                        <!-- <b-col cols="6" xl="6" lg="6" md="6" sm="12">
                            <b-form-group label="Trade End">
                              <validation-provider
                                #default="{ errors }"
                                name="trade end"
                              >
                                <flat-pickr
                                  :disabled="true"
                                  placeholder="Trade End"
                                  v-model="tradeForm.tradeEnd"
                                  onClose="testClose"
                                  class="form-control"
                                  :config="dateConfig"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col> -->
                      </div>
                      <div class="d-flex">
                        <!-- Settle Start  -->
                        <!-- <b-col cols="6" xl="6" lg="6" md="6" sm="12">
                            <b-form-group label="Settle Start">
                              <validation-provider
                                #default="{ errors }"
                                name="settle start"
                              >
                                <flat-pickr
                                  :disabled="true"
                                  placeholder="Settle Start"
                                  v-model="tradeForm.settleStart"
                                  onClose="testClose"
                                  class="form-control"
                                  :config="dateConfig"
                                />
  
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col> -->
                        <!-- Settle end -->
                        <!-- <b-col cols="6" xl="6" lg="6" md="6" sm="12">
                            <b-form-group label="Settle End">
                              <validation-provider
                                #default="{ errors }"
                                name="settle end"
                              >
                                <flat-pickr
                                  :disabled="true"
                                  placeholder="Settle End"
                                  v-model="tradeForm.settleEnd"
                                  onClose="testClose"
                                  class="form-control"
                                  :config="dateConfig"
                                />
                                <small class="text-danger">{{ errors[0] }}</small>
                              </validation-provider>
                            </b-form-group>
                          </b-col> -->
                      </div>
                      <div>
                        <!-- Trade Ref -->
                        <b-col cols="12" xl="12" lg="12" md="12" sm="12">
                          <b-form-group :label="$t('equity_bond.trade_form.trade_ref')">
                            <validation-provider
                              #default="{ errors }"
                              :name="$t('equity_bond.trade_form.trade_ref')"
                            >
                              <b-form-input
                                :disabled="true"
                                id="tradeRef"
                                v-model="tradeForm.tradeRef"
                                name="tradeRef"
                                :state="errors.length > 0 ? false : null"
                                :placeholder="$t('equity_bond.trade_form.trade_ref')"
                                autofocus
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </b-form-group>
                        </b-col>
                      </div>
                    </b-card>
                  </b-col>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
        </b-container>
        <template #overlay v-if="tradeModalLoading">
          <div
            class="text-center d-flex justify-content-center align-items-start"
          >
            <b-icon icon="stopwatch" font-scale="3" animation="cylon"> </b-icon>
            <p id="createJournal-label">
              <feather-icon icon="DownloadIcon" />
              <b>{{ $t('equity_bond.overlay_messages.undo_reverting_message') }}</b>
            </p>
          </div>
        </template>
      </b-overlay>
      <template #modal-footer>
        <div class="w-100">
          <b-button
            id="tradeAction-button"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="outline-primary"
            size="sm"
            class="float-left mb-0"
            :disabled="actionsData == null || actionsData.length == 0"
          >
            <feather-icon
              icon="EyeIcon"
              size="16"
              style="margin-right: 0.2rem;"
            />

            <span class="text-nowrap">{{ $t('equity_bond.titles.trade_actions') }}</span>
          </b-button>
          <b-popover
            target="tradeAction-button"
            placement="top"
            triggers="click"
            id="actions-popover"
          >
            <template #title>
              {{ $t('equity_bond.titles.trade_actions') }}
            </template>
            <b-table-simple hover small caption-top responsive bordered>
              <b-tbody>
                <b-tr v-for="(action, index) in actionsData" :key="index">
                  <td style="width: 20%;">
                    {{ action.type }}
                  </td>
                  <td style="width: 20%;">
                    {{ action.user }}
                  </td>
                  <td style="width: 30%;">
                    {{ action.date }}
                  </td>
                  <td style="width: 30%;">
                    {{ action.reason }}
                  </td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
          </b-popover>

          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="md"
            class="float-right"
            @click="tradeOkOperation"
          >
            {{ $t('equity_bond.titles.undo_initiate_reversal') }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="secondary"
            size="md"
            class="float-right mr-1"
            @click="resetTradeModal"
          >
          {{ $t('equity_bond.modals.modal_close') }}
          </b-button>
        </div>
      </template>
      {{/****END*** Undo Initiate Reversal for trades modal*/}}
    </b-modal>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
  BPopover,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import equityBondStoreModule from "./EquityBondStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import moment from "moment";
import Cleave from "vue-cleave-component";
import "animate.css";
import _ from "lodash";
import { mapState, mapGetters, mapActions } from "vuex";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    BPopover,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    flatPickr,
    Cleave,
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
  },

  setup() {
    const EQUITYBONDS_APP_STORE_MODULE_NAME = "equity&bond";
    // Register module
    if (!store.hasModule(EQUITYBONDS_APP_STORE_MODULE_NAME))
      store.registerModule(
        EQUITYBONDS_APP_STORE_MODULE_NAME,
        equityBondStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EQUITYBONDS_APP_STORE_MODULE_NAME))
        store.unregisterModule(EQUITYBONDS_APP_STORE_MODULE_NAME);
    });
  },

  mounted() {},
  props: {
     
    searchedCounterparties: {
      type: Array,
        default: function() {
        return [];
      }
    },
    searchedClients: {
      type: Array,
        default: function() {
        return [];
      }
    },
    optCostCenters: {
      type: Array,
        default: function() {
        return [];
      }
    },
    optClients: {
      type: Array,
        default: function() {
        return [];
      }
    },
    optCounterParties: {
      type: Array,
        default: function() {
        return [];
      }
    }
},

  data() {
    return {
      allEquityBondsData: [],
      undoInitiateTradeModal: false,
      tradeModalLoading: false,
      orderDurationDataDisabled:true,
      orderDurationTimeDisabled: true,
      orderDurationInputsVisible: true,
      optEntryTypes: ["Order", "Execution"],
      optbuySells: ["Buy", "Sell", "Sell Short"],
      optOrderTypes: ["Limit", "Market", "Stop Limit", "Stop", "Trailing Stop"],
      optTimeInForces: ["DAY", "GTC", "GTD", "FOK", "FAK"],
      optInstrumentTypes: ["EQ", "FI"],
      optSecurityDescriptions: [],
      optClientPriceTypes: [],
      optExchangeTrades: [],
      optCounterPartyTraders: [],
      optScfUsers: [],
      optActingCapacities: ["PRINCIPAL", "AGENT","MATCHED PRINCIPAL"],
      optOrderTakenVias: [],
      optOrderGivenThroughs: [],
      fetchedTradeData: {},
      actionsData: null,
      checkVerify: false,
      tradeForm: {
        costCenter : null,
        orderDurationDate: null,
        orderDurationTime: null,
        entryType: null,
        buySell: null,
        orderType: null,
        timeInForce: null,
        orderDate: null,
        orderTime: null,
        cptyOrderDate: null,
        cptyOrderTime: null,
        enableConnectedOCOOrders: false,
        stopPrice: null,
        trailingAmount: null,
        takeProfitLimit: null,
        stopLossStop: null,
        stopLossLimit: null,
        instrumentType: null,
        prop: false,
        securityDesc: null,
        quantity: null,
        clientPrice: null,
        clientPriceType: null,
        amount: null,
        counterpartyPrice: null,
        clean: false,
        accrued: null,
        client: null,
        clientAccount: null,
        isin: null,
        exchangeTraded: null,
        counterparty: null,
        cptyAccount: null,
        counterpartyTrader: null,
        scfUser: null,
        actingCapaticy: null,
        tradeDate: null,
        settlementDate: null,
        executionTime: null,
        cptyExecutionTime: null,
        clientRate: null,
        clientCommission: null,
        clientAmount: null,
        counterpartyRate: null,
        tax: null,
        cptyCommission: null,
        counterpartyAmount: null,
        orderTakenVia: null,
        orderGivenThrough: null,
        profit: null,
        tradeReportingNeeded: null,
        note: null,
        calculateCommissionFromRate1: true,
        calculateCommissionFromRate2: true,
        uti: null,
        uniqueLinkID: null,
        tradeRef: null,
        tradeStart: null,
        tradeEnd: null,
        settleStart: null,
        settleEnd: null,
      },
      timeConfig: {
        enableTime: true,
        noCalendar: true,
        dateFormat: "H:i:s",
        time_24hr: true,
      },
      dateConfig: {
        allowInput:true,
        dateFormat: "d-m-Y",
      },
      cleaveOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
        },
        time: {
          time: true,
          timePattern: ["h", "m", "s"],
        },
      },
      cleave6DecimalOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 6,
        },
      },
      cleaveQuantityOptions: {
        number: {
          numeral: true,
          numeralThousandsGroupStyle: "thousand",
          numeralDecimalScale: 0,
          numeralIntegerScale: 0,
        },
      },
    };
  },
  computed: {
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },
  },
  methods: {
    ...mapActions(["saveLastSearchEquityBond"]),
    getArangeEquityBondTCUsers() {
      store
        .dispatch("equity&bond/fetchArangeEquityBondTCUsers", {
          auth: this.user,
          teamName: this.user.role,
        })
        .then((res) => {
          if (res.data.info == "Pass") {
            this.optScfUsers = res.data.users;
          } else if (res.data.info == "no_users") {
            this.errorMessage(this.$t('equity_bond.messages.user_error_no_users'));
          } else {
            this.errorMessage(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getArrangeEquityBondOrderTakenGivenThrough() {
      store
        .dispatch(
          "equity&bond/fetchArrangeEquityBondOrderTakenGivenThrough",
          this.user
        )
        .then((res) => {
          if (
            res.data.orderGivenThroughs.length > 0 ||
            res.data.orderTakenVias.length > 0
          ) {
            this.optOrderGivenThroughs = res.data.orderGivenThroughs;
            this.optOrderTakenVias = res.data.orderTakenVias;
          } else {
            this.errorMessage(this.$t('equity_bond.messages.not_found_given_order_data'));
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getArrangeEquityBondCurrencies() {
      store
        .dispatch("equity&bond/fetchArrangeEquityBondCurrencies", this.user)
        .then((res) => {
          if (res.data.info == "Pass") {
            this.optClientPriceTypes = res.data.currencies;
          } else {
            this.errorMessage(res.data.message);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    tradeOkOperation(bvModalEvt) {
      bvModalEvt.preventDefault();
      const self = this;
      this.$refs.tradeValidation.validate().then((success) => {
        if (success) {
          this.$checkAccessRight("EquityBond", "UndoInitiateReversal").then(
            (res) => {
              if (res.data.valid) {
                if (this.tradeForm.uti != null) {
                  this.tradeModalLoading = true;
                  this.checkEntryReversed(
                    "UndoInitiateReversal",
                    this.tradeForm.uti
                  ).then((res) => {
                    if (res.data.info == true) {
                      this.checkReversalInitiated(
                        "UndoInitiateReversal",
                        this.tradeForm.uti
                      ).then((res) => {
                        if (res.data.info == true) {
                          //set and check trade data
                          let tradeData = self.setTradeData();

                          store
                            .dispatch("equity&bond/undoInitiateReversal", {
                              auth: self.user,
                              trade: tradeData,
                            })
                            .then((res) => {
                              if (res.data.info == "Pass") {
                                self.tradeModalLoading = false;
                                self.resetTradeModal();
                                this.undoReversedSuccessMessage();
                                this.$emit("undoInitiate", true);
                              } else if(res.data.info == 'reversal_initiated_error'){
                                self.errorMessage(this.$t('fx_spot_forward_swap.messages.reversal_initiated_error'));
                                self.tradeModalLoading = false;
                              } else {
                                self.errorMessage(res.data.message);
                                self.tradeModalLoading = false;
                              }
                            })
                            .catch((error) => {
                              console.log(error);
                            });
                        } else {
                          self.checkReversalInitiatedMessage();
                        }
                      });
                    } else {
                      this.checkEntryReversedMessage(res.data.message);
                    }
                  });
                } else {
                  this.notFoundUTIValue(10000);
                }
              } else {
                this.notAuthToastMessage();
              }
            }
          );
        } else {
          let notEnteredDatas = "";

          let checkRequiredDatas = _.map(
            this.$refs.tradeValidation.errors,
            (value, key) => ({ key, value })
          );

          if (checkRequiredDatas.length > 0) {
            let first = true;
            checkRequiredDatas.forEach((r) => {
              if (r.value.length > 0) {
                if (first) {
                  notEnteredDatas += r.key;
                  first = false;
                } else {
                  notEnteredDatas += "," + r.key;
                  first = false;
                }
              }
            });
            this.checkRequiredInputsMessage(notEnteredDatas, 20000);
          }
        }
      });
    },
    checkEntryReversed(messageType, tcUti) {
      if (tcUti != null && tcUti != "") {
        return new Promise((resolve, reject) => {
          store
            .dispatch("equity&bond/checkTCUtiReserved", {
              auth: this.user,
              type: messageType,
              tcUti: tcUti,
            })
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } else {
        this.errorMessage(this.$t('equity_bond.messages.tc_uti_not_found'));
      }
    },
    checkReversalInitiated(messageType, tcUti) {
      if (tcUti != null && tcUti != "") {
        return new Promise((resolve, reject) => {
          store
            .dispatch("equity&bond/checkReversalInitiated", {
              auth: this.user,
              type: messageType,
              tcUti: tcUti,
            })
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } else {
        this.errorMessage(this.$t('equity_bond.messages.tc_uti_not_found'));
      }
    },
    checkJournalCreated(messageType, tcUti) {
      if (tcUti != null && tcUti != "") {
        return new Promise((resolve, reject) => {
          store
            .dispatch("equity&bond/checkTCUtiJournalCreated", {
              auth: this.user,
              type: messageType,
              tcUti: tcUti,
            })
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } else {
        this.errorMessage(this.$t('equity_bond.messages.tc_uti_not_found'));
      }
    },
    changeTimeInForceType() {
      if(this.tradeForm.timeInForce == 'DAY') {
        this.tradeForm.orderDurationDate = this.tradeForm.orderDate
        this.tradeForm.orderDurationTime = moment()
          .endOf("day")
          .format("HH:mm:ss")
          .toString()
        this.orderDurationDataDisabled = true;
        this.orderDurationTimeDisabled = true
        this.orderDurationInputsVisible = true
      }else if(this.tradeForm.timeInForce == 'GTC') {
        this.orderDurationInputsVisible = false
        this.tradeForm.orderDurationDate = null
        this.tradeForm.orderDurationTime = null
      } else if(this.tradeForm.timeInForce == 'GTD') {
        this.tradeForm.orderDurationDate = this.tradeForm.orderDate
        this.tradeForm.orderDurationTime = moment()
          .endOf("day")
          .format("HH:mm:ss")
          .toString()
        this.orderDurationDataDisabled = false;
        this.orderDurationTimeDisabled = false;
        this.orderDurationInputsVisible = true

      }else if(this.tradeForm.timeInForce == 'FOK') {
        this.orderDurationInputsVisible = false
        this.tradeForm.orderDurationDate = null
        this.tradeForm.orderDurationTime = null

      } else if(this.tradeForm.timeInForce == 'FAK') {
        this.orderDurationInputsVisible = false
        this.tradeForm.orderDurationDate = null
        this.tradeForm.orderDurationTime = null

      }
    },
    resetTradeModal() {
      this.undoInitiateTradeModal = false;
      this.fetchedTradeData = {};
      this.actionsData = null;
      this.checkVerify = false;
    },
    undoReversedSuccessMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t('equity_bond.toast_messages.trade_undo_reversed_success'),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    checkEntryReversedMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkReversalInitiatedMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkTradeVerifyOperationMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkJournalOperationsMessage(message, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Warning",
            text: message,
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        },
        {
          timeout,
        }
      );
    },
    checkRequiredInputsMessage(message, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Warning",
            text: this.$t('equity_bond.messages.required_fill_inputs') + ` ${message}`,
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        },
        {
          timeout,
        }
      );
    },
    notAuthToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Not Authorized",
          text: this.$t('equity_bond.general_messages.not_authorized_message'),
          icon: "UserXIcon",
          variant: "warning",
        },
      });
    },
    notFoundUTIValue(timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Not Found UTI",
            text: this.$t('equity_bond.general_messages.not_found_tc_uti'),
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        },
        {
          timeout,
        }
      );
    },
    errorMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    notFoundMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: "Trade is not found",
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    wrongToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: val,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    clearDate() {
      this.newHoliday.date = null;
    },
    DMYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("DD-MM-YYYY");
      }
    },
    MDYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("MM-DD-YYYY");
      }
    },
    YMDdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("YYYY-MM-DD");
      }
    },
    dateFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("DD-MM-YYYY");
      }
    },
    timeFormat(val) {
      if (val != undefined) {
        return moment(String(val), "HH:mm:ss")._i;
      }
    },
    HmstimeFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("HH:mm:ss");
      }
    },
    dateFormatWithTime(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY HH:mm:ss").format(
          "DD-MM-YYYY HH:mm:ss"
        );
      }
    },
    formatPrice(value, val) {
      if (value != "") {
        let val = (value / 1).toFixed(2).replace(",", ".");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    arrangeInputFormat() {
      if (
        this.tradeForm.clientPrice != null &&
        this.tradeForm.clientPrice > 0
      ) {
        this.tradeForm.clientPrice = Number(this.tradeForm.clientPrice).toFixed(
          2
        );
      }

      // if (this.tradeForm.clientRate != null) {
      //   this.tradeForm.clientRate = this.formatPrice2(this.tradeForm.clientRate, 6)
      // }

      // if (this.tradeForm.counterpartyRate != null) {
      //   this.tradeForm.counterpartyRate = this.formatPrice2(this.tradeForm.counterpartyRate, 6)
      // }

      if (this.tradeForm.clientCommission != null) {
        this.tradeForm.clientCommission = Number(
          this.tradeForm.clientCommission
        ).toFixed(2);
      }

      if (this.tradeForm.cptyCommission != null) {
        this.tradeForm.cptyCommission = Number(
          this.tradeForm.cptyCommission
        ).toFixed(2);
      }

      if (this.tradeForm.amount != null) {
        this.tradeForm.amount = Number(this.tradeForm.amount).toFixed(2);
      }

      if (this.tradeForm.counterpartyPrice != null) {
        this.tradeForm.counterpartyPrice = Number(
          this.tradeForm.counterpartyPrice
        ).toFixed(2);
      }
      if (this.tradeForm.counterpartyAmount != null) {
        this.tradeForm.counterpartyAmount = Number(
          this.tradeForm.counterpartyAmount
        ).toFixed(2);
      }
      if (this.tradeForm.clientAmount != null) {
        this.tradeForm.clientAmount = Number(
          this.tradeForm.clientAmount
        ).toFixed(2);
      }
      if (this.tradeForm.stopLossLimit != null) {
        this.tradeForm.stopLossLimit = Number(
          this.tradeForm.stopLossLimit
        ).toFixed(2);
      }
      if (this.tradeForm.stopLossStop != null) {
        this.tradeForm.stopLossStop = Number(
          this.tradeForm.stopLossStop
        ).toFixed(2);
      }
      if (this.tradeForm.takeProfitLimit != null) {
        this.tradeForm.takeProfitLimit = Number(
          this.tradeForm.takeProfitLimit
        ).toFixed(2);
      }
      if (this.tradeForm.stopPrice != null) {
        this.tradeForm.stopPrice = Number(this.tradeForm.stopPrice).toFixed(2);
      }
      if (this.tradeForm.profit != null) {
        this.tradeForm.profit = Number(this.tradeForm.profit).toFixed(2);
      }
      if (this.tradeForm.accrued != null) {
        this.tradeForm.accrued = Number(this.tradeForm.accrued).toFixed(2);
      }
    },
    setTradeData() {
      const self = this;
      let setTrade = {
        costCenter : self.tradeForm.costCenter,

        id: self.tradeForm.id,

        instrumentType: self.tradeForm.instrumentType,
        tradeDate:
          self.tradeForm.tradeDate != null
            ? self.YMDdateFormat(self.tradeForm.tradeDate)
            : null,
        settlementDate:
          self.tradeForm.settlementDate != null
            ? self.YMDdateFormat(self.tradeForm.settlementDate)
            : null,
        orderTime:
          self.tradeForm.orderTime != null
            ? self.timeFormat(self.tradeForm.orderTime)
            : null,
        executionTime:
          self.tradeForm.executionTime != null
            ? self.timeFormat(self.tradeForm.executionTime)
            : null,
        client: self.tradeForm.client,
        account: self.tradeForm.clientAccount,
        securityDesc: self.tradeForm.securityDesc,
        isin: self.tradeForm.isin,
        buySell: self.tradeForm.buySell,
        quantity: self.tradeForm.quantity != null ? self.tradeForm.quantity : 0,
        clientAmount:
          self.tradeForm.clientAmount != null
            ? parseFloat(self.tradeForm.clientAmount)
            : null,
        counterpartyRate:
          self.tradeForm.counterpartyRate != null
            ? parseFloat(self.tradeForm.counterpartyRate)
            : null,
        comissionRate:
          self.tradeForm.clientRate != null
            ? parseFloat(self.tradeForm.clientRate)
            : null,
        counterpartyComission:
          self.tradeForm.cptyCommission != null
            ? parseFloat(self.tradeForm.cptyCommission)
            : null,
        commissionAmount:
          self.tradeForm.clientCommission != null
            ? parseFloat(self.tradeForm.clientCommission)
            : null,
        counterpartyAmount:
          self.tradeForm.counterpartyAmount != null
            ? parseFloat(self.tradeForm.counterpartyAmount)
            : null,
        counterpartyAccount: self.tradeForm.cptyAccount,
        counterpartyTrader: self.tradeForm.counterpartyTrader,
        counterpartyPrice:
          self.tradeForm.counterpartyPrice != null
            ? parseFloat(self.tradeForm.counterpartyPrice)
            : null,
        clean: self.tradeForm.Clean ? 1 : 0,
        accuredInterest:
          self.tradeForm.accrued != null
            ? parseFloat(self.tradeForm.accrued)
            : null,
        ccy: self.tradeForm.clientPriceType,
        price:
          self.tradeForm.clientPrice != null
            ? parseFloat(self.tradeForm.clientPrice)
            : null,
        amount:
          self.tradeForm.amount != null
            ? parseFloat(self.tradeForm.amount)
            : null,
        counterparty: self.tradeForm.counterparty,
        tcUser: self.tradeForm.scfUser,
        orderTakenVia: self.tradeForm.orderTakenVia,
        orderGivenThrough: self.tradeForm.orderGivenThrough,
        orderPrice: null,
        margin: self.tradeForm.tradeReportingNeeded ? 1 : 0,
        exchangeTraded: self.tradeForm.exchangeTraded,
        prop: self.tradeForm.prop ? 1 : 0,
        savedBy: self.user.name ? self.user.name : null,
        saveDate: moment().format("YYYY-MM-DD"),
        saveTime: moment().format("HH:mm:ss"),
        canceled: 0,
        canceledBy: null,
        canceledDate: null,
        canceledTime: null,
        canceledReason: null,
        traderNote: self.tradeForm.note,
        securityAccount: null,
        actingCapaticy: self.tradeForm.actingCapaticy,
        counterpartOrderTime:
          self.tradeForm.cptyOrderTime != null
            ? self.timeFormat(self.tradeForm.cptyOrderTime)
            : null,
        counterpartyExecutionTime:
          self.tradeForm.cptyExecutionTime != null
            ? self.timeFormat(self.tradeForm.cptyExecutionTime)
            : null,
        tax: self.tradeForm.tax != null ? parseFloat(self.tradeForm.tax) : null,
        tcUti: self.tradeForm.uti,
        uniqueLinkId: self.tradeForm.uniqueLinkID,
        tcProfit:
          self.tradeForm.profit != null
            ? parseFloat(self.tradeForm.profit)
            : null,
        entryType: self.tradeForm.entryType,
        orderType: self.tradeForm.orderType,
        timeInForce: self.tradeForm.timeInForce,
        stopPrice:
          self.tradeForm.stopPrice != null
            ? parseFloat(self.tradeForm.stopPrice)
            : null,
        trailingAmount:
          self.tradeForm.trailingAmount != null
            ? parseFloat(self.tradeForm.tradeForm)
            : null,
        takeProfit: null,
        stopLoss:
          self.tradeForm.stopLossStop != null
            ? parseFloat(self.tradeForm.stopLossStop)
            : null,
        orderDate:
          self.tradeForm.orderDate != null
            ? self.YMDdateFormat(self.tradeForm.orderDate)
            : null,
        counterpartyOrderDate:
          self.tradeForm.cptyOrderDate != null
            ? self.YMDdateFormat(self.tradeForm.cptyOrderDate)
            : null,
        orderDurationDate:
          self.tradeForm.orderDurationDate != null
            ? self.YMDdateFormat(self.tradeForm.orderDurationDate)
            : null,
        orderDurationTime:
          self.tradeForm.orderDurationTime != null
            ? self.timeFormat(self.tradeForm.orderDurationTime)
            : null,
        enableOCO:
          self.tradeForm.enableConnectedOCOOrders != null
            ? self.tradeForm.enableConnectedOCOOrders
            : false,
        ocoType: null,
        takeProfitLimit:
          self.tradeForm.takeProfitLimit != null
            ? parseFloat(self.tradeForm.takeProfitLimit)
            : null,
        stopLossLimit:
          self.tradeForm.stopLossLimit != null
            ? parseFloat(self.tradeForm.stopLossLimit)
            : null,
        ocoLink: null,
        orderQuantity: null,
        future4: null,
        future5: null,
        future6: null,
        future7: null,
        future8: null,
        future9: null,
        future10: null,
        future11: null,
        future12: null,
        future13: null,
        future14: null,
        future15: null,
        future16: null,
        ocoLink: self.tradeForm.ocoLink ?? null,
        ocoType: self.tradeForm.ocoType ?? null,
        ID: self.tradeForm.tradeRef,
      };

      return setTrade;
    },
  },
  watch: {
    undoInitiateTradeModal: {
      handler: function(val, before) {
        if (val) {
          this.getArangeEquityBondTCUsers();
          //   this.getArrangeEquityBondCurrencies();
          this.getArrangeEquityBondOrderTakenGivenThrough();
        }
      },
    },
    fetchedTradeData: {
      handler: function(val, before) {
        if (val.ID != 0) {
          this.tradeForm = {
            costCenter:val.CostCenter,

         
            id:val.id != ""
                ? val.id
                : null,

            orderDurationDate:
              val.OrderDurationDate != ""
                ? this.dateFormat(val.OrderDurationDate)
                : null,
            orderDurationTime:
              val.OrderDurationTime != ""
                ? val.OrderDurationTime
                : null,
            entryType: val.EntryType ?? null,
            buySell: val.BuySell ?? null,
            orderType: val.OrderType ?? null,
            timeInForce: val.TimeinForce ?? null,
            orderDate:
              val.OrderDate != "" ? this.dateFormat(val.OrderDate) : null,
            orderTime:
              val.OrderTime != "" ? val.OrderTime : null,
            cptyOrderDate:
              val.CounterpartyOrderDate != ""
                ? this.dateFormat(val.CounterpartyOrderDate)
                : null,
            cptyOrderTime:
              val.CptyOrderTime != ""
                ? val.CptyOrderTime
                : null,
            enableConnectedOCOOrders: val.EnableOCO == "1" ? true : false,
            stopPrice: val.StopPrice != "" ? parseFloat(val.StopPrice) : null,
            trailingAmount:
              val.TrailingAmount != "" ? parseFloat(val.TrailingAmount) : null,
            takeProfitLimit:
              val.TakeProfitLimit != ""
                ? parseFloat(val.TakeProfitLimit)
                : null,
            stopLossStop: val.StopLoss != "" ? parseFloat(val.StopLoss) : null,
            stopLossLimit:
              val.StopLossLimit != "" ? parseFloat(val.StopLossLimit) : null,
            instrumentType: val.InstrumentType ?? null,
            securityDesc: val.SecurityDescription ?? null,
            quantity: val.Quantity ?? null,
            clientPrice: val.Price != "" ? parseFloat(val.Price) : null,
            clientPriceType: val.Ccy ?? null,
            amount: val.Amount != "" ? parseFloat(val.Amount) : null,
            counterpartyPrice:
              val.CounterpartyPrice != ""
                ? parseFloat(val.CounterpartyPrice)
                : null,
            clean: val.Clean == "0" ? false : true,
            accrued: val.AccuredInterest != "" ? val.AccuredInterest : null,
            client: val.Client ?? null,
            clientAccount: val.Account ?? null,
            isin: val.Isin ?? null,
            exchangeTraded: val.ExchangeTraded ?? null,
            counterparty: val.Counterparty ?? null,
            cptyAccount: val.CounterpartyAccount ?? null,
            counterpartyTrader: val.CounterpartyTrader ?? null,
            scfUser: val.TcUser ?? null,
            actingCapaticy: val.ActingCapacity ?? null,
            tradeReportingNeeded: val.margin == "0" ? false : true,
            tradeDate:
              val.TradeDate != "" ? this.dateFormat(val.TradeDate) : null,
            settlementDate:
              val.SettlementDate != ""
                ? this.dateFormat(val.SettlementDate)
                : null,
            executionTime:
              val.ExecutionTime != ""
                ? val.ExecutionTime
                : null,
            cptyExecutionTime:
              val.CptyExecutionTime != ""
                ? val.CptyExecutionTime
                : null,
            clientRate:
              val.CommissionRate != "" ? parseFloat(val.CommissionRate) : null,
            clientCommission:
              val.CommissionAmount != ""
                ? parseFloat(val.CommissionAmount)
                : null,
            clientAmount:
              val.ClientAmount != "" ? parseFloat(val.ClientAmount) : null,
            counterpartyRate:
              val.counterpartyRate != ""
                ? parseFloat(val.CounterpartyRate)
                : null,
            tax: val.Tax != "" ? val.Tax : null,
            cptyCommission:
              val.CounterpartyCommission != ""
                ? parseFloat(val.CounterpartyCommission)
                : null,
            counterpartyAmount:
              val.CounterpartyAmount != ""
                ? parseFloat(val.CounterpartyAmount)
                : null,
            orderTakenVia: val.OrderTakenVia ?? null,
            orderGivenThrough: val.OrderGivenThrough ?? null,
            profit: val.TcProfit != "" ? parseFloat(val.TcProfit) : null,
            tradeReportingNeeded: val.TradeReportingNeeded == 1 ? true : false,
            note: val.TraderNote ?? null,
            calculateCommissionFromRate1:
              val.CalculateCommissionFromRate1 == 1 ? true : false,
            calculateCommissionFromRate2:
              val.CalculateCommissionFromRate2 == 1 ? true : false,
            uti: val.TcUti ?? null,
            uniqueLinkID: val.UniqueLinkId ?? null,
            tradeRef: val.id,
            tradeStart: null,
            tradeEnd: null,
            settleStart: null,
            settleEnd: null,
            ocoLink: val.OCOLink ?? null,
            ocoType: val.OCOType ?? null,
          };
          this.changeTimeInForceType()

          //set trade date name
          let tradeDate = new Date(this.MDYdateFormat(this.tradeForm.tradeDate));
        
          this.tradeForm.tradeDateName = this.weekday[tradeDate.getDay()];
          //set settle date name
          let settleDate = new Date(this.MDYdateFormat(this.tradeForm.settlementDate));
        
          this.tradeForm.settlementDateName = this.weekday[settleDate.getDay()];


          if(this.tradeForm.accrued != null) {
            this.tradeForm.clean = true
          }

          this.arrangeInputFormat()

        }
      },
    },
  },
};
</script>

<style>
#tradeModal .modal-header .modal-title {
  color: white !important;
}

.eq_color .vs__dropdown-toggle .vs__selected-options .vs__selected {
  color: #191970;
}

.fi_color .vs__dropdown-toggle .vs__selected-options .vs__selected {
  color: #cd853f;
}

#actions-popover {
  width: 500px !important;
  max-width: 500px !important;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
