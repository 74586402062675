<template>
  <div>
    <b-card
      class="mb-0 card_body_bottom"
      :title="$t('equity_bond.titles.equity_bond')"
    >
      <b-overlay :show="loading" rounded="sm" no-fade>
        <hr />
        <b-row class="mb-2">
          <b-col cols="12" xl="8" md="8" sm="12">
            <div
              class="d-flex mt-1"
              style="width: 100%;height: 56.39px !important; max-height: 56.39px !important;"
            >
              <b-col cols="6" md="6" xl="6" sm="6">
                <b-input-group>
                  <flat-pickr
                    :placeholder="$t('equity_bond.trade_date')"
                    onClose="testClose"
                    v-model="tradeRangeDate"
                    class="form-control"
                    :config="dateConfig"
                    @on-change="getFilterTradeDate()"
                  />
                  <b-input-group-append>
                    <b-button
                      @click="clearTradeDate()"
                      variant="success"
                      :disabled="
                        typeof lastSearchData != 'Object'
                          ? !lastSearchData.find(
                              (data) => data.user == this.user.name
                            )
                          : true
                      "
                      >{{ $t("general_buttons.clear") }}</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-col>
              <b-col cols="6" md="6" xl="6" sm="6">
                <b-input-group>
                  <flat-pickr
                    :placeholder="$t('equity_bond.settlement_date')"
                    onClose="testClose"
                    v-model="settleRangeDate"
                    class="form-control"
                    :config="dateConfig"
                    @on-change="getFilterSettlementDate"
                    :disabled="
                      typeof lastSearchData != 'Object'
                        ? !lastSearchData.find(
                            (data) => data.user == this.user.name
                          )
                        : true
                    "
                  />
                  <b-input-group-append>
                    <b-button
                      @click="clearSettlementDate()"
                      variant="success"
                      :disabled="
                        typeof lastSearchData != 'Object'
                          ? !lastSearchData.find(
                              (data) => data.user == this.user.name
                            )
                          : true
                      "
                      >{{ $t("general_buttons.clear") }}</b-button
                    >
                  </b-input-group-append>
                </b-input-group>
              </b-col>
            </div>
            <hr class="mt-0" />
            <div
              class="d-flex mt-1"
              style="width: 100%;height: 56.39px !important; max-height: 56.39px !important;"
            >
              <!-- Per Page -->
              <b-col
                cols="12"
                md="3"
                sm="4"
                class="d-flex align-items-center justify-content-start mb-md-0"
              >
                <label>Show</label>
                <v-select
                  v-model="perPage"
                  :options="perPageOptions"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50 w-100"
                />
                <label>entries</label>
              </b-col>
              <!-- filter status -->
              <b-col
                cols="12"
                md="3"
                xl="3"
                sm="4"
                class="d-flex align-items-center justify-content-start mb-md-0"
              >
                <treeselect
                  value-consists-of="LEAF_PRIORITY"
                  :multiple="true"
                  :options="statusOptions"
                  v-model="treeStatus"
                  valueFormat="object"
                  :limit="5"
                  clearable
                  :placeholder="$t('equity_bond.equity_bonds_selections')"
                  v-if="visibleEquityBondStatusInput"
                  class="treeSelect"
                />
              </b-col>
              <!-- filter table columns -->
              <b-col
                cols="12"
                md="6"
                xl="6"
                sm="4"
                class="d-flex align-items-center justify-content-start mb-md-0"
              >
                <treeselect
                  value-consists-of="LEAF_PRIORITY"
                  :multiple="true"
                  :options="tableColumnsOptions"
                  :clearable="false"
                  :searchable="true"
                  v-model="treeTablecolumns"
                  valueFormat="object"
                  :limit="4"
                  :placeholder="$t('equity_bond.get_selected_columns')"
                  v-if="visibleEquityBondStatusInput"
                  class="treeSelect"
                />
              </b-col>
              <!-- search in table -->
              <!-- <b-col cols="6" sm="6" xl="6" md="6">
              <b-form-input
                v-model="filter"
                class="d-inline-block mr-1"
                :placeholder="$t('general_text.search_table')"
                v-if="allEquityBondsData.length > 0"
              />
            </b-col> -->
            </div>
          </b-col>
          <b-col cols="12" xl="4" md="4" sm="12">
            <div class="d-flex" style="width: 100% !important;">
              <b-col v-if="$Can('search_equity')" cols="6" md="6" sm="12">
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="flat-info"
                  size="sm"
                  class="top-btns"
                  @click="searchForTradesModal"
                >
                  <feather-icon
                    icon="SearchIcon"
                    size="24"
                    style="margin-bottom: 0.3rem;"
                  />
                  <span class="trades-font">{{
                    $t("equity_bond.buttons.search_for_trades")
                  }}</span>
                </b-button>
              </b-col>
              <b-col v-if="$Can('search_equity')" cols="6" md="6" sm="12">
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="flat-dark"
                  size="sm"
                  class="top-btns"
                  @click="runLastSearch()"
                  :disabled="
                    typeof lastSearchData != 'Object'
                      ? !lastSearchData.find(
                          (data) => data.user == this.user.name
                        )
                      : true
                  "
                >
                  <feather-icon
                    icon="ClockIcon"
                    size="24"
                    style="margin-bottom: 0.3rem;"
                  />
                  <span class="trades-font">{{
                    $t("equity_bond.buttons.run_last_search")
                  }}</span>
                </b-button>
              </b-col>
            </div>
            <hr />
            <div class="d-flex mt-1" style="width: 100% !important;">
              <b-col v-if="$Can('add_equity')" cols="6" xl="6" md="6" sm="12">
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  size="sm"
                  variant="gradient-success"
                  class="top-btns"
                  @click="createNewTrade"
                >
                  <feather-icon
                    icon="PlusIcon"
                    size="24"
                    style="margin-bottom: 0.3rem;"
                  />
                  <span class="text-nowrap">{{
                    $t("equity_bond.buttons.enter_new_trade")
                  }}</span>
                </b-button>
              </b-col>
              <b-col cols="3" xl="3" md="3" sm="12">
                <b-button
                  id="info-legend-button"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="flat-primary"
                  size="sm"
                  class="top-btns"
                >
                  <feather-icon
                    icon="EyeIcon"
                    size="24"
                    style="margin-bottom: 0.3rem;"
                  />

                  <span class="text-nowrap">{{
                    $t("equity_bond.buttons.legend_table")
                  }}</span>
                </b-button>
                <b-popover
                  target="info-legend-button"
                  placement="right"
                  triggers="click"
                >
                  <template #title>
                    {{ $t("equity_bond.legend_table.legend") }}
                  </template>
                  <b-table-simple hover small caption-top responsive bordered>
                    <b-tbody>
                      <b-tr>
                        <td style="width: 60%;">
                          {{ $t("equity_bond.legend_table.execution") }}
                        </td>
                        <td style="background-color: paleturquoise;"></td>
                      </b-tr>
                      <b-tr>
                        <td>
                          {{ $t("equity_bond.legend_table.verified") }}
                        </td>
                        <td style="background-color: springgreen;"></td>
                      </b-tr>
                      <b-tr>
                        <td>
                          {{ $t("equity_bond.legend_table.journal_created") }}
                        </td>
                        <td style="background-color: mediumpurple;"></td>
                      </b-tr>
                      <b-tr>
                        <td>
                          {{ $t("equity_bond.legend_table.expired_order") }}
                        </td>
                        <td style="background-color: silver;"></td>
                      </b-tr>
                      <b-tr>
                        <td>
                          {{ $t("equity_bond.legend_table.revers_initiated") }}
                        </td>
                        <td style="background-color: #FFFF99;"></td>
                      </b-tr>
                      <b-tr>
                        <td>
                          {{ $t("equity_bond.legend_table.reversed") }}
                        </td>
                        <td style="background-color: coral;"></td>
                      </b-tr>
                      <b-tr>
                        <td>
                          {{ $t("equity_bond.legend_table.cancelled") }}
                        </td>
                        <td style="background-color: orangered;"></td>
                      </b-tr>
                    </b-tbody>
                  </b-table-simple>
                </b-popover>
              </b-col>
              <b-col cols="3" xl="3" md="3" sm="12">
                <b-button
                  id="info-export-button"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="flat-primary"
                  size="sm"
                  class="top-btns"
                >
                  <feather-icon
                    icon="DownloadIcon"
                    size="24"
                    style="margin-bottom: 0.3rem;"
                  />

                  <span class="text-nowrap">{{
                    $t("equity_bond.buttons.export")
                  }}</span>
                </b-button>
                <b-popover
                  target="info-export-button"
                  placement="right"
                  triggers="click"
                >
                  <template #title>
                    {{ $t("equity_bond.buttons.export") }}
                  </template>

                  <b-button size="sm" class="top-btns" @click="exportXlsx()">
                    <span class="text-nowrap">Save as .xlsx</span>
                  </b-button>

                  <b-button
                    size="sm"
                    class="top-btns mt-1"
                    @click="exportCsv()"
                  >
                    <span class="text-nowrap">Save as .csv</span>
                  </b-button>
                </b-popover>
              </b-col>
            </div>
          </b-col>
        </b-row>
        <div
          @contextmenu.prevent="$refs.contextMenu.open"
          v-if="allEquityBondsData.length > 0"
        >
          <div
            style="background-color:white;"
            :style="'height:' + (windowHeight - 300) + 'px'"
            id="table-container"
          >
            <b-table
              :sticky-header="true"
              :no-border-collapse="true"
              hover
              :bordered="true"
              ref="refEquityBondTable"
              style="max-height: 95vh !important"
              class="position-relative"
              :items="filteredTrades"
              :filter="filter"
              filter-debounce="250"
              @filtered="onFiltered"
              :filter-included-fields="filterOn"
              selectable
              select-mode="single"
              responsive
              :small="true"
              head-variant="dark"
              :fields="tableColumns"
              show-empty
              empty-text="No matching records found"
              :current-page="currentPage"
              :per-page="perPage"
              :tbody-tr-class="legendClass"
              @row-clicked="onRowSelected"
              caption-top
              :sort-by.sync="sortBy"
              :sort-desc.sync="sortByDesc"
            >
              <!-- Column: show_details -->
              <!-- <template #cell(show_details)="row">
            <b-form-checkbox
              size="md"
              v-model="row.detailsShowing"
              @change="row.toggleDetails"
              name="check-button"
              button
              v-b-tooltip.hover
              :title="
                row.detailsShowing
                  ? 'Hide Trade Controls'
                  : 'Show Trade Controls'
              "
              button-variant="flat-primary"
            >
              <feather-icon
                :icon="row.detailsShowing ? 'EyeOffIcon' : 'EyeIcon'"
              />
            </b-form-checkbox>
          </template> -->

              <template
                slot="top-row"
                slot-scope="{ fields }"
                v-if="showColumnsFilter"
              >
                <td
                  :class="field.key == 'ID' ? 'sticky-column' : ''"
                  v-for="field in tableColumns"
                  :key="field.key"
                >
                  <v-select
                    v-if="field.key == 'EntryType'"
                    label="title"
                    v-model="filters[field.key]"
                    :placeholder="field.label"
                    :class="field.key + ' column_search_input'"
                    :options="optEntryTypes"
                    :clearable="false"
                    :reduce="(val) => val.value"
                  />
                  <v-select
                    v-else-if="field.key == 'InstrumentType'"
                    label="title"
                    v-model="filters[field.key]"
                    :placeholder="field.label"
                    :class="field.key + ' column_search_input'"
                    :options="optInstrumentTypes"
                    :clearable="false"
                    :reduce="(val) => val.value"
                  />
                  <v-select
                    v-else-if="field.key == 'BuySell'"
                    label="title"
                    v-model="filters[field.key]"
                    :placeholder="field.label"
                    :class="field.key + ' column_search_input'"
                    :options="optbuySells"
                    :clearable="false"
                    :reduce="(val) => val.value"
                  />
                  <v-select
                    v-else-if="field.key == 'OrderGivenThrough'"
                    label="title"
                    v-model="filters[field.key]"
                    :placeholder="field.label"
                    :class="field.key + ' column_search_input'"
                    :options="optOrderGivenThroughs"
                    :clearable="false"
                    :reduce="(val) => val.value"
                  />
                  <v-select
                    v-else-if="field.key == 'OrderTakenVia'"
                    label="title"
                    v-model="filters[field.key]"
                    :placeholder="field.label"
                    :class="field.key + ' column_search_input'"
                    :options="optOrderTakenVias"
                    :clearable="false"
                    :reduce="(val) => val.value"
                  />
                  <v-select
                    v-else-if="field.key == 'ActingCapacity'"
                    label="title"
                    v-model="filters[field.key]"
                    :placeholder="field.label"
                    :class="field.key + ' column_search_input'"
                    :options="optActingCapacities"
                    :clearable="false"
                    :reduce="(val) => val.value"
                  />
                  <input
                    v-else-if="field.key == 'ID'"
                    v-model="filters[field.key]"
                    :placeholder="field.label"
                    :class="'ID_column_search_input'"
                    style="max-width: 75px;"
                  />
                  <input
                    v-else
                    v-model="filters[field.key]"
                    :placeholder="field.label"
                    :class="field.key + ' column_search_input'"
                  />
                  <!-- <b-form-input v-model="filters[field.key]" :placeholder="field.label" :class="field.key" /> -->
                </td>
              </template>

              <!-- Column: check box -->
              <!-- <template #cell(check)="data" v-if="showMultiplecheckboks">
              <b-form-checkbox
                v-model="data.item.selectedTrade"
                @change="multiSelectRow(data.item.selectedTrade, data.item)"
              >
              </b-form-checkbox>
            </template> -->

              <!-- Column: ID -->
              <template #cell(ID)="data">
                <div class="d-flex justify-content-center align-items-center">
                  <b
                    ><span class="font-weight-bold text-center ">
                      <strong style="text-decoration:underline;">{{
                        data.item.ID ? data.item.ID : "-"
                      }}</strong>
                    </span>
                  </b>
                  <div v-if="showMultiplecheckboks" style="margin-left:0.3em">
                    <b-form-checkbox
                      v-model="data.item.selectedTrade"
                      @change="
                        multiSelectRow(data.item.selectedTrade, data.item)
                      "
                    >
                    </b-form-checkbox>
                  </div>
                </div>
              </template>

              <!-- Column: EntryType -->
              <template #cell(EntryType)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.EntryType ? data.item.EntryType : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: InstrumentType -->
              <template #cell(InstrumentType)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.InstrumentType ? data.item.InstrumentType : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: OrderDate -->
              <template #cell(OrderDate)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.OrderDate
                        ? dateFormat(data.item.OrderDate)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: TradeDate -->
              <template #cell(TradeDate)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.TradeDate != null
                        ? dateFormat(data.item.TradeDate)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: Amount -->
              <template #cell(Amount)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.Amount ? formatPrice(data.item.Amount, 2) : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: SettlementDate -->
              <template #cell(SettlementDate)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.SettlementDate != null
                        ? dateFormat(data.item.SettlementDate)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: Client -->
              <template #cell(Client)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.Client ? data.item.Client : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: Account -->
              <template #cell(Account)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.Account ? data.item.Account : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: SecurityDescription -->
              <template #cell(SecurityDescription)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.SecurityDescription
                        ? data.item.SecurityDescription
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: BuySell -->
              <template #cell(BuySell)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.BuySell ? data.item.BuySell : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: Quantity -->
              <template #cell(Quantity)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.Quantity
                        ? formatPrice(data.item.Quantity, 2)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: Price -->
              <template #cell(Price)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.Price ? formatPrice(data.item.Price, 2) : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: TraderNote -->
              <template #cell(TraderNote)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.TraderNote ? data.item.TraderNote : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: CommissionRate -->
              <template #cell(CommissionRate)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.CommissionRate
                        ? formatPrice(data.item.CommissionRate, 2)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: CommissionAmount -->
              <template #cell(CommissionAmount)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.CommissionAmount
                        ? formatPrice(data.item.CommissionAmount, 2)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: Tax -->
              <template #cell(Tax)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.Tax ? formatPrice(data.item.Tax, 2) : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: ClientAmount -->
              <template #cell(ClientAmount)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.ClientAmount
                        ? formatPrice(data.item.ClientAmount, 2)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: Ccy -->
              <template #cell(Ccy)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{ data.item.Ccy ? data.item.Ccy : "-" }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: CounterpartyRate -->
              <template #cell(CounterpartyRate)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.CounterpartyRate
                        ? formatPrice(data.item.CounterpartyRate, 2)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: CounterpartyCommission -->
              <template #cell(CounterpartyCommission)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.CounterpartyCommission
                        ? formatPrice(data.item.CounterpartyCommission, 2)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: CounterpartyAmount -->
              <template #cell(CounterpartyAmount)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.CounterpartyAmount
                        ? formatPrice(data.item.CounterpartyAmount, 2)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: Counterparty -->
              <template #cell(Counterparty)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.Counterparty ? data.item.Counterparty : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: CounterpartyAccount -->
              <template #cell(CounterpartyAccount)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.CounterpartyAccount
                        ? data.item.CounterpartyAccount
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: TcProfit -->
              <template #cell(TcProfit)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.TcProfit
                        ? formatPrice(data.item.TcProfit, 2)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: CounterpartyTrader -->
              <template #cell(CounterpartyTrader)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.CounterpartyTrader
                        ? data.item.CounterpartyTrader
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: TcUser -->
              <template #cell(TcUser)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.TcUser ? data.item.TcUser : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: SecurityAccount -->
              <template #cell(SecurityAccount)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.SecurityAccount
                        ? data.item.SecurityAccount
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: CounterpartyPrice -->
              <template #cell(CounterpartyPrice)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.CounterpartyPrice
                        ? formatPrice(data.item.CounterpartyPrice, 2)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: Clean -->
              <template #cell(Clean)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{ data.item.Clean != 0 ? true : false }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: AccuredInterest -->
              <template #cell(AccuredInterest)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.AccuredInterest
                        ? formatPrice(data.item.AccuredInterest, 2)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: OrderTime -->
              <template #cell(OrderTime)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.OrderTime ? data.item.OrderTime : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: ExecutionTime -->
              <template #cell(ExecutionTime)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.ExecutionTime ? data.item.ExecutionTime : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: OrderTakenVia -->
              <template #cell(OrderTakenVia)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.OrderTakenVia ? data.item.OrderTakenVia : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: OrderGivenThrough -->
              <template #cell(OrderGivenThrough)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.OrderGivenThrough
                        ? data.item.OrderGivenThrough
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: OrderPrice -->
              <template #cell(OrderPrice)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.OrderPrice
                        ? formatPrice(data.item.OrderPrice, 2)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: Margin -->
              <template #cell(Margin)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{ data.item.Margin != 0 ? true : false }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: ExchangeTraded -->
              <template #cell(ExchangeTraded)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.ExchangeTraded ? data.item.ExchangeTraded : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: Isin -->
              <template #cell(Isin)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{ data.item.Isin ? data.item.Isin : "-" }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: Prop -->
              <template #cell(Prop)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{ data.item.Prop != 0 ? true : false }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: ActingCapacity -->
              <template #cell(ActingCapacity)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.ActingCapacity ? data.item.ActingCapacity : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: SCF UTI -->
              <template #cell(TcUti)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.TcUti ? data.item.TcUti : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: UniqueLinkId -->
              <template #cell(UniqueLinkId)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.UniqueLinkId ? data.item.UniqueLinkId : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: CptyOrderTime -->
              <template #cell(CptyOrderTime)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.CptyOrderTime != null
                        ? data.item.CptyOrderTime
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: CptyExecutionTime -->
              <template #cell(CptyExecutionTime)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.CptyExecutionTime != null
                        ? data.item.CptyExecutionTime
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: SavedBy -->
              <template #cell(SavedBy)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.SavedBy ? data.item.SavedBy : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: SaveDate -->
              <template #cell(SaveDate)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.SaveDate != null
                        ? dateFormat(data.item.SaveDate)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: SaveTime -->
              <template #cell(SaveTime)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.SaveTime != null ? data.item.SaveTime : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: AmendedBy -->
              <template #cell(AmendedBy)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.AmendedBy ? data.item.AmendedBy : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: AmendmentDate -->
              <template #cell(AmendmentDate)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.AmendmentDate != null
                        ? dateFormat(data.item.AmendmentDate)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: AmendmentTime -->
              <template #cell(AmendmentTime)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.AmendmentTime != null
                        ? data.item.AmendmentTime
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: AmendmentReason -->
              <template #cell(AmendmentReason)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.AmendmentReason
                        ? data.item.AmendmentReason
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: EntryType2 & Main Entry -->
              <template #cell(EntryType2)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{ "Main Entry" }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: Cancelled -->
              <template #cell(Cancelled)="data">
                <b
                  ><span class="font-weight-bold text-white">
                    <strong>{{
                      data.item.Cancelled == 1 ? "Cancelled" : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: CancelledBy -->
              <template #cell(CancelledBy)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.CancelledBy ? data.item.CancelledBy : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: CancelDate -->
              <template #cell(CancelDate)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.CancelDate != null
                        ? dateFormat(data.item.CancelDate)
                        : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: CancelTime -->
              <template #cell(CancelTime)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.CancelTime != null ? data.item.CancelTime : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- Column: CancelReason -->
              <template #cell(CancelReason)="data">
                <b
                  ><span class="font-weight-bold">
                    <strong>{{
                      data.item.CancelReason ? data.item.CancelReason : "-"
                    }}</strong>
                  </span></b
                >
              </template>

              <!-- trade controls section -->
              <template #row-details="row">
                <b-card :title="$t('equity_bond.titles.trade_controls')">
                  <b-col cols="12">
                    <hr />
                  </b-col>

                  <div
                    class="d-flex mb-1"
                    style="max-width:1300px!important;white-space:nowrap;overflow-x:scroll;"
                  >
                    <b-col v-if="$Can('verify_equity')" cols="2" md="2" sm="12">
                      <b-button
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        variant="flat-success"
                        :title="$t('equity_bond.buttons.verify_trade')"
                        size="sm"
                        class="top-btns"
                        @click="verifySelectedTrade(row.item)"
                      >
                        <feather-icon
                          icon="CheckSquareIcon"
                          size="24"
                          style="margin-bottom: 0.3rem;"
                        />

                        <span class="trades-font">{{
                          $t("equity_bond.buttons.verify_trade")
                        }}</span>
                      </b-button>
                    </b-col>
                    <b-col v-if="$Can('amend_equity')" cols="2" md="2" sm="12">
                      <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="flat-warning"
                        size="sm"
                        class="top-btns"
                        @click="amendUpdate(row.item)"
                      >
                        <feather-icon
                          icon="EditIcon"
                          size="24"
                          style="margin-bottom: 0.3rem;"
                        />
                        <span class="trades-font">{{
                          $t("equity_bond.buttons.amend_trade")
                        }}</span>
                      </b-button>
                    </b-col>
                    <b-col
                      v-if="$Can('journal_equity')"
                      cols="2"
                      md="2"
                      sm="12"
                    >
                      <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="flat-primary"
                        size="sm"
                        class="top-btns"
                        @click="createJournalEntries(row.item)"
                      >
                        <feather-icon
                          icon="ClipboardIcon"
                          size="24"
                          style="margin-bottom: 0.3rem;"
                        />
                        <span class="trades-font">{{
                          $t("equity_bond.buttons.create_Journal_entries")
                        }}</span>
                      </b-button>
                    </b-col>
                    <b-col v-if="$Can('view_equity')" cols="2" md="2" sm="12">
                      <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="flat-secondary"
                        size="sm"
                        class="top-btns"
                        @click="viewSelectedTrade(row.item)"
                      >
                        <feather-icon
                          icon="EyeIcon"
                          size="24"
                          style="margin-bottom: 0.3rem;"
                        />
                        <span class="trades-font">{{
                          $t("equity_bond.buttons.view_trade")
                        }}</span>
                      </b-button>
                    </b-col>
                    <b-col v-if="$Can('copy_equity')" cols="2" md="2" sm="12">
                      <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="flat-secondary"
                        size="sm"
                        class="top-btns"
                        @click="copySelectedTrade(row.item)"
                      >
                        <feather-icon
                          icon="CopyIcon"
                          size="24"
                          style="margin-bottom: 0.3rem;"
                        />
                        <span class="trades-font">{{
                          $t("equity_bond.buttons.copy_trade")
                        }}</span>
                      </b-button>
                    </b-col>
                    <b-col
                      v-if="$Can('history_equity')"
                      cols="2"
                      md="2"
                      sm="12"
                    >
                      <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="flat-secondary"
                        size="sm"
                        class="top-btns"
                        @click="getAmendDetails(row.item)"
                      >
                        <feather-icon
                          icon="ListIcon"
                          size="24"
                          style="margin-bottom: 0.3rem;"
                        />
                        <span class="trades-font">{{
                          $t("equity_bond.buttons.get_amend_details")
                        }}</span>
                      </b-button>
                    </b-col>
                    <b-col
                      v-if="$Can('view_journals_equity')"
                      cols="2"
                      md="2"
                      sm="12"
                    >
                      <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="flat-secondary"
                        size="sm"
                        class="top-btns"
                        @click="viewJournalEntries(row.item)"
                      >
                        <feather-icon
                          icon="EyeIcon"
                          size="24"
                          style="margin-bottom: 0.3rem;"
                        />
                        <span class="trades-font">{{
                          $t("equity_bond.buttons.view_journal_entries")
                        }}</span>
                      </b-button>
                    </b-col>
                    <b-col
                      v-if="$Can('initiate_equity')"
                      cols="2"
                      md="2"
                      xl="2"
                      lg="2"
                      sm="12"
                    >
                      <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="flat-dark"
                        size="sm"
                        class="top-btns"
                        @click="initiateReversal(row.item)"
                      >
                        <feather-icon
                          icon="PlayIcon"
                          size="24"
                          style="margin-bottom: 0.3rem;"
                        />
                        <span class="trades-font">{{
                          $t("equity_bond.buttons.initiate_revesal")
                        }}</span>
                      </b-button>
                    </b-col>
                    <b-col
                      v-if="$Can('reverse_equity')"
                      cols="2"
                      md="2"
                      xl="2"
                      lg="2"
                      sm="12"
                    >
                      <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="flat-dark"
                        size="sm"
                        class="top-btns"
                        @click="reverseEntry(row.item)"
                      >
                        <feather-icon
                          icon="CornerUpLeftIcon"
                          size="24"
                          style="margin-bottom: 0.3rem;"
                        />
                        <span class="trades-font">{{
                          $t("equity_bond.buttons.reverse_entry")
                        }}</span>
                      </b-button>
                    </b-col>
                    <b-col
                      v-if="$Can('undo_equity')"
                      cols="2"
                      md="2"
                      xl="2"
                      lg="2"
                      sm="12"
                    >
                      <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="flat-dark"
                        size="sm"
                        class="top-btns"
                        @click="undoInitiateReversal(row.item)"
                      >
                        <feather-icon
                          icon="ChevronsLeftIcon"
                          size="24"
                          style="margin-bottom: 0.3rem;"
                        />
                        <span class="trades-font">{{
                          $t("equity_bond.buttons.undo_initiate_reversal")
                        }}</span>
                      </b-button>
                    </b-col>
                    <b-col
                      v-if="$Can('cancel_equity')"
                      cols="2"
                      md="2"
                      xl="2"
                      lg="2"
                      sm="12"
                    >
                      <b-button
                        v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                        variant="flat-danger"
                        size="sm"
                        class="top-btns"
                        @click="cancelSelectedTrade(row.item)"
                        :disabled="row.item.Cancelled == 1"
                      >
                        <feather-icon
                          icon="XSquareIcon"
                          size="24"
                          style="margin-bottom: 0.3rem;"
                        />
                        <span class="trades-font">{{
                          $t("equity_bond.buttons.cancel_trade")
                        }}</span>
                      </b-button>
                    </b-col>
                  </div>
                  <b-col cols="12">
                    <hr />
                  </b-col>
                  <!-- <b-button
                size="sm"
                variant="outline-secondary"
                @click="row.toggleDetails"
              >
                Hide Details
              </b-button> -->
                </b-card>
              </template>
            </b-table>
          </div>
        </div>

        <!-- mouse right click -->
        <vue-context ref="contextMenu">
          <li>
            <b-link
              class="d-flex align-items-center"
              @click="optionRightClicked('showColumnsFilter')"
            >
              <feather-icon
                :icon="!showColumnsFilter ? 'EyeIcon' : 'EyeOffIcon'"
                size="16"
              />
              <span class="ml-75">{{
                !showColumnsFilter
                  ? "Show column filters"
                  : "Hide column filters"
              }}</span>
            </b-link>
          </li>
          <li>
            <b-link
              class="d-flex align-items-center"
              @click="optionRightClicked('showMultiplecheckboks')"
            >
              <feather-icon
                :icon="
                  !showMultiplecheckboks ? 'CheckSquareIcon' : 'EyeOffIcon'
                "
                size="16"
              />
              <span class="ml-75">{{
                !showMultiplecheckboks
                  ? "Show multiple checkbox"
                  : "Hide multiple checkbox"
              }}</span>
            </b-link>
          </li>
          <li v-if="selectedMultipleTrades.length > 0">
            <b-link
              class="d-flex align-items-center"
              @click="multipleChangeOprFunc('copyTrades')"
            >
              <feather-icon icon="CopyIcon" size="16" />
              <span class="ml-75">{{
                $t("fx_spot_forward_swap.buttons.copy_trade")
              }}</span>
            </b-link>
          </li>
          <li
            v-if="multiSelectListStatusData.length > 0"
            v-for="(optStatus, index) in multiSelectListStatusData"
            :key="index"
          >
            <b-link
              class="d-flex align-items-center"
              @click="multipleChangeOprFunc(optStatus.key)"
            >
              <feather-icon
                :icon="optStatus.icon"
                size="16"
                :stroke="optStatus.color"
              />
              <span class="ml-75">{{ optStatus.title }}</span>
            </b-link>
          </li>
        </vue-context>
        <div class="mx-2 mb-2" v-if="allEquityBondsData.length > 0">
          <b-row>
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
            >
              <span class="text-muted"
                >{{ $t("general_text.showing") }} {{ dataMeta.from }}
                {{ $t("general_text.to") }} {{ dataMeta.to }}
                {{ $t("general_text.of") }} {{ dataMeta.of }}
                {{ $t("general_text.entries") }}</span
              >
            </b-col>

            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalRows"
                :per-page="perPage"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>

        <div v-if="allEquityBondsData.length == 0">
          <b-alert variant="info" show>
            <div class="alert-body text-center">
              <span
                ><strong>{{ $t("general_title.not_found_data") }}</strong>
                <br />
                {{
                  $t("equity_bond.messages.not_found_equity_bond_data")
                }}</span
              >
            </div>
          </b-alert>
        </div>
      </b-overlay>
      {{ /*search for trades modal*/ }}
      <SearchTrades
        :opt-counter-parties.sync="optCounterParties"
        :opt-clients.sync="optClients"
        :opt-cost-centers.sync="optCostCenters"
        :searched-clients.sync="searchedClients"
        :searched-counterparties.sync="searchedCounterparties"
        ref="searchTrades"
        @searchedData="getSearchedData"
      />

      {{ /*View Selected Trade for trades modal*/ }}
      <ViewSelectedTrade
        :opt-counter-parties.sync="optCounterParties"
        :opt-clients.sync="optClients"
        :opt-cost-centers.sync="optCostCenters"
        :searched-clients.sync="searchedClients"
        :searched-counterparties.sync="searchedCounterparties"
        ref="ViewSelectedTrade"
      />

      {{ /*Enter New Trade for trades modal*/ }}
      <EnterNewTrade
        :opt-counter-parties.sync="optCounterParties"
        :opt-clients.sync="optClients"
        :opt-cost-centers.sync="optCostCenters"
        :searched-clients.sync="searchedClients"
        :searched-counterparties.sync="searchedCounterparties"
        ref="refEnterNewTrade"
        @createdEquityBond="createdEquityBond"
      />
      {{ /*Verify selected Trade for trades modal*/ }}
      <VerifyTrade
        :opt-counter-parties.sync="optCounterParties"
        :opt-clients.sync="optClients"
        :opt-cost-centers.sync="optCostCenters"
        :searched-clients.sync="searchedClients"
        :searched-counterparties.sync="searchedCounterparties"
        ref="refVerifyTrade"
        @verified="verifiedTrade"
      />
      {{ /*Amend selected Trade for trades modal*/ }}
      <AmendSelectedTrade
        :opt-counter-parties.sync="optCounterParties"
        :opt-clients.sync="optClients"
        :opt-cost-centers.sync="optCostCenters"
        :searched-clients.sync="searchedClients"
        :searched-counterparties.sync="searchedCounterparties"
        ref="refAmendTrade"
        @updatedData="getUpdatedAmendData"
      />
      {{ /*Copy selected Trade for trades modal*/ }}
      <CopySelectedTrade
        :opt-counter-parties.sync="optCounterParties"
        :opt-clients.sync="optClients"
        :opt-cost-centers.sync="optCostCenters"
        :searched-clients.sync="searchedClients"
        :searched-counterparties.sync="searchedCounterparties"
        ref="refCopyTrade"
        @copiedEquityBond="copiedEquityBond"
      />
      {{ /*Create Journal selected Trade for trades modal*/ }}
      <CreateJournal
        :opt-counter-parties.sync="optCounterParties"
        :opt-clients.sync="optClients"
        :opt-cost-centers.sync="optCostCenters"
        :searched-clients.sync="searchedClients"
        :searched-counterparties.sync="searchedCounterparties"
        ref="refCreateJournalTrade"
        @createdJournalEntryArray="createdJournalEntryArray"
      />
      {{ /*View Journal selected Trade for trades modal*/ }}
      <ViewJournalEntries
        :opt-counter-parties.sync="optCounterParties"
        :opt-clients.sync="optClients"
        :opt-cost-centers.sync="optCostCenters"
        :searched-clients.sync="searchedClients"
        :searched-counterparties.sync="searchedCounterparties"
        ref="refViewJournalEntries"
        @createdJournalEntry="createdJournalEntry"
      />
      {{ /*Inititate reversal Trade for trades modal*/ }}
      <InitiateReversal
        :opt-counter-parties.sync="optCounterParties"
        :opt-clients.sync="optClients"
        :opt-cost-centers.sync="optCostCenters"
        :searched-clients.sync="searchedClients"
        :searched-counterparties.sync="searchedCounterparties"
        ref="refInitiateReversal"
        @reversaledTrade="reversaledTrade"
      />
      <ReverseEntry
        :opt-counter-parties.sync="optCounterParties"
        :opt-clients.sync="optClients"
        :opt-cost-centers.sync="optCostCenters"
        :searched-clients.sync="searchedClients"
        :searched-counterparties.sync="searchedCounterparties"
        ref="refReverseEntry"
        @reversedEntry="reversedEntry"
      />
      {{ /*Undo selected Trade for trades modal*/ }}
      <UndoInitiateReversal
        :opt-counter-parties.sync="optCounterParties"
        :opt-clients.sync="optClients"
        :opt-cost-centers.sync="optCostCenters"
        :searched-clients.sync="searchedClients"
        :searched-counterparties.sync="searchedCounterparties"
        ref="refUndoInitiateReversal"
        @undoInitiate="undoInitiate"
      />
    </b-card>
    {{/** view modal failed multi select process*/}}
    <b-modal
      id="multiSelectProcessModal"
      v-model="showMultiSelectProcessModal"
      :title="$t('multiCheckBoxTitles.failes_process_title')"
      :ok-title="$t('general_buttons.close')"
      @ok="okMultiSelectProcessModal"
      @hidden="okMultiSelectProcessModal"
      size="lg"
      :body-bg-variant="'light'"
      :header-bg-variant="'light'"
      ok-only
      ok-variant="primary"
    >
      <b-container>
        <b-form>
          <b-row class="mt-0">
            <b-col
              cols="12"
              xl="12"
              md="12"
              sm="12"
              class="bodyRow mt-1"
              style="padding: 0.5rem 0;"
            >
              <b-table
                striped
                hover
                ref="refMultiSelectProcessModal"
                class="position-relative"
                :items="multiSelectProcessModalTableData"
                selectable
                select-mode="single"
                responsive
                head-variant="light"
                :fields="multiSelectProcessModalTableColumns"
                show-empty
                :empty-text="$t('general_messages.no_matching_recors_found')"
              >
                <template #cell(ID)="data">
                  <strong>{{ data.item.ID }}</strong>
                </template>

                <template #cell(Tcuti)="data">
                  <strong>{{ data.item.Tcuti }}</strong>
                </template>

                <template #cell(reason)="data">
                  <strong>{{ data.item.reason }}</strong>
                </template>
              </b-table>
            </b-col>
          </b-row>
        </b-form>
      </b-container>
      {{/****END*** view modal failed multi select process*/}}
    </b-modal>
  </div>
</template>

<script>
import {
  BOverlay,
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormRating,
  VBModal,
  BTabs,
  BTab,
  BCardText,
  BIcon,
  BPopover,
  VBTooltip,
} from "bootstrap-vue";
import store from "@/store";
import { onUnmounted } from "@vue/composition-api";
import equityBondStoreModule from "./EquityBondStoreModule";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, alphaNum, email } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import axios from "axios";
import axiosIns from "@/libs/axios";
import router from "@/router";
import moment from "moment";
import "animate.css";
import _ from "lodash";
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { mapState, mapGetters } from "vuex";
import SearchTrades from "./SearchTrades.vue";
import ViewSelectedTrade from "./ViewSelectedTrade.vue";
import EnterNewTrade from "./EnterNewTrade.vue";
import VerifyTrade from "./VerifyTrade.vue";
import AmendSelectedTrade from "./AmendSelectedTrade.vue";
import CopySelectedTrade from "./CopySelectedTrade.vue";
import CreateJournal from "./CreateJournal.vue";
import ViewJournalEntries from "./ViewJournalEntries.vue";
import InitiateReversal from "./InitiateReversal.vue";
import ReverseEntry from "./ReverseEntry.vue";
import UndoInitiateReversal from "./UndoInitiateReversal.vue";
import VueHorizontal from "vue-horizontal";
import VueContext from "vue-context";
import { debounce } from "lodash";

export default {
  components: {
    BOverlay,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BTabs,
    BTab,
    BCardText,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormRating,
    BIcon,
    BPopover,
    vSelect,
    ValidationProvider,
    ValidationObserver,
    ToastificationContent,
    flatPickr,
    SearchTrades,
    ViewSelectedTrade,
    EnterNewTrade,
    VerifyTrade,
    AmendSelectedTrade,
    CopySelectedTrade,
    CreateJournal,
    ViewJournalEntries,
    InitiateReversal,
    ReverseEntry,
    UndoInitiateReversal,
    Treeselect,
    VueHorizontal,
    VueContext,
  },

  directives: {
    Ripple,
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
  },

  setup() {
    const EQUITYBONDS_APP_STORE_MODULE_NAME = "equity&bond";
    // Register module
    if (!store.hasModule(EQUITYBONDS_APP_STORE_MODULE_NAME))
      store.registerModule(
        EQUITYBONDS_APP_STORE_MODULE_NAME,
        equityBondStoreModule
      );

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(EQUITYBONDS_APP_STORE_MODULE_NAME))
        store.unregisterModule(EQUITYBONDS_APP_STORE_MODULE_NAME);
    });
  },

  mounted() {
    let treeOptionsStatus = [
      {
        id: 1000000,
        label: this.$t("equity_bond.status"),
        children: [],
      },
    ];
    this.equityBondsStatusOptions.forEach((element) => {
      let setStatus = {
        id: element.text,
        label: element.text,
        value: element.text,
        is: "status",
      };
      treeOptionsStatus[0].children.push(setStatus);
    });

    this.statusOptions = treeOptionsStatus;

    let treeColumnsOption = [
      {
        id: 1000000,
        label: this.$t("equity_bond.columns"),
        children: [],
      },
    ];

    this.tableColumns.forEach((element, index) => {
      if (element.key != "ID") {
        let setColumn = {
          id: index,
          label: element.key,
          value: element.key,
          is: "column",
        };
        treeColumnsOption[0].children.push(setColumn);
      }
    });

    this.tableColumnsOptions = treeColumnsOption;
    this.treeTablecolumns = treeColumnsOption[0].children;

    //get last 7 days trades
    this.getLast7DaysTrades();
    this.getAllAccounts();
    this.getArrangeFxExecutedOrderTakenGivenThrough();

    this.debouncedGetWindowHeight = debounce(this.getWindowHeight, 300);
    window.addEventListener("resize", this.debouncedGetWindowHeight);
  },

  created() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.debouncedGetWindowHeight);
  },

  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  },

  data() {
    const equityBondsStatusOptions = [
      { text: "Execution" },
      { text: "Verified" },
      { text: "Journal Created" },
      { text: "Expired Order" },
      { text: "Reverse Initiated" },
      { text: "Reversed" },
      { text: "Cancelled" },
    ];

    const equityBondtableColumns = [
      // { key: "show_details", label: null },
      {
        stickyColumn: true,
        key: "ID",
        label: "ID",
        // thClass: "text-left",
        tdClass: "text-center ID_background",
        thClass: "sticky-column",
        thStyle: "width: 50px",
        sortable: true,
        class: "text-start sticky-column",
        variant: "primary",
      },
      // {
      //   // stickyColumn: true,
      //   key: "check",
      //   label: "#",
      //   tdClass: "text-center sticky-column",
      //   thClass: "sticky-column",
      //   class: `text-center d-none`,
      // },

      {
        key: "EntryType",
        label: this.$t("equity_bond.table.entry_type"),
        class: "text-start",
      },
      {
        key: "InstrumentType",
        label: this.$t("equity_bond.table.type"),
        class: "text-start",
      },
      {
        key: "OrderDate",
        label: this.$t("equity_bond.table.order_date"),
        class: "text-start text-nowrap",
      },
      {
        key: "TradeDate",
        label: this.$t("equity_bond.table.trade_date"),
        class: "text-start text-nowrap",
        sortable: true,
      },
      {
        key: "Amount",
        label: this.$t("equity_bond.table.amount"),
        class: "text-start",
      },
      {
        key: "SettlementDate",
        label: this.$t("equity_bond.table.settlement_date"),
        class: "text-start text-nowrap",
        sortable: true,
      },
      {
        key: "Client",
        label: this.$t("equity_bond.table.client"),
        class: "text-start",
        sortable: true,
      },
      {
        key: "Account",
        label: this.$t("equity_bond.table.account"),
        class: "text-start",
      },
      {
        key: "SecurityDescription",
        label: this.$t("equity_bond.table.security_desc"),
        class: "text-start",
      },
      {
        key: "BuySell",
        label: this.$t("equity_bond.table.buy_sell"),
        class: "text-start",
      },
      {
        key: "Quantity",
        label: this.$t("equity_bond.table.quantity"),
        class: "text-start",
      },
      {
        key: "Price",
        label: this.$t("equity_bond.table.price"),
        class: "text-start",
        sortable: true,
      },
      {
        key: "TraderNote",
        label: this.$t("equity_bond.table.note"),
        class: "text-start",
      },
      {
        key: "CommissionRate",
        label: this.$t("equity_bond.table.client_rate"),
        class: "text-start",
      },
      {
        key: "CommissionAmount",
        label: this.$t("equity_bond.table.client_comission"),
        class: "text-start",
      },
      {
        key: "Tax",
        label: this.$t("equity_bond.table.tax_amount"),
        class: "text-start",
      },
      {
        key: "ClientAmount",
        label: this.$t("equity_bond.table.client_amount"),
        class: "text-start",
      },
      {
        key: "Ccy",
        label: this.$t("equity_bond.table.ccy"),
        class: "text-start",
      },
      {
        key: "CounterpartyRate",
        label: this.$t("equity_bond.table.counterparty_rate"),
        class: "text-start",
      },
      {
        key: "CounterpartyCommission",
        label: this.$t("equity_bond.table.counterparty_commission"),
        class: "text-start",
      },
      {
        key: "CounterpartyAmount",
        label: this.$t("equity_bond.table.counterparty_amount"),
        class: "text-start",
      },
      {
        key: "Counterparty",
        label: this.$t("equity_bond.table.counterparty"),
        class: "text-start",
      },
      {
        key: "CounterpartyAccount",
        label: this.$t("equity_bond.table.counterparty_account"),
        class: "text-start",
      },
      {
        key: "TcProfit",
        label: this.$t("equity_bond.table.profit"),
        class: "text-start",
      },
      {
        key: "CounterpartyTrader",
        label: this.$t("equity_bond.table.counterparty_trader"),
        class: "text-start",
      },
      {
        key: "TcUser",
        label: this.$t("equity_bond.table.sfc_user"),
        class: "text-start",
      },
      {
        key: "SecurityAccount",
        label: this.$t("equity_bond.table.security_account"),
        class: "text-start",
      },
      {
        key: "CounterpartyPrice",
        label: this.$t("equity_bond.table.counterparty_price"),
        class: "text-start",
      },
      {
        key: "Clean",
        label: this.$t("equity_bond.table.clean"),
        class: "text-start",
      },
      {
        key: "AccuredInterest",
        label: this.$t("equity_bond.table.accrued_interest"),
        class: "text-start",
      },
      {
        key: "OrderTime",
        label: this.$t("equity_bond.table.order_time"),
        class: "text-start",
      },
      {
        key: "ExecutionTime",
        label: this.$t("equity_bond.table.execution_time"),
        class: "text-start",
      },
      {
        key: "OrderTakenVia",
        label: this.$t("equity_bond.table.order_taken_via"),
        class: "text-start",
      },
      {
        key: "OrderGivenThrough",
        label: this.$t("equity_bond.table.order_given_through"),
        class: "text-start",
      },
      {
        key: "OrderPrice",
        label: this.$t("equity_bond.table.order_price"),
        class: "text-start",
      },
      {
        key: "Margin",
        label: this.$t("equity_bond.table.margin"),
        class: "text-start",
      },
      {
        key: "ExchangeTraded",
        label: this.$t("equity_bond.table.exchange_traded"),
        class: "text-start",
      },
      {
        key: "Isin",
        label: this.$t("equity_bond.table.security_isin"),
        class: "text-start",
      },
      {
        key: "Prop",
        label: this.$t("equity_bond.table.prop"),
        class: "text-start",
      },
      {
        key: "ActingCapacity",
        label: this.$t("equity_bond.table.acting_capacity"),
        class: "text-start",
      },
      {
        key: "TcUti",
        label: this.$t("equity_bond.table.sfc_uti"),
        class: "text-start",
      },
      {
        key: "UniqueLinkId",
        label: this.$t("equity_bond.table.unique_link_id"),
        class: "text-start",
      },
      {
        key: "CptyOrderTime",
        label: this.$t("equity_bond.table.cpty_order_time"),
        class: "text-start",
      },
      {
        key: "CptyExecutionTime",
        label: this.$t("equity_bond.table.cpty_execution_time"),
        class: "text-start",
      },
      {
        key: "SavedBy",
        label: this.$t("equity_bond.table.saved_by"),
        class: "text-start",
      },
      {
        key: "SaveDate",
        label: this.$t("equity_bond.table.save_date"),
        class: "text-start text-nowrap",
      },
      {
        key: "SaveTime",
        label: this.$t("equity_bond.table.save_time"),
        class: "text-start",
      },
      {
        key: "AmendedBy",
        label: this.$t("equity_bond.table.amended_by"),
        class: "text-start",
      },
      {
        key: "AmendmentDate",
        label: this.$t("equity_bond.table.amendment_date"),
        class: "text-start text-nowrap",
      },
      {
        key: "AmendmentTime",
        label: this.$t("equity_bond.table.amendment_time"),
        class: "text-start",
      },
      {
        key: "AmendmentReason",
        label: this.$t("equity_bond.table.amendment_reason"),
        class: "text-start",
      },
      {
        key: "EntryType2",
        label: this.$t("equity_bond.table.entry_type"),
        class: "text-start",
      },
      {
        key: "Cancelled",
        label: this.$t("equity_bond.table.cancelled"),
        class: "text-start",
      },
      {
        key: "CancelledBy",
        label: this.$t("equity_bond.table.cancelled_by"),
        class: "text-start",
      },
      {
        key: "CancelDate",
        label: this.$t("equity_bond.table.canceled_date"),
        class: "text-start text-nowrap",
      },
      {
        key: "CancelTime",
        label: this.$t("equity_bond.table.cancelled_time"),
        class: "text-start",
      },
      {
        key: "CancelReason",
        label: this.$t("equity_bond.table.cancelled_reason"),
        class: "text-start",
      },
    ];

    return {
      multiSelectProcessModalTableColumns: [
        {
          key: "ID",
          label: this.$t(
            "multiCheckBoxTitles.failed_proceses_table_columns.id"
          ),
          class: "text-center",
          thStyle: "width: 20%",
        },
        {
          key: "Tcuti",
          label: this.$t(
            "multiCheckBoxTitles.failed_proceses_table_columns.tcUti"
          ),
          sortable: false,
          class: "text-start",
          thStyle: "width: 30%",
        },
        {
          key: "reason",
          label: this.$t(
            "multiCheckBoxTitles.failed_proceses_table_columns.reason"
          ),
          sortable: false,
          class: "text-start",
          thStyle: "width: 50%",
        },
      ],
      userData: JSON.parse(localStorage.getItem("userData")),
      windowHeight: window.innerHeight,
      allEquityBondsData: [],
      allEquityBondNotFilterData: [],
      visibleEquityBondStatusInput: false,
      optInstrumentTypes: [
        { title: "All", value: "" },
        { title: "EQ", value: "EQ" },
        { title: "FI", value: "FI" },
      ],
      optbuySells: [
        { title: "All", value: "" },
        { title: "Buy", value: "Buy" },
        { title: "Sell", value: "Sell" },
        { title: "Sell Short", value: "Sell Short" },
      ],
      optEntryTypes: [
        { title: "All", value: "" },
        { title: "Order", value: "Order" },
        { title: "Execution", value: "Execution" },
      ],
      optOrderTypes: [
        { title: "All", value: "" },
        { title: "Limit", value: "Limit" },
        { title: "Market", value: "Market" },
        { title: "Stop Limit", value: "Stop Limit" },
        { title: "Stop", value: "Stop" },
        { title: "Trailing Stop", value: "Trailing Stop" },
      ],
      optTimeInForces: [
        { title: "All", value: "" },
        { title: "DAY", value: "DAY" },
        { title: "GTC", value: "GTC" },
        { title: "GTD", value: "GTD" },
        { title: "FOK", value: "FOK" },
        { title: "FAK", value: "FAK" },
      ],
      optActingCapacities: [
        { title: "All", value: "" },
        { title: "CASH", value: "CASH" },
        { title: "PRINCIPAL", value: "PRINCIPAL" },
      ],
      optOrderTakenVias: [],
      optOrderGivenThroughs: [],
      sortByDesc: true,
      sortBy: "ID",
      filterOn: [
        "TcUser",
        "Counterparty",
        "Client",
        "OrderGivenThrough",
        "OrderTakenVia",
      ],
      tableColumns: equityBondtableColumns,
      tableOrjinalColumns: equityBondtableColumns,
      filters: {
        EntryType: "",
        TcUti: "",
        Counterparty: "",
      },
      perPage: localStorage.getItem("equityTradesShow") ?? 25,
      perPage: 25,
      currentPage: 1,
      totalRows: 0,
      perPageOptions: [10, 25, 50, 100, 500],
      filter: null,
      loading: false,
      filterSettlementDate: null,
      filterTradeDate: null,
      canceledReasonText: null,
      statusOptions: [],
      tableColumnsOptions: [],
      equityBondsStatusOptions: equityBondsStatusOptions,
      treeStatus: null,
      treeTablecolumns: null,
      tradeRangeDate: null,
      filterTradeDateStart: null,
      filterTradeDateEnd: null,
      settleRangeDate: null,
      filterSettleDateStart: null,
      filterSettleDateEnd: null,
      dateConfig: {
        allowInput: true,
        dateFormat: "d-m-Y",
        mode: "range",
      },

      optClients: [],
      optCounterParties: [],
      searchedClients: [],
      searchedCounterparties: [],
      optCostCenters: [],
      showColumnsFilter: false,
      showMultiplecheckboks: false,
      selectedMultipleTrades: [],
      multiSelectListStatusData: [],
      showMultiSelectProcessModal: false,
      multiSelectProcessModalTableData: [],
      tradeForm: {
        costCenter: null,
        orderDurationDate: null,
        orderDurationDateEnd: null,

        orderDurationTime: null,
        entryType: null,
        buySell: null,
        orderType: null,
        timeInForce: null,
        orderDate: null,
        orderDateEnd: null,

        orderTime: null,
        cptyOrderDate: null,
        cptyOrderDateEnd: null,

        cptyOrderTime: null,
        enableConnectedOCOOrders: "All Oco Orders",
        stopPrice: null,
        trailingAmount: null,
        takeProfitLimit: null,
        stopLossStop: null,
        stopLossLimit: null,
        instrumentType: null,
        securityDesc: null,
        quantity: null,
        clientPrice: null,
        clientPriceType: null,
        amount: null,
        counterpartyPrice: null,
        clean: false,
        accrued: null,
        client: null,
        clientAccount: null,
        isin: null,
        exchangeTraded: null,
        counterparty: null,
        cptyAccount: null,
        counterpartyTrader: null,
        scfUser: null,
        actingCapaticy: null,
        tradeDate: null,
        tradeDateEnd: null,

        settlementDate: null,
        settlementDateEnd: null,

        executionTime: null,
        cptyExecutionTime: null,
        clientRate: null,
        clientCommission: null,
        clientAmount: null,
        counterpartyRate: null,
        tax: null,
        cptyCommission: null,
        counterpartyAmount: null,
        orderTakenVia: null,
        orderGivenThrough: null,
        profit: null,
        tradeReportingNeeded: null,
        note: null,
        calculateCommissionFromRate1: true,
        calculateCommissionFromRate2: true,
        uti: null,
        uniqueLinkID: null,
        tradeRef: null,
        tradeStart: null,
        tradeEnd: null,
        settleStart: null,
        settleEnd: null,

        stopPriceEnd: null,
        trailingAmountEnd: null,
        takeProfitLimitEnd: null,
        stopLossStopEnd: null,
        stopLossLimitEnd: null,
        quantityEnd: null,
        clientPriceEnd: null,
        amountEnd: null,
        counterpartyPriceEnd: null,
        calculateCommissionFromRate1End: null,
        clientRateEnd: null,
        clientCommissionEnd: null,
        clientAmountEnd: null,
        calculateCommissionFromRate2End: null,
        counterpartyRateEnd: null,
        cptyCommissionEnd: null,
        counterpartyAmountEnd: null,
        profitEnd: null,
        taxEnd: null,
      },
    };
  },
  computed: {
    ...mapState({
      lastSearchData: (state) => state.runLastEquityBondSearchData,
    }),
    user: {
      get() {
        return this.$store.getters["authUser"];
      },
      set(newValue) {
        return this.$store.dispatch("setUser", newValue);
      },
    },

    filteredTrades() {
      const filtered = this.allEquityBondsData.filter((item) => {
        return Object.keys(this.filters).every((key) =>
          String(item[key]).includes(this.filters[key])
        );
      });
      return filtered.length > 0
        ? filtered
        : [
            {
              EntryType: "",
              TcUti: "",
              Counterparty: "",
            },
          ];
    },
    dataMeta() {
      return {
        from:
          this.totalRows != 0 ? this.perPage * (this.currentPage - 1) + 1 : 0,
        to:
          this.perPage * (this.currentPage - 1) + Number(this.perPage) <
          this.totalRows
            ? this.perPage * (this.currentPage - 1) + Number(this.perPage)
            : this.totalRows,
        of: this.totalRows,
      };
    },
  },
  methods: {
    getWindowHeight() {
      this.windowHeight = window.innerHeight;
    },

    optionRightClicked(click) {
      if (click == "showColumnsFilter") {
        this.showColumnsFilter = !this.showColumnsFilter;
      }
      if (click == "showMultiplecheckboks") {
        this.showMultiplecheckboks = !this.showMultiplecheckboks;
        this.multiSelectListStatusData = [];
        this.selectedMultipleTrades = [];

        this.tableColumns[1].class = this.showMultiplecheckboks
          ? "text-center"
          : "text-center d-none";
        this.multiSelectListStatusData = [];
        _.mapValues(this.allEquityBondsData, (v) => (v.selectedTrade = false));
      }
    },
    multiSelectRow(check, trade) {
      if (check) {
        //add right click check true trades
        this.multiSelectListStatusData = [];
        this.selectedMultipleTrades.push(trade);
      } else {
        // remove false check trades
        let clonedTrades = JSON.parse(
          JSON.stringify(this.selectedMultipleTrades)
        );
        _.remove(clonedTrades, {
          ID: trade.ID,
        });

        this.selectedMultipleTrades = clonedTrades;
      }
    },
    multipleChangeOprFunc(status) {
      let tradesCount = null;
      let trades = [];

      if (status == "Verify") {
        tradesCount = _.filter(this.selectedMultipleTrades, {
          EntryType: "Execution",
          Verified: 0,
          JournalCreated: 0,
          ReversalInitiated: 0,
          Reversed: 0,
          Cancelled: 0,
        }).length;

        trades = _.filter(this.selectedMultipleTrades, {
          EntryType: "Execution",
          Verified: 0,
          JournalCreated: 0,
          ReversalInitiated: 0,
          Reversed: 0,
          Cancelled: 0,
        });
      }

      if (status == "Create Journal") {
        tradesCount = _.filter(this.selectedMultipleTrades, {
          EntryType: "Execution",
          Verified: 1,
          JournalCreated: 0,
          ReversalInitiated: 0,
          Reversed: 0,
          Cancelled: 0,
        }).length;

        trades = _.filter(this.selectedMultipleTrades, {
          EntryType: "Execution",
          Verified: 1,
          JournalCreated: 0,
          ReversalInitiated: 0,
          Reversed: 0,
          Cancelled: 0,
        });
      }

      if (status == "Revers Inititated") {
        tradesCount = _.filter(this.selectedMultipleTrades, {
          EntryType: "Execution",
          Verified: 1,
          JournalCreated: 1,
          ReversalInitiated: 0,
          Reversed: 0,
          Cancelled: 0,
        }).length;

        trades = _.filter(this.selectedMultipleTrades, {
          EntryType: "Execution",
          Verified: 1,
          JournalCreated: 1,
          ReversalInitiated: 0,
          Reversed: 0,
          Cancelled: 0,
        });
      }

      if (status == "Reversed") {
        tradesCount = _.filter(this.selectedMultipleTrades, {
          EntryType: "Execution",
          Verified: 1,
          JournalCreated: 1,
          ReversalInitiated: 1,
          Reversed: 0,
          Cancelled: 0,
        }).length;

        trades = _.filter(this.selectedMultipleTrades, {
          EntryType: "Execution",
          Verified: 1,
          JournalCreated: 1,
          ReversalInitiated: 1,
          Reversed: 0,
          Cancelled: 0,
        });
      }

      if (status == "Undo Initiate Reversal") {
        tradesCount = _.filter(this.selectedMultipleTrades, {
          EntryType: "Execution",
          Verified: 1,
          JournalCreated: 1,
          ReversalInitiated: 1,
          Reversed: 0,
          Cancelled: 0,
        }).length;

        trades = _.filter(this.selectedMultipleTrades, {
          EntryType: "Execution",
          Verified: 1,
          JournalCreated: 1,
          ReversalInitiated: 1,
          Reversed: 0,
          Cancelled: 0,
        });
      }

      if (status == "Cancel") {
        tradesCount = _.filter(this.selectedMultipleTrades, {
          Cancelled: 0,
        }).length;

        trades = _.filter(this.selectedMultipleTrades, {
          Cancelled: 0,
        });
      }

      if (status == "copyTrades") {
        tradesCount = _.filter(this.selectedMultipleTrades, {
          Cancelled: 0,
        }).length;

        trades = _.filter(this.selectedMultipleTrades, {
          Cancelled: 0,
        });
      }

      if (trades.length > 0 && status) {
        if (status == "copyTrades") {
          this.tradeMultipleSelectCopyOpr(tradesCount, trades);
        } else {
          this.tradeMultipleChangeStatusOpr(tradesCount, trades, status);
        }
      }
    },

    exportXlsx() {
      store
        .dispatch("equity&bond/exportXlsx")
        .then((res) => {
          let blob = new Blob([res.data], {
            type: "application/octet-stream",
          });

          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "equity.xlsx");
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    exportCsv() {
      store
        .dispatch("equity&bond/exportCsv")
        .then((res) => {
          let blob = new Blob([res.data], {
            type: "application/octet-stream",
          });

          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "equity.csv");
          document.body.appendChild(link);
          link.click();
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getAllAccounts() {
      store
        .dispatch("equity&bond/getAccountCostCenter", { type: "Equity" })
        .then((res) => {
          this.optClients = [];
          this.optCounterParties = [];
          this.searchedClients = res.data.clients;
          this.searchedCounterparties = res.data.clients;
          this.optCostCenters = [];
          this.optCostCenters = res.data.cost_centers;
          this.$refs.refEnterNewTrade.tradeForm.costCenter =
            res.data.default_cost_center;
          this.$refs.refAmendTrade.tradeForm.costCenter =
            res.data.default_cost_center;

          if (res.data.clients.length > 0) {
            res.data.clients.forEach((data) => {
              this.optClients.push(data.AccountName);
            });

            res.data.clients.forEach((data) => {
              this.optCounterParties.push(data.AccountName);
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },

    getLast7DaysTrades() {
      this.$checkAccessRight("EquityBond", "Search").then((res) => {
        if (res.data.valid) {
          this.loading = true;
          store
            .dispatch("equity&bond/getLast7DaysEquityTrades")
            .then((res) => {
              this.allEquityBondsData = [];
              if (res.data.length > 0) {
                this.visibleEquityBondStatusInput = true;
              } else {
                this.visibleEquityBondStatusInput = false;
              }
              this.totalRows = res.data.length;
              this.allEquityBondsData = res.data;
              this.allEquityBondNotFilterData = res.data;
              this.loading = false;
            })
            .catch((error) => {
              this.databaseErrorMessage();
              console.log(error);
            });
        } else {
          this.notAuthToastMessage();
        }
      });
    },
    getArrangeFxExecutedOrderTakenGivenThrough() {
      store
        .dispatch(
          "equity&bond/fetchArrangeFxExecutedOrderTakenGivenThrough",
          this.user
        )
        .then((res) => {
          if (
            res.data.orderGivenThroughs.length > 0 ||
            res.data.orderTakenVias.length > 0
          ) {
            this.optOrderGivenThroughs.push({ title: "All", value: "" });

            if (res.data.orderGivenThroughs.length > 0) {
              res.data.orderGivenThroughs.forEach((data) => {
                this.optOrderGivenThroughs.push({ title: data, value: data });
              });
            }
            this.optOrderTakenVias.push({ title: "All", value: "" });

            if (res.data.orderTakenVias.length > 0) {
              res.data.orderTakenVias.forEach((data) => {
                this.optOrderTakenVias.push({ title: data, value: data });
              });
            }
          }
          // else {
          //   this.errorMessage(
          //     this.$t("fx_spot_forward_swap.messages.creating_message")
          //   );
          // }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    initiateReversal(item) {
      this.$checkAccessRight("EquityBond", "InitiateReversal").then((res) => {
        if (res.data.valid) {
          if (item.ID != 0) {
            this.checkJournalCreated("InitiateReversal", item.TcUti).then(
              (res) => {
                if (res.data.info == true) {
                  this.checkEntryReversed("Initiate", item.TcUti).then(
                    (res) => {
                      if (res.data.info == true) {
                        this.checkReversalInitiated(
                          "InitiateReversal",
                          item.TcUti
                        ).then((res) => {
                          if (res.data.info == true) {
                            store
                              .dispatch("equity&bond/getAmendInitialise", {
                                auth: this.user,
                                tradeId: item.ID,
                                tcUti: item.TcUti,
                              })
                              .then((res) => {
                                if (res.data.info == "Pass") {
                                  if (res.data.trade.Cancelled == 0) {
                                    if (this.getChoice()) {
                                      this.$refs.refInitiateReversal.fetchedTradeData =
                                        res.data.trade;

                                      //set timeline datas
                                      let actionsArray = [];
                                      if (
                                        res.data.trade.SavedBy != null &&
                                        res.data.trade.SavedBy != null
                                      ) {
                                        actionsArray.push({
                                          type: "Saved",
                                          user: res.data.trade.SavedBy ?? null,
                                          date:
                                            res.data.trade.SaveDate.split(
                                              " "
                                            )[0] +
                                            " " +
                                            res.data.trade.SaveTime.split(
                                              " "
                                            )[1],
                                          reason: "-",
                                        });
                                      }

                                      if (
                                        res.data.trade.AmendedBy != null &&
                                        res.data.trade.AmendedBy != null
                                      ) {
                                        actionsArray.push({
                                          type: "Amended",
                                          user:
                                            res.data.trade.AmendedBy ?? null,
                                          date:
                                            res.data.trade.AmendmentDate.split(
                                              " "
                                            )[0] +
                                            " " +
                                            res.data.trade.AmendmentTime.split(
                                              " "
                                            )[1],
                                          reason:
                                            res.data.trade.AmendmentReason,
                                        });
                                      }
                                      if (res.data.actions.length > 0) {
                                        if (
                                          res.data.actions[0]
                                            .VerificationUser != null &&
                                          res.data.actions[0]
                                            .VerificationUser != null
                                        ) {
                                          this.$refs.refInitiateReversal.checkVerify = true;
                                          actionsArray.push({
                                            type: "Verified",
                                            user:
                                              res.data.actions[0]
                                                .VerificationUser ?? null,
                                            date:
                                              res.data.actions[0]
                                                .VerificationDateTime,
                                            reason: "-",
                                          });
                                        }
                                      }

                                      if (res.data.actions.length > 0) {
                                        if (
                                          res.data.actions[0].JournalUser !=
                                            null &&
                                          res.data.actions[0].JournalUser !=
                                            null
                                        ) {
                                          actionsArray.push({
                                            type: "Journal Created",
                                            user:
                                              res.data.actions[0].JournalUser ??
                                              null,
                                            date:
                                              res.data.actions[0]
                                                .JournalDateTime,
                                            reason: "-",
                                          });
                                        }
                                      }

                                      if (
                                        res.data.trade.CancelledBy != null &&
                                        res.data.trade.CancelledBy != null
                                      ) {
                                        actionsArray.push({
                                          type: "Canceled",
                                          user:
                                            res.data.trade.CancelledBy ?? null,
                                          date:
                                            res.data.trade.CancelDate.split(
                                              " "
                                            )[0] +
                                            " " +
                                            res.data.trade.CancelTime.split(
                                              " "
                                            )[1],
                                          reason: res.data.trade.CancelReason,
                                        });
                                      }

                                      if (res.data.actions.length > 0) {
                                        if (
                                          res.data.actions[0]
                                            .ReversalInitiatedBy != null &&
                                          res.data.actions[0]
                                            .ReversalInitiatedBy != null
                                        ) {
                                          actionsArray.push({
                                            type: "Reversal Initiated",
                                            user:
                                              res.data.actions[0]
                                                .ReversalInitiatedBy ?? null,
                                            date:
                                              res.data.actions[0]
                                                .ReversalInitiatedDateTime,
                                            reason:
                                              res.data.actions[0]
                                                .ReversalInitiatedReason,
                                          });
                                        }
                                      }

                                      if (res.data.actions.length > 0) {
                                        if (
                                          res.data.actions[0].ReversedBy !=
                                            null &&
                                          res.data.actions[0].ReversedBy != null
                                        ) {
                                          actionsArray.push({
                                            type: "Reversed",
                                            user:
                                              res.data.actions[0].ReversedBy ??
                                              null,
                                            date:
                                              res.data.actions[0]
                                                .ReversedDateTime,
                                            reason: "-",
                                          });
                                        }
                                      }

                                      this.$refs.refInitiateReversal.actionsData = actionsArray;
                                      this.$refs.refInitiateReversal.initiateReversalTradeModal = true;
                                    } else {
                                      let verifyList = [];
                                      verifyList.push(item);
                                      this.tradeMultipleChangeStatusOpr(
                                        1,
                                        verifyList,
                                        "Revers Inititated"
                                      );
                                    }
                                  } else {
                                    this.canceledToastMessage();
                                  }
                                } else {
                                  this.notFoundMessage();
                                }
                              })
                              .catch((error) => {
                                console.log(error);
                              });
                          } else {
                            this.checkReversalInitiatedMessage(
                              res.data.message
                            );
                          }
                        });
                      } else {
                        this.checkEntryReversedMessage(res.data.message);
                      }
                    }
                  );
                } else {
                  this.checkJournalCreatedMessage(res.data.message);
                }
              }
            );
          } else {
            this.tradeIDDoesntZeroMessage();
          }
        } else {
          this.notAuthToastMessage();
        }
      });
    },
    reverseEntry(item) {
      this.$checkAccessRight("EquityBond", "Reverse").then((res) => {
        if (res.data.valid) {
          if (item.ID != 0) {
            this.checkJournalCreated("Reverse", item.TcUti).then((res) => {
              if (res.data.info == true) {
                this.checkEntryReversed("Reverse", item.TcUti).then((res) => {
                  if (res.data.info == true) {
                    this.checkReversalInitiated("Reverse", item.TcUti).then(
                      (res) => {
                        if (res.data.info == true) {
                          store
                            .dispatch("equity&bond/getReverseEntry", {
                              auth: this.user,
                              tradeId: item.ID,
                              tcUti: item.TcUti,
                            })
                            .then((res) => {
                              if (res.data.info == "Pass") {
                                if (res.data.trade.Cancelled == 0) {
                                  if (this.getChoice()) {
                                    this.$refs.refReverseEntry.fetchedTradeData =
                                      res.data.trade;

                                    //set timeline datas
                                    let actionsArray = [];
                                    if (
                                      res.data.trade.SavedBy != null &&
                                      res.data.trade.SavedBy != null
                                    ) {
                                      actionsArray.push({
                                        type: "Saved",
                                        user: res.data.trade.SavedBy ?? null,
                                        date:
                                          res.data.trade.SaveDate.split(
                                            " "
                                          )[0] +
                                          " " +
                                          res.data.trade.SaveTime.split(" ")[1],
                                        reason: "-",
                                      });
                                    }

                                    if (
                                      res.data.trade.AmendedBy != null &&
                                      res.data.trade.AmendedBy != null
                                    ) {
                                      actionsArray.push({
                                        type: "Amended",
                                        user: res.data.trade.AmendedBy ?? null,
                                        date:
                                          res.data.trade.AmendmentDate.split(
                                            " "
                                          )[0] +
                                          " " +
                                          res.data.trade.AmendmentTime.split(
                                            " "
                                          )[1],
                                        reason: res.data.trade.AmendmentReason,
                                      });
                                    }
                                    if (res.data.actions.length > 0) {
                                      if (
                                        res.data.actions[0].VerificationUser !=
                                          null &&
                                        res.data.actions[0].VerificationUser !=
                                          null
                                      ) {
                                        this.$refs.refReverseEntry.checkVerify = true;
                                        actionsArray.push({
                                          type: "Verified",
                                          user:
                                            res.data.actions[0]
                                              .VerificationUser ?? null,
                                          date:
                                            res.data.actions[0]
                                              .VerificationDateTime,
                                          reason: "-",
                                        });
                                      }
                                    }

                                    if (res.data.actions.length > 0) {
                                      if (
                                        res.data.actions[0].JournalUser !=
                                          null &&
                                        res.data.actions[0].JournalUser != null
                                      ) {
                                        actionsArray.push({
                                          type: "Journal Created",
                                          user:
                                            res.data.actions[0].JournalUser ??
                                            null,
                                          date:
                                            res.data.actions[0].JournalDateTime,
                                          reason: "-",
                                        });
                                      }
                                    }

                                    if (
                                      res.data.trade.CancelledBy != null &&
                                      res.data.trade.CancelledBy != null
                                    ) {
                                      actionsArray.push({
                                        type: "Canceled",
                                        user:
                                          res.data.trade.CancelledBy ?? null,
                                        date:
                                          res.data.trade.CancelDate.split(
                                            " "
                                          )[0] +
                                          " " +
                                          res.data.trade.CancelTime.split(
                                            " "
                                          )[1],
                                        reason: res.data.trade.CancelReason,
                                      });
                                    }

                                    if (res.data.actions.length > 0) {
                                      if (
                                        res.data.actions[0]
                                          .ReversalInitiatedBy != null &&
                                        res.data.actions[0]
                                          .ReversalInitiatedBy != null
                                      ) {
                                        actionsArray.push({
                                          type: "Reversal Initiated",
                                          user:
                                            res.data.actions[0]
                                              .ReversalInitiatedBy ?? null,
                                          date:
                                            res.data.actions[0]
                                              .ReversalInitiatedDateTime,
                                          reason:
                                            res.data.actions[0]
                                              .ReversalInitiatedReason,
                                        });
                                      }
                                    }

                                    if (res.data.actions.length > 0) {
                                      if (
                                        res.data.actions[0].ReversedBy !=
                                          null &&
                                        res.data.actions[0].ReversedBy != null
                                      ) {
                                        actionsArray.push({
                                          type: "Reversed",
                                          user:
                                            res.data.actions[0].ReversedBy ??
                                            null,
                                          date:
                                            res.data.actions[0]
                                              .ReversedDateTime,
                                          reason: "-",
                                        });
                                      }
                                    }

                                    this.$refs.refReverseEntry.actionsData = actionsArray;
                                    this.$refs.refReverseEntry.reverseEntryTradeModal = true;
                                  } else {
                                    let verifyList = [];
                                    verifyList.push(item);
                                    this.tradeMultipleChangeStatusOpr(
                                      1,
                                      verifyList,
                                      "Reversed"
                                    );
                                  }
                                } else {
                                  this.canceledToastMessage();
                                }
                              } else {
                                this.notFoundMessage();
                              }
                            })
                            .catch((error) => {
                              console.log(error);
                            });
                        } else {
                          this.checkReversalInitiatedMessage(res.data.message);
                        }
                      }
                    );
                  } else {
                    this.checkEntryReversedMessage(res.data.message);
                  }
                });
              } else {
                this.checkJournalCreatedMessage(res.data.message);
              }
            });
          } else {
            this.tradeIDDoesntZeroMessage();
          }
        } else {
          this.notAuthToastMessage();
        }
      });
    },
    undoInitiateReversal(item) {
      this.$checkAccessRight("EquityBond", "UndoInitiateReversal").then(
        (res) => {
          if (res.data.valid) {
            if (item.ID != 0) {
              this.checkEntryReversed("UndoInitiateReversal", item.TcUti).then(
                (res) => {
                  if (res.data.info == true) {
                    this.checkReversalInitiated(
                      "UndoInitiateReversal",
                      item.TcUti
                    ).then((res) => {
                      if (res.data.info == true) {
                        store
                          .dispatch("equity&bond/getUndoInitiateReversal", {
                            auth: this.user,
                            tradeId: item.ID,
                            tcUti: item.TcUti,
                          })
                          .then((res) => {
                            if (res.data.info == "Pass") {
                              if (res.data.trade.Cancelled == 0) {
                                if (this.getChoice()) {
                                  this.$refs.refUndoInitiateReversal.fetchedTradeData =
                                    res.data.trade;

                                  //set timeline datas
                                  let actionsArray = [];
                                  if (
                                    res.data.trade.SavedBy != null &&
                                    res.data.trade.SavedBy != null
                                  ) {
                                    actionsArray.push({
                                      type: "Saved",
                                      user: res.data.trade.SavedBy ?? null,
                                      date:
                                        res.data.trade.SaveDate.split(" ")[0] +
                                        " " +
                                        res.data.trade.SaveTime.split(" ")[1],
                                      reason: "-",
                                    });
                                  }

                                  if (
                                    res.data.trade.AmendedBy != null &&
                                    res.data.trade.AmendedBy != null
                                  ) {
                                    actionsArray.push({
                                      type: "Amended",
                                      user: res.data.trade.AmendedBy ?? null,
                                      date:
                                        res.data.trade.AmendmentDate.split(
                                          " "
                                        )[0] +
                                        " " +
                                        res.data.trade.AmendmentTime.split(
                                          " "
                                        )[1],
                                      reason: res.data.trade.AmendmentReason,
                                    });
                                  }
                                  if (res.data.actions.length > 0) {
                                    if (
                                      res.data.actions[0].VerificationUser !=
                                        null &&
                                      res.data.actions[0].VerificationUser !=
                                        null
                                    ) {
                                      this.$refs.refUndoInitiateReversal.checkVerify = true;
                                      actionsArray.push({
                                        type: "Verified",
                                        user:
                                          res.data.actions[0]
                                            .VerificationUser ?? null,
                                        date:
                                          res.data.actions[0]
                                            .VerificationDateTime,
                                        reason: "-",
                                      });
                                    }
                                  }

                                  if (res.data.actions.length > 0) {
                                    if (
                                      res.data.actions[0].JournalUser != null &&
                                      res.data.actions[0].JournalUser != null
                                    ) {
                                      actionsArray.push({
                                        type: "Journal Created",
                                        user:
                                          res.data.actions[0].JournalUser ??
                                          null,
                                        date:
                                          res.data.actions[0].JournalDateTime,
                                        reason: "-",
                                      });
                                    }
                                  }

                                  if (
                                    res.data.trade.CancelledBy != null &&
                                    res.data.trade.CancelledBy != null
                                  ) {
                                    actionsArray.push({
                                      type: "Canceled",
                                      user: res.data.trade.CancelledBy ?? null,
                                      date:
                                        res.data.trade.CancelDate.split(
                                          " "
                                        )[0] +
                                        " " +
                                        res.data.trade.CancelTime.split(" ")[1],
                                      reason: res.data.trade.CancelReason,
                                    });
                                  }

                                  if (res.data.actions.length > 0) {
                                    if (
                                      res.data.actions[0].ReversalInitiatedBy !=
                                        null &&
                                      res.data.actions[0].ReversalInitiatedBy !=
                                        null
                                    ) {
                                      actionsArray.push({
                                        type: "Reversal Initiated",
                                        user:
                                          res.data.actions[0]
                                            .ReversalInitiatedBy ?? null,
                                        date:
                                          res.data.actions[0]
                                            .ReversalInitiatedDateTime,
                                        reason:
                                          res.data.actions[0]
                                            .ReversalInitiatedReason,
                                      });
                                    }
                                  }

                                  if (res.data.actions.length > 0) {
                                    if (
                                      res.data.actions[0].ReversedBy != null &&
                                      res.data.actions[0].ReversedBy != null
                                    ) {
                                      actionsArray.push({
                                        type: "Reversed",
                                        user:
                                          res.data.actions[0].ReversedBy ??
                                          null,
                                        date:
                                          res.data.actions[0].ReversedDateTime,
                                        reason: "-",
                                      });
                                    }
                                  }

                                  this.$refs.refUndoInitiateReversal.actionsData = actionsArray;
                                  this.$refs.refUndoInitiateReversal.undoInitiateTradeModal = true;
                                } else {
                                  let verifyList = [];
                                  verifyList.push(item);
                                  this.tradeMultipleChangeStatusOpr(
                                    1,
                                    verifyList,
                                    "Undo Initiate Reversal"
                                  );
                                }
                              } else {
                                this.canceledToastMessage();
                              }
                            } else {
                              this.notFoundMessage();
                            }
                          })
                          .catch((error) => {
                            console.log(error);
                          });
                      } else {
                        this.checkReversalInitiatedMessage(res.data.message);
                      }
                    });
                  } else {
                    this.checkEntryReversedMessage(res.data.message);
                  }
                }
              );
            } else {
              this.tradeIDDoesntZeroMessage();
            }
          } else {
            this.notAuthToastMessage();
          }
        }
      );
    },
    cancelSelectedTrade(item) {
      this.$checkAccessRight("EquityBond", "Cancel").then((res) => {
        if (res.data.valid) {
          if (item.Cancelled == 0) {
            this.checkJournalCreated("Cancel", item.TcUti).then((res) => {
              if (res.data.info == true) {
                if (item.ID != 0) {
                  this.tradeCancelReasonText().then((res) => {
                    if (res) {
                      store
                        .dispatch("equity&bond/getCanceledEquityBondById", {
                          auth: this.user,
                          tradeId: item.ID,
                          reason: this.canceledReasonText,
                          trade: item,
                        })
                        .then((res) => {
                          if (res.data.info == "Pass") {
                            this.tradeCancelledMessage();
                            this.runLastSearch();
                            this.canceledReasonText = null;
                          } else {
                            this.tradeCancelledErrorMessage(res.data.message);
                          }
                        })
                        .catch((error) => {
                          console.log(error);
                        });
                    } else {
                      this.cancelOpearionNotDoneMessage();
                    }
                  });
                } else {
                  this.tradeIDDoesntZeroMessage();
                }
              } else {
                this.checkJournalCreatedMessage(res.data.message);
              }
            });
          } else {
            this.canceledToastMessage();
          }
        } else {
          this.notAuthToastMessage();
        }
      });
    },
    async tradeCancelReasonText() {
      const self = this;
      const { value: reason } = await this.$swal({
        title: self.$t("equity_bond.reason.cancel_reason"),
        text: self.$t("equity_bond.reason.please_enter_cancel_reason"),
        input: "textarea",
        inputPlaceholder: self.$t(
          "equity_bond.reason.please_enter_cancel_reason"
        ),
        //icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        inputAttributes: {
          style: "margin-top:5px!important",
        },
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: self.$t("equity_bond.reason.submit"),
        cancelButtonText: self.$t("equity_bond.reason.Cancel"),
        customClass: {
          confirmButton: "btn btn-primary mr-1",
          cancelButton: "btn btn-danger",
          denyButton: "btn btn-warning",
          text: "mb-1",
        },
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        inputValidator: function(reason) {
          // resons your input
          return new Promise(function(resolve, reject) {
            if (reason != null && reason != null && reason.length > 3) {
              self.canceledReasonText = reason;
              resolve();
            } else {
              resolve(self.$t("equity_bond.reason.you_need_write_reason"));
              self.validReasonTextMessage();
              //reject("Please enter a valid text");
            }
          });
        },
      });

      if (reason) {
        return new Promise(function(resolve, reject) {
          resolve(true);
        });
      }
    },
    tradeMultipleChangeStatusOpr(tradesCount, trades, status) {
      const self = this;
      this.$swal({
        title: self.$t("multiCheckBoxTitles.change_status_title"),
        text:
          self.$t("multiCheckBoxTitles.trades_count_message_1") +
          tradesCount +
          self.$t("multiCheckBoxTitles.trades_count_message_2"),
        input:
          status == "Revers Inititated" || status == "Cancel" ? "textarea" : "",
        inputPlaceholder: self.$t(
          "fx_spot_forward_swap.reason.please_enter_reversal_reason"
        ),
        //icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        inputAttributes: {
          style: "margin-top:5px!important",
        },
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: self.$t("multiCheckBoxTitles.change_status"),
        cancelButtonText: self.$t("general_buttons.cancel"),
        customClass: {
          confirmButton: "btn btn-primary mr-1",
          cancelButton: "btn btn-danger",
          denyButton: "btn btn-warning",
          text: "mb-1",
        },
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        inputValidator: function(reason) {
          // resons your input
          return new Promise(function(resolve, reject) {
            if (reason != "" && reason != null && reason.length > 3) {
              resolve();
            } else {
              resolve(
                self.$t("fx_spot_forward_swap.reason.you_need_write_reason")
              );
              self.validReasonTextMessage();
              //reject("Please enter a valid text");
            }
          });
        },
      }).then((result) => {
        if (result.isConfirmed) {
          self.loading = true;

          //if status journal set journal data
          if (status == "Create Journal") {
            if (trades.length > 0) {
              var setTradesData = [];
              trades.forEach((trade) => {
                setTradesData.push(self.setTradeData(trade));
              });
            }
            trades = setTradesData;
          }

          store
            .dispatch("equity&bond/tradeMultipleChangeEquityStatusOpr", {
              auth: self.user,
              trades: trades,
              status: status,
              reason: result.value ? result.value : null,
            })
            .then((res) => {
              self.loading = false;
              if (res.data.failedProcesses.length > 0) {
                self.multiSelectListStatusData = [];
                self.selectedMultipleTrades = [];
                self.multiSelectAllProcesesWarningMessage();
                self.showMultiSelectProcessModal = true;
                self.multiSelectProcessModalTableData =
                  res.data.failedProcesses;
              } else {
                self.multiSelectListStatusData = [];
                self.selectedMultipleTrades = [];
                self.multiSelectAllProcesesSuccessMessage();
                _.mapValues(
                  this.allEquityBondsData,
                  (v) => (v.selectedTrade = false)
                );
                if (trades.length == 1 && status == "Revers Inititated") {
                  let val = {
                    info: true,
                    tradeId: trades[0].ID,
                    tcUti: trades[0].tcUti,
                    reason: result.value ? result.value : null,
                  };
                  this.reversaledTrade(val);
                }

                self.okMultiSelectProcessModal();
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },

    tradeMultipleSelectCopyOpr(tradesCount, trades) {
      const self = this;
      this.$swal({
        title: self.$t("multiCheckBoxTitles.change_copy_title"),
        text: tradesCount + self.$t("multiCheckBoxTitles.trades_copy_message"),
        //icon: "warning",
        showCancelButton: true,
        showLoaderOnConfirm: true,
        inputAttributes: {
          style: "margin-top:5px!important",
        },
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: self.$t("multiCheckBoxTitles.copy_trades"),
        cancelButtonText: self.$t("general_buttons.cancel"),
        customClass: {
          confirmButton: "btn btn-primary mr-1",
          cancelButton: "btn btn-danger",
          denyButton: "btn btn-warning",
          text: "mb-1",
        },
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          self.loading = true;

          // set trade data
          if (trades.length > 0) {
            var setTradesData = [];
            trades.forEach((trade) => {
              setTradesData.push(self.setTradeData(trade));
            });
          }
          trades = setTradesData;
          store
            .dispatch("equity&bond/tradeMultipleSelectEquityCopyOpr", {
              auth: self.user,
              trades: trades,
            })
            .then((res) => {
              self.loading = false;
              self.selectedMultipleTrades = [];

              if (res.data.failedCopyOpr.length > 0) {
                self.multiSelectCopyWarningMessage();
                self.okMultiSelectProcessModal();
                self.showMultiSelectProcessModal = true;
                self.multiSelectProcessModalTableData = res.data.failedCopyOpr;
              } else if (res.data.info == "not_found") {
                this.wrongToastMessage(res.data.message);
              } else {
                self.multiSelectAllTradesCopySuccessMessage();
                self.okMultiSelectProcessModal();
              }
            })
            .catch((error) => {
              console.log(error);
            });
        }
      });
    },

    okMultiSelectProcessModal() {
      this.showMultiSelectProcessModal = false;
      this.multiSelectProcessModalTableData = [];
      _.mapValues(this.allEquityBondsData, (v) => (v.selectedTrade = false));

      this.getLast7DaysTrades();
    },

    searchForTradesModal() {
      this.$checkAccessRight("EquityBond", "Search").then((res) => {
        if (res.data.valid) {
          this.$refs.searchTrades.searchForTradesModal = true;
          //this.$refs.searchTrades.getAllAccounts();
        } else {
          this.notAuthToastMessage();
        }
      });
    },
    getSearchedData(data) {
      this.totalRows = data.length;
      if (data.length > 0) {
        this.visibleEquityBondStatusInput = true;
      }
      this.allEquityBondsData = data;
      this.allEquityBondNotFilterData = data;
      this.$refs.searchTrades.searchForTradesModal = false;
    },
    getUpdatedAmendData(val) {
      if (val) {
        this.runLastSearch();
      }
    },
    createNewTrade() {
      this.$checkAccessRight("EquityBond", "Save").then((res) => {
        if (res.data.valid) {
          this.$refs.refEnterNewTrade.getSavedDefaultEntries();
          this.$refs.refEnterNewTrade.getAllSecurityDesc();
          this.$refs.refEnterNewTrade.enterNewTradeModal = true;
        } else {
          this.notAuthToastMessage();
        }
      });
    },
    createdEquityBond(val) {
      if (val) {
        this.runLastSearch();
      }
    },
    copiedEquityBond(val) {
      if (val) {
        this.runLastSearch();
      }
    },
    createdJournalEntryArray(journalData) {
      if (journalData.success) {
        this.$refs.refViewJournalEntries.fetchedTradeData = journalData.trade;
        this.$refs.refViewJournalEntries.journalEntries = journalData.entries;
        this.$refs.refViewJournalEntries.viewJournalTradeModal = true;
        this.$refs.refViewJournalEntries.createJournalOperation = true;
      }
    },
    createdJournalEntry(val) {
      if (val) {
        this.runLastSearch();
      }
    },
    reversaledTrade(val) {
      const self = this;
      if (val.info) {
        this.runLastSearch();
        this.$swal({
          title: self.$t("equity_bond.mail.title"),
          text: self.$t("equity_bond.mail.message"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: self.$t("equity_bond.mail.send"),
          cancelButtonText: self.$t("equity_bond.mail.not_send"),
          customClass: {
            confirmButton: "btn btn-success mr-1",
            cancelButton: "btn btn-danger",
            denyButton: "btn btn-primary",
          },
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
        }).then((result) => {
          if (result.isConfirmed) {
            store
              .dispatch("equity&bond/sendInitiateReversalMail", {
                auth: self.user,
                tcUti: val.tcUti,
                tradeId: val.tradeId,
                reason: val.reason,
              })
              .then((res) => {
                if (res.data.info == "Pass") {
                  self.sendedMailSuccess();
                } else {
                  self.errorMessage(res.data.message);
                }
              })
              .catch((error) => {
                console.log(error);
              });
          }
        });
      }
    },
    reversedEntry(val) {
      if (val) {
        this.runLastSearch();
      }
    },
    undoInitiate(val) {
      if (val) {
        this.runLastSearch();
      }
    },
    verifiedTrade(val) {
      if (val) {
        this.runLastSearch();
      }
    },
    legendClass(item, type) {
      const executedRowColor = "table-executed";
      const verifiedRowColor = "table-verified";
      const journalCreateRowColor = "table-journalCreate";
      const expiredOrderRowColor = "table-expiredOrder";
      const reversedInitiateRowColor = "table-reverseInitiated";
      const reversedRowColor = "table-reversed";
      const canceledRowColor = "table-canceled";

      if (!item || type !== "row") {
        return;
      }

      if (item.Cancelled == 1) {
        return canceledRowColor;
      }
      if (item.Reversed == 1) {
        return reversedRowColor;
      }
      if (item.ReversalInitiated == 1) {
        return reversedInitiateRowColor;
      }
      if (item.ExpiredOrder == 1) {
        return expiredOrderRowColor;
      }
      if (item.JournalCreated == 1) {
        return journalCreateRowColor;
      }
      if (item.Verified == 1) {
        return verifiedRowColor;
      }
      if (item.EntryType == "Execution") {
        return executedRowColor;
      }
    },
    onRowSelected(row) {
      row._showDetails = !row._showDetails;
    },
    runLastSearch() {
      this.$checkAccessRight("EquityBond", "Search").then((res) => {
        if (res.data.valid) {
          this.loading = true;
          store
            .dispatch("equity&bond/getLastSearch", {
              auth: this.user,
              searchType: "EquityBond",
            })
            .then((res) => {
              if (res.data != "Not Found") {
                if (res.data.length > 0) {
                  this.allEquityBondsData = [];
                  if (res.data.length > 0) {
                    this.visibleEquityBondStatusInput = true;
                  } else {
                    this.visibleEquityBondStatusInput = true;
                  }
                  this.totalRows = res.data.length;
                  this.allEquityBondsData = res.data;
                  this.allEquityBondNotFilterData = res.data;
                  this.loading = false;
                } else {
                  this.getLast7DaysTrades();
                }
              } else {
                this.notFoundSearchData();
                this.loading = false;
              }
            })
            .catch((error) => {
              this.loading = false;
              console.log(error);
            });
        } else {
          this.notAuthToastMessage();
        }
      });
    },

    setSearchData() {
      let setSearch = {
        costCenter: this.tradeForm.costCenter ?? null,
        instrumentType: this.tradeForm.instrumentType,
        tradeDate:
          this.tradeForm.tradeDate != null
            ? this.YMDdateFormat(this.tradeForm.tradeDate)
            : null,
        tradeDateEnd:
          this.tradeForm.tradeDateEnd != null
            ? this.YMDdateFormat(this.tradeForm.tradeDateEnd)
            : null,
        settlementDate:
          this.tradeForm.settlementDate != null
            ? this.YMDdateFormat(this.tradeForm.settlementDate)
            : null,
        settlementDateEnd:
          this.tradeForm.settlementDateEnd != null
            ? this.YMDdateFormat(this.tradeForm.settlementDateEnd)
            : null,
        orderTime:
          this.tradeForm.orderTime != null ? this.tradeForm.orderTime : null,
        executionTime:
          this.tradeForm.executionTime != null
            ? this.tradeForm.executionTime
            : null,
        client: this.tradeForm.client,
        account: this.tradeForm.clientAccount,
        securityDesc: this.tradeForm.securityDesc,
        isin: this.tradeForm.isin,
        buySell: this.tradeForm.buySell,
        quantity:
          this.tradeForm.quantity != null ? this.tradeForm.quantity : null,
        clientAmount:
          this.tradeForm.clientAmount != null
            ? parseFloat(this.tradeForm.clientAmount.toFixed(2))
            : null,
        counterpartyRate:
          this.tradeForm.counterpartyRate != null
            ? parseFloat(this.tradeForm.counterpartyRate.toFixed(2))
            : null,
        comissionRate:
          this.tradeForm.clientRate != null
            ? parseFloat(this.tradeForm.clientRate.toFixed(2))
            : null,
        counterpartyComission:
          this.tradeForm.cptyCommission != null
            ? parseFloat(this.tradeForm.cptyCommission.toFixed(2))
            : null,
        commissionAmount:
          this.tradeForm.clientCommission != null
            ? parseFloat(this.tradeForm.clientCommission.toFixed(2))
            : null,
        counterpartyAmount:
          this.tradeForm.counterpartyAmount != null
            ? parseFloat(this.tradeForm.counterpartyAmount.toFixed(2))
            : null,
        counterpartyAccount: this.tradeForm.cptyAccount,
        counterpartyTrader: this.tradeForm.counterpartyTrader,
        counterpartyPrice:
          this.tradeForm.counterpartyPrice != null
            ? parseFloat(this.tradeForm.counterpartyPrice.toFixed(2))
            : null,
        accuredInterest:
          this.tradeForm.accrued != null
            ? parseFloat(this.tradeForm.accrued.toFixed(2))
            : null,
        ccy: this.tradeForm.clientPriceType,
        price:
          this.tradeForm.clientPrice != null
            ? parseFloat(this.tradeForm.clientPrice.toFixed(2))
            : null,
        amount:
          this.tradeForm.amount != null
            ? parseFloat(this.tradeForm.amount.toFixed(2))
            : null,
        counterparty: this.tradeForm.counterparty,
        tcUser: this.tradeForm.scfUser,
        orderTakenVia: this.tradeForm.orderTakenVia,
        orderGivenThrough: this.tradeForm.orderGivenThrough,
        orderPrice: null,
        exchangeTraded: this.tradeForm.exchangeTraded,
        traderNote: this.tradeForm.note,
        securityAccount: null,
        actingCapaticy: this.tradeForm.actingCapaticy,
        counterpartOrderTime:
          this.tradeForm.cptyOrderTime != null
            ? this.tradeForm.cptyOrderTime
            : null,
        counterpartyExecutionTime:
          this.tradeForm.cptyExecutionTime != null
            ? this.tradeForm.cptyExecutionTime
            : null,
        tax:
          this.tradeForm.tax != null
            ? parseFloat(this.tradeForm.tax.toFixed(2))
            : null,
        tcUti: this.tradeForm.uti,
        uniqueLinkId: this.tradeForm.uniqueLinkID,
        tcProfit:
          this.tradeForm.profit != null
            ? parseFloat(this.tradeForm.profit.toFixed(2))
            : null,
        entryType: this.tradeForm.entryType,
        orderType: this.tradeForm.orderType,
        timeInForce: this.tradeForm.timeInForce,
        stopPrice:
          this.tradeForm.stopPrice != null
            ? parseFloat(this.tradeForm.stopPrice.toFixed(2))
            : null,
        trailingAmount:
          this.tradeForm.trailingAmount != null
            ? parseFloat(this.tradeForm.tradeForm.toFixed(2))
            : null,
        takeProfit: null,
        stopLoss:
          this.tradeForm.stopLossStop != null
            ? parseFloat(this.tradeForm.stopLossStop.toFixed(2))
            : null,
        orderDate:
          this.tradeForm.orderDate != null
            ? this.YMDdateFormat(this.tradeForm.orderDate)
            : null,
        orderDateEnd:
          this.tradeForm.orderDateEnd != null
            ? this.YMDdateFormat(this.tradeForm.orderDateEnd)
            : null,
        counterpartyOrderDate:
          this.tradeForm.cptyOrderDate != null
            ? this.YMDdateFormat(this.tradeForm.cptyOrderDate)
            : null,
        counterpartyOrderDateEnd:
          this.tradeForm.cptyOrderDateEnd != null
            ? this.YMDdateFormat(this.tradeForm.cptyOrderDateEnd)
            : null,
        orderDurationDate:
          this.tradeForm.orderDurationDate != null
            ? this.YMDdateFormat(this.tradeForm.orderDurationDate)
            : null,
        orderDurationDateEnd:
          this.tradeForm.orderDurationDateEnd != null
            ? this.YMDdateFormat(this.tradeForm.orderDurationDateEnd)
            : null,
        orderDurationTime:
          this.tradeForm.orderDurationTime != null
            ? this.tradeForm.orderDurationTime
            : null,
        enableOCO: this.tradeForm.enableConnectedOCOOrders,
        ocoType: null,
        takeProfitLimit:
          this.tradeForm.takeProfitLimit != null
            ? parseFloat(this.tradeForm.takeProfitLimit.toFixed(2))
            : null,
        stopLossLimit:
          this.tradeForm.stopLossLimit != null
            ? parseFloat(this.tradeForm.stopLossLimit.toFixed(2))
            : null,
        ocoLink: null,
        orderQuantity: null,
        future4: null,
        future5: null,
        future6: null,
        future7: null,
        future8: null,
        future9: null,
        future10: null,
        future11: null,
        future12: null,
        future13: null,
        future14: null,
        future15: null,
        future16: null,
        startTradeDate:
          this.tradeForm.tradeStart != null
            ? this.YMDdateFormat(this.tradeForm.tradeStart)
            : null,
        endTradeDate:
          this.tradeForm.tradeEnd != null
            ? this.YMDdateFormat(this.tradeForm.tradeEnd)
            : null,
        startSettlementDate:
          this.tradeForm.settleStart != null
            ? this.YMDdateFormat(this.tradeForm.settleStart)
            : null,
        endSettlementDate:
          this.tradeForm.settleEnd != null
            ? this.YMDdateFormat(this.tradeForm.settleEnd)
            : null,
        ID: 0,

        stopPriceEnd: this.tradeForm.stopPriceEnd,
        trailingAmountEnd: this.tradeForm.trailingAmountEnd,
        takeProfitLimitEnd: this.tradeForm.takeProfitLimitEnd,
        stopLossEnd: this.tradeForm.stopLossStopEnd,
        stopLossLimitEnd: this.tradeForm.stopLossLimitEnd,
        quantityEnd: this.tradeForm.quantityEnd,
        priceEnd: this.tradeForm.clientPriceEnd,
        amountEnd: this.tradeForm.amountEnd,
        counterpartyPriceEnd: this.tradeForm.counterpartyPriceEnd,
        calculateCommissionFromRate1End: this.tradeForm
          .calculateCommissionFromRate1End,
        comissionRateEnd: this.tradeForm.clientRateEnd,
        commissionAmountEnd: this.tradeForm.clientCommissionEnd,
        clientAmountEnd: this.tradeForm.clientAmountEnd,
        calculateComissionFromRate2End: this.tradeForm
          .calculateCommissionFromRate2End,
        counterpartyRateEnd: this.tradeForm.counterpartyRateEnd,
        counterpartyComissionEnd: this.tradeForm.cptyCommissionEnd,
        counterpartyAmountEnd: this.tradeForm.counterpartyAmountEnd,
        tcProfitEnd: this.tradeForm.profitEnd,
        taxEnd: this.tradeForm.taxEnd,
      };

      return setSearch;
    },
    setTradeData(val) {
      if (
        val.TimeinForce == "GTC" ||
        val.TimeinForce == "FOK" ||
        val.TimeinForce == "FAK"
      ) {
        val.OrderDurationDate = null;
        val.OrderDurationTime = null;
      }

      if (val.Tax != null) {
        val.Tax = Number(val.Tax);
      }

      let setTrade = {
        costCenter: val.CostCenter,

        instrumentType: val.InstrumentType,
        tradeDate:
          val.TradeDate != null ? this.YMDdateFormat(val.TradeDate) : null,
        settlementDate:
          val.SettlementDate != null
            ? this.YMDdateFormat(val.SettlementDate)
            : null,
        orderTime:
          val.OrderTime != null ? this.HmstimeFormat(val.OrderTime) : null,
        executionTime:
          val.ExecutionTime != null
            ? this.HmstimeFormat(val.ExecutionTime)
            : null,
        client: val.Client,
        account: val.Account,
        securityDesc: val.SecurityDescription,
        isin: val.Isin,
        buySell: val.BuySell,
        quantity: val.Quantity != null ? val.Quantity : 0,
        clientAmount:
          val.ClientAmount != null ? parseFloat(val.ClientAmount) : null,
        counterpartyRate:
          val.CounterpartyRate != null
            ? parseFloat(val.CounterpartyRate)
            : null,
        comissionRate:
          val.CommissionRate != null ? parseFloat(val.CommissionRate) : null,
        counterpartyComission:
          val.CounterpartyCommission != null
            ? parseFloat(val.CounterpartyCommission)
            : null,
        commissionAmount:
          val.CommissionAmount != null
            ? parseFloat(val.CommissionAmount)
            : null,
        counterpartyAmount:
          val.CounterpartyAmount != null
            ? parseFloat(val.CounterpartyAmount)
            : null,
        counterpartyAccount: val.CounterpartyAccount,
        counterpartyTrader: val.CounterpartyTrader,
        counterpartyPrice:
          val.CounterpartyPrice != null
            ? parseFloat(val.CounterpartyPrice)
            : null,
        clean: val.Clean ? 1 : 0,
        margin: val.Margin ? 1 : 0,
        saveDate: moment().format("YYYY-MM-DD"),
        saveTime: moment().format("HH:mm:ss"),
        accuredInterest:
          val.AccuredInterest != null ? parseFloat(val.AccuredInterest) : null,
        ccy: val.Ccy,
        price: val.Price != null ? parseFloat(val.Price) : null,
        amount: val.Amount != null ? parseFloat(val.Amount) : null,
        tax: this.tradeForm.Tax != null ? parseFloat(this.tradeForm.Tax) : null,
        counterparty: val.Counterparty,
        tcUser: val.TcUser,
        orderTakenVia: val.OrderTakenVia,
        orderGivenThrough: val.OrderGivenThrough,
        orderPrice: null,
        exchangeTraded: val.ExchangeTraded,
        prop: val.Prop ? 1 : 0,
        traderNote: val.TraderNote,
        securityAccount: null,
        actingCapaticy: val.ActingCapacity,
        counterpartOrderTime:
          val.CptyOrderTime != null
            ? this.HmstimeFormat(val.CptyOrderTime)
            : null,
        counterpartyExecutionTime:
          val.CptyExecutionTime != null
            ? this.HmstimeFormat(val.CptyExecutionTime)
            : null,
        tcUti: val.TcUti,
        uniqueLinkId: val.UniqueLinkId,
        tcProfit: val.TcProfit != null ? parseFloat(val.TcProfit) : null,
        entryType: val.EntryType,
        orderType: val.OrderType,
        timeInForce: val.TimeinForce,
        stopPrice: val.StopPrice != null ? parseFloat(val.StopPrice) : null,
        trailingAmount:
          val.TrailingAmount != null ? parseFloat(val.TrailingAmount) : null,
        takeProfit: null,
        stopLoss: val.StopLoss != null ? parseFloat(val.StopLoss) : null,
        orderDate:
          val.OrderDate != null ? this.YMDdateFormat(val.OrderDate) : null,
        counterpartyOrderDate:
          val.CounterpartyOrderDate != null
            ? this.YMDdateFormat(val.CounterpartyOrderDate)
            : null,
        orderDurationDate:
          val.OrderDurationDate != null
            ? this.YMDdateFormat(val.OrderDurationDate)
            : null,
        orderDurationTime:
          val.OrderDurationTime != null
            ? this.HmstimeFormat(val.OrderDurationTime)
            : null,
        enableOCO: val.EnableOCO == "True" ? val.EnableOCO : false,
        ocoType: null,
        takeProfitLimit:
          val.TakeProfitLimit != null ? parseFloat(val.TakeProfitLimit) : null,
        stopLossLimit:
          val.StopLossLimit != null ? parseFloat(val.StopLossLimit) : null,
        ocoLink: val.OCOLink,
        future4: null,
        future5: null,
        future6: null,
        future7: null,
        future8: null,
        future9: null,
        future10: null,
        future11: null,
        future12: null,
        future13: null,
        future14: null,
        future15: null,
        future16: null,
        ID: val.ID,
      };

      return setTrade;
    },

    getFilterTradeDate(e) {
      if (this.tradeRangeDate != null) {
        this.$checkAccessRight("EquityBond", "Search").then((res) => {
          if (res.data.valid) {
            let filterTradeDateStart = null;
            let filterTradeDateEnd = null;
            if (
              this.filterTradeDateStart != null &&
              this.filterTradeDateEnd != null
            ) {
              filterTradeDateStart = this.YMDdateFormat(
                this.filterTradeDateStart
              );
              filterTradeDateEnd = this.YMDdateFormat(this.filterTradeDateEnd);
            }

            let searchData = this.setSearchData();

            if (filterTradeDateStart != null && filterTradeDateEnd != null) {
              searchData.tradeDate = filterTradeDateStart;
              searchData.tradeDateEnd = filterTradeDateEnd;

              this.loading = true;
              if (filterTradeDateStart != null && filterTradeDateEnd != null) {
                store
                  .dispatch("equity&bond/searchEquityBond", {
                    auth: this.user,
                    searchData: searchData,
                  })
                  .then((res) => {
                    this.allEquityBondsData = [];
                    if (res.data.length > 0) {
                      this.visibleEquityBondStatusInput = true;
                      this.totalRows = res.data.length;
                      this.allEquityBondsData = res.data;
                    } else {
                      this.visibleEquityBondStatusInput = false;
                    }

                    this.loading = false;
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
            } else {
              this.notAuthToastMessage();
            }
          }
        });
      }
    },
    getFilterSettlementDate() {
      if (this.settleRangeDate != null) {
        this.$checkAccessRight("EquityBond", "Search").then((res) => {
          if (res.data.valid) {
            let filterSettleDateStart = null;
            let filterSettleDateEnd = null;

            if (
              this.filterSettleDateStart != null &&
              this.filterSettleDateEnd != null
            ) {
              filterSettleDateStart = this.YMDdateFormat(
                this.filterSettleDateStart
              );
              filterSettleDateEnd = this.YMDdateFormat(
                this.filterSettleDateEnd
              );
            }

            let searchData = this.setSearchData();

            if (filterSettleDateStart != null && filterSettleDateEnd != null) {
              searchData.settlementDate = filterSettleDateStart;
              searchData.settlementDateEnd = filterSettleDateEnd;

              this.loading = true;
              if (
                filterSettleDateStart != null &&
                filterSettleDateEnd != null
              ) {
                store
                  .dispatch("equity&bond/searchEquityBond", {
                    auth: this.user,
                    searchData: searchData,
                  })
                  .then((res) => {
                    this.allEquityBondsData = [];
                    if (res.data.length > 0) {
                      this.visibleEquityBondStatusInput = true;
                      this.totalRows = res.data.length;
                      this.allEquityBondsData = res.data;
                    } else {
                      this.visibleEquityBondStatusInput = false;
                    }
                    this.loading = false;
                  })
                  .catch((error) => {
                    console.log(error);
                  });
              }
            } else {
              this.notAuthToastMessage();
            }
          }
        });
      }
    },

    getChoice() {
      return this.userData.personal_settings.find(
        (e) => e.key == "equity_tickets"
      ).choice;
    },
    verifySelectedTrade(item) {
      if (item.ID != 0) {
        if (item.Cancelled == 0) {
          this.checkTradeOperations(item).then((res) => {
            if (res == true) {
              store
                .dispatch("equity&bond/getVerifySelectedTrade", {
                  auth: this.user,
                  tradeId: item.ID,
                  tcUti: item.TcUti,
                })
                .then((res) => {
                  if (res.data.info == "Pass") {
                    if (this.getChoice()) {
                      this.$refs.refVerifyTrade.verifySelectedTradeModal = true;
                      this.$refs.refVerifyTrade.fetchedTradeData =
                        res.data.trade;

                      // set changes inputs
                      if (res.data.changedInputs) {
                        let setChangedInputs = _.map(
                          res.data.changedInputs,
                          (value, key) => ({ key, value })
                        );

                        if (setChangedInputs.length > 0) {
                          this.$refs.refVerifyTrade.changedInputs = setChangedInputs;
                        }
                      }

                      //set timeline datas
                      let actionsArray = [];
                      if (
                        res.data.trade.SavedBy != null &&
                        res.data.trade.SavedBy != null
                      ) {
                        actionsArray.push({
                          type: "Saved",
                          user: res.data.trade.SavedBy ?? null,
                          date:
                            res.data.trade.SaveDate.split(" ")[0] +
                            " " +
                            res.data.trade.SaveTime,
                          reason: "-",
                        });
                      }

                      if (
                        res.data.trade.AmendedBy != null &&
                        res.data.trade.AmendedBy != null
                      ) {
                        actionsArray.push({
                          type: "Amended",
                          user: res.data.trade.AmendedBy ?? null,
                          date:
                            res.data.trade.AmendmentDate.split(" ")[0] +
                            " " +
                            res.data.trade.AmendmentTime,
                          reason: res.data.trade.AmendmentReason,
                        });
                      }
                      if (res.data.actions.length > 0) {
                        if (
                          res.data.actions[0].VerificationUser != null &&
                          res.data.actions[0].VerificationUser != null
                        ) {
                          actionsArray.push({
                            type: "Verified",
                            user: res.data.actions[0].VerificationUser ?? null,
                            date: res.data.actions[0].VerificationDateTime,
                            reason: "-",
                          });
                        }
                      }

                      if (res.data.actions.length > 0) {
                        if (
                          res.data.actions[0].JournalUser != null &&
                          res.data.actions[0].JournalUser != null
                        ) {
                          actionsArray.push({
                            type: "Journal Created",
                            user: res.data.actions[0].JournalUser ?? null,
                            date: res.data.actions[0].JournalDateTime,
                            reason: "-",
                          });
                        }
                      }

                      if (
                        res.data.trade.CancelledBy != null &&
                        res.data.trade.CancelledBy != null
                      ) {
                        actionsArray.push({
                          type: "Canceled",
                          user: res.data.trade.CancelledBy ?? null,
                          date:
                            res.data.trade.CancelDate.split(" ")[0] +
                            " " +
                            res.data.trade.CancelTime,
                          reason: res.data.trade.CancelReason,
                        });
                      }

                      if (res.data.actions.length > 0) {
                        if (
                          res.data.actions[0].ReversalInitiatedBy != null &&
                          res.data.actions[0].ReversalInitiatedBy != null
                        ) {
                          actionsArray.push({
                            type: "Reversal Initiated",
                            user:
                              res.data.actions[0].ReversalInitiatedBy ?? null,
                            date: res.data.actions[0].ReversalInitiatedDateTime,
                            reason: res.data.actions[0].ReversalInitiatedReason,
                          });
                        }
                      }

                      if (res.data.actions.length > 0) {
                        if (
                          res.data.actions[0].ReversedBy != null &&
                          res.data.actions[0].ReversedBy != null
                        ) {
                          actionsArray.push({
                            type: "Reversed",
                            user: res.data.actions[0].ReversedBy ?? null,
                            date: res.data.actions[0].ReversedDateTime,
                            reason: "-",
                          });
                        }
                      }

                      this.$refs.refVerifyTrade.actionsData = actionsArray;
                    } else {
                      let verifyList = [];
                      verifyList.push(item);
                      this.tradeMultipleChangeStatusOpr(
                        1,
                        verifyList,
                        "Verify"
                      );
                    }
                  } else {
                    this.notFoundMessage();
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            }
          });
        } else {
          this.canceledToastMessage();
        }
      } else {
        this.tradeIDDoesntZeroMessage();
      }
    },
    amendUpdate(item) {
      this.$checkAccessRight("EquityBond", "Amend").then((res) => {
        if (res.data.valid) {
          if (item.ID != 0) {
            if (item.Cancelled == 0) {
              this.checkJournalCreated("Amend", item.TcUti).then((res) => {
                if (res.data.info == true) {
                  store
                    .dispatch("equity&bond/getAmendSelectedTrade", {
                      auth: this.user,
                      tradeId: item.ID,
                      tcUti: item.TcUti,
                    })
                    .then((res) => {
                      if (res.data.info == "Pass") {
                        this.$refs.refAmendTrade.getAllSecurityDesc();
                        this.$refs.refAmendTrade.fetchedTradeData =
                          res.data.trade;
                        this.$refs.refAmendTrade.amendTradeModal = true;
                        //this.$refs.refAmendTrade.getAllAccounts();

                        //set timeline datas
                        let actionsArray = [];
                        if (
                          res.data.trade.SavedBy != null &&
                          res.data.trade.SavedBy != null
                        ) {
                          actionsArray.push({
                            type: "Saved",
                            user: res.data.trade.SavedBy ?? null,
                            date:
                              res.data.trade.SaveDate.split(" ")[0] +
                              " " +
                              res.data.trade.SaveTime,
                            reason: "-",
                          });
                        }

                        if (
                          res.data.trade.AmendedBy != null &&
                          res.data.trade.AmendedBy != null
                        ) {
                          actionsArray.push({
                            type: "Amended",
                            user: res.data.trade.AmendedBy ?? null,
                            date:
                              res.data.trade.AmendmentDate.split(" ")[0] +
                              " " +
                              res.data.trade.AmendmentTime,
                            reason: res.data.trade.AmendmentReason,
                          });
                        }
                        if (res.data.actions.length > 0) {
                          if (
                            res.data.actions[0].VerificationUser != null &&
                            res.data.actions[0].VerificationUser != null
                          ) {
                            this.$refs.refAmendTrade.checkVerify = true;
                            actionsArray.push({
                              type: "Verified",
                              user:
                                res.data.actions[0].VerificationUser ?? null,
                              date: res.data.actions[0].VerificationDateTime,
                              reason: "-",
                            });
                          }
                        }

                        if (res.data.actions.length > 0) {
                          if (
                            res.data.actions[0].JournalUser != null &&
                            res.data.actions[0].JournalUser != null
                          ) {
                            actionsArray.push({
                              type: "Journal Created",
                              user: res.data.actions[0].JournalUser ?? null,
                              date: res.data.actions[0].JournalDateTime,
                              reason: "-",
                            });
                          }
                        }

                        if (
                          res.data.trade.CancelledBy != null &&
                          res.data.trade.CancelledBy != null
                        ) {
                          actionsArray.push({
                            type: "Canceled",
                            user: res.data.trade.CancelledBy ?? null,
                            date:
                              res.data.trade.CancelDate.split(" ")[0] +
                              " " +
                              res.data.trade.CancelTime,
                            reason: res.data.trade.CancelReason,
                          });
                        }

                        if (res.data.actions.length > 0) {
                          if (
                            res.data.actions[0].ReversalInitiatedBy != null &&
                            res.data.actions[0].ReversalInitiatedBy != null
                          ) {
                            actionsArray.push({
                              type: "Reversal Initiated",
                              user:
                                res.data.actions[0].ReversalInitiatedBy ?? null,
                              date:
                                res.data.actions[0].ReversalInitiatedDateTime,
                              reason:
                                res.data.actions[0].ReversalInitiatedReason,
                            });
                          }
                        }

                        if (res.data.actions.length > 0) {
                          if (
                            res.data.actions[0].ReversedBy != null &&
                            res.data.actions[0].ReversedBy != null
                          ) {
                            actionsArray.push({
                              type: "Reversed",
                              user: res.data.actions[0].ReversedBy ?? null,
                              date: res.data.actions[0].ReversedDateTime,
                              reason: "-",
                            });
                          }
                        }

                        this.$refs.refAmendTrade.actionsData = actionsArray;
                      } else {
                        this.notFoundMessage();
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                } else {
                  this.checkJournalCreatedMessage(res.data.message);
                }
              });
            } else {
              this.canceledToastMessage();
            }
          } else {
            this.tradeIDDoesntZeroMessage();
          }
        } else {
          this.notAuthToastMessage();
        }
      });
    },
    createJournalEntries(item) {
      this.$checkAccessRight("EquityBond", "CreateJournal").then((res) => {
        if (res.data.valid) {
          store
            .dispatch("equity&bond/checkJournalTradeVerifyOperation", {
              auth: this.user,
              trade: item,
            })
            .then((res) => {
              if (res.data.info == "verified") {
                this.checkJournalCreated("Journal", item.TcUti).then((res) => {
                  if (res.data.info == true) {
                    store
                      .dispatch("equity&bond/getCreateJournalTrade", {
                        auth: this.user,
                        tradeId: item.ID,
                        tcUti: item.TcUti,
                      })
                      .then((res) => {
                        if (res.data.info == "Pass") {
                          if (res.data.trade.Cancelled == 0) {
                            if (this.getChoice()) {
                              this.$refs.refCreateJournalTrade.fetchedTradeData =
                                res.data.trade;

                              // set changes inputs
                              if (res.data.changedInputs) {
                                let setChangedInputs = _.map(
                                  res.data.changedInputs,
                                  (value, key) => ({ key, value })
                                );

                                if (setChangedInputs.length > 0) {
                                  this.$refs.refCreateJournalTrade.changedInputs = setChangedInputs;
                                }
                              }

                              //set timeline datas
                              let actionsArray = [];
                              if (
                                res.data.trade.SavedBy != null &&
                                res.data.trade.SavedBy != null
                              ) {
                                actionsArray.push({
                                  type: "Saved",
                                  user: res.data.trade.SavedBy ?? null,
                                  date:
                                    res.data.trade.SaveDate.split(" ")[0] +
                                    " " +
                                    res.data.trade.SaveTime,
                                  reason: "-",
                                });
                              }

                              if (
                                res.data.trade.AmendedBy != null &&
                                res.data.trade.AmendedBy != null
                              ) {
                                actionsArray.push({
                                  type: "Amended",
                                  user: res.data.trade.AmendedBy ?? null,
                                  date:
                                    res.data.trade.AmendmentDate.split(" ")[0] +
                                    " " +
                                    res.data.trade.AmendmentTime,
                                  reason: res.data.trade.AmendmentReason,
                                });
                              }
                              if (res.data.actions.length > 0) {
                                if (
                                  res.data.actions[0].VerificationUser !=
                                    null &&
                                  res.data.actions[0].VerificationUser != null
                                ) {
                                  this.$refs.refCreateJournalTrade.checkVerify = true;
                                  actionsArray.push({
                                    type: "Verified",
                                    user:
                                      res.data.actions[0].VerificationUser ??
                                      null,
                                    date:
                                      res.data.actions[0].VerificationDateTime,
                                    reason: "-",
                                  });
                                }
                              }

                              if (res.data.actions.length > 0) {
                                if (
                                  res.data.actions[0].JournalUser != null &&
                                  res.data.actions[0].JournalUser != null
                                ) {
                                  actionsArray.push({
                                    type: "Journal Created",
                                    user:
                                      res.data.actions[0].JournalUser ?? null,
                                    date: res.data.actions[0].JournalDateTime,
                                    reason: "-",
                                  });
                                }
                              }

                              if (
                                res.data.trade.CancelledBy != null &&
                                res.data.trade.CancelledBy != null
                              ) {
                                actionsArray.push({
                                  type: "Canceled",
                                  user: res.data.trade.CancelledBy ?? null,
                                  date:
                                    res.data.trade.CancelDate.split(" ")[0] +
                                    " " +
                                    res.data.trade.CancelTime,
                                  reason: res.data.trade.CancelReason,
                                });
                              }

                              if (res.data.actions.length > 0) {
                                if (
                                  res.data.actions[0].ReversalInitiatedBy !=
                                    null &&
                                  res.data.actions[0].ReversalInitiatedBy !=
                                    null
                                ) {
                                  actionsArray.push({
                                    type: "Reversal Initiated",
                                    user:
                                      res.data.actions[0].ReversalInitiatedBy ??
                                      null,
                                    date:
                                      res.data.actions[0]
                                        .ReversalInitiatedDateTime,
                                    reason:
                                      res.data.actions[0]
                                        .ReversalInitiatedReason,
                                  });
                                }
                              }

                              if (res.data.actions.length > 0) {
                                if (
                                  res.data.actions[0].ReversedBy != null &&
                                  res.data.actions[0].ReversedBy != null
                                ) {
                                  actionsArray.push({
                                    type: "Reversed",
                                    user:
                                      res.data.actions[0].ReversedBy ?? null,
                                    date: res.data.actions[0].ReversedDateTime,
                                    reason: "-",
                                  });
                                }
                              }

                              this.$refs.refCreateJournalTrade.actionsData = actionsArray;
                              this.$refs.refCreateJournalTrade.createJournalTradeModal = true;
                            } else {
                              let verifyList = [];
                              verifyList.push(item);
                              this.tradeMultipleChangeStatusOpr(
                                1,
                                verifyList,
                                "Create Journal"
                              );
                            }
                          } else {
                            this.canceledToastMessage();
                          }
                        } else {
                          this.notFoundMessage();
                        }
                      })
                      .catch((error) => {
                        console.log(error);
                      });
                  } else {
                    this.checkJournalCreatedMessage(res.data.message);
                  }
                });
              } else {
                if (res.data.info == "not_verified") {
                  this.checkTradeUnVerifyOperationLangMessage();
                } else {
                  this.checkTradeVerifyOperationMessage(res.data.message);
                }
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.notAuthToastMessage();
        }
      });
    },
    viewSelectedTrade(item) {
      this.$checkAccessRight("EquityBond", "View").then((res) => {
        if (res.data.valid) {
          if (item.ID != 0) {
            store
              .dispatch("equity&bond/getViewSelectedTrade", {
                auth: this.user,
                tradeId: item.ID,
                tcUti: item.TcUti,
              })
              .then((res) => {
                if (res.data.info == "Pass") {
                  this.$refs.ViewSelectedTrade.viewSelectedTradeModal = true;
                  this.$refs.ViewSelectedTrade.fetchedTradeData =
                    res.data.trade;

                  //set timeline datas
                  let actionsArray = [];
                  if (
                    res.data.trade.SavedBy != null &&
                    res.data.trade.SavedBy != null
                  ) {
                    actionsArray.push({
                      type: "Saved",
                      user: res.data.trade.SavedBy ?? null,
                      date:
                        res.data.trade.SaveDate.split(" ")[0] +
                        " " +
                        res.data.trade.SaveTime,
                      reason: "-",
                    });
                  }

                  if (
                    res.data.trade.AmendedBy != null &&
                    res.data.trade.AmendedBy != null
                  ) {
                    actionsArray.push({
                      type: "Amended",
                      user: res.data.trade.AmendedBy ?? null,
                      date:
                        res.data.trade.AmendmentDate.split(" ")[0] +
                        " " +
                        res.data.trade.AmendmentTime,
                      reason: res.data.trade.AmendmentReason,
                    });
                  }
                  if (res.data.actions.length > 0) {
                    if (
                      res.data.actions[0].VerificationUser != null &&
                      res.data.actions[0].VerificationUser != null
                    ) {
                      this.$refs.ViewSelectedTrade.checkVerify = true;
                      actionsArray.push({
                        type: "Verified",
                        user: res.data.actions[0].VerificationUser ?? null,
                        date: res.data.actions[0].VerificationDateTime,
                        reason: "-",
                      });
                    }
                  }

                  if (res.data.actions.length > 0) {
                    if (
                      res.data.actions[0].JournalUser != null &&
                      res.data.actions[0].JournalUser != null
                    ) {
                      actionsArray.push({
                        type: "Journal Created",
                        user: res.data.actions[0].JournalUser ?? null,
                        date: res.data.actions[0].JournalDateTime,
                        reason: "-",
                      });
                    }
                  }

                  if (
                    res.data.trade.CancelledBy != null &&
                    res.data.trade.CancelledBy != null
                  ) {
                    actionsArray.push({
                      type: "Canceled",
                      user: res.data.trade.CancelledBy ?? null,
                      date:
                        res.data.trade.CancelDate.split(" ")[0] +
                        " " +
                        res.data.trade.CancelTime,
                      reason: res.data.trade.CancelReason,
                    });
                  }

                  if (res.data.actions.length > 0) {
                    if (
                      res.data.actions[0].ReversalInitiatedBy != null &&
                      res.data.actions[0].ReversalInitiatedBy != null
                    ) {
                      actionsArray.push({
                        type: "Reversal Initiated",
                        user: res.data.actions[0].ReversalInitiatedBy ?? null,
                        date: res.data.actions[0].ReversalInitiatedDateTime,
                        reason: res.data.actions[0].ReversalInitiatedReason,
                      });
                    }
                  }

                  if (res.data.actions.length > 0) {
                    if (
                      res.data.actions[0].ReversedBy != null &&
                      res.data.actions[0].ReversedBy != null
                    ) {
                      actionsArray.push({
                        type: "Reversed",
                        user: res.data.actions[0].ReversedBy ?? null,
                        date: res.data.actions[0].ReversedDateTime,
                        reason: "-",
                      });
                    }
                  }

                  this.$refs.ViewSelectedTrade.actionsData = actionsArray;
                } else {
                  this.notFoundMessage();
                }
              })
              .catch((error) => {
                console.log(error);
              });
          } else {
            this.tradeIDDoesntZeroMessage();
          }
        } else {
          this.notAuthToastMessage();
        }
      });
    },
    copySelectedTrade(item) {
      this.$checkAccessRight("EquityBond", "Save").then((res) => {
        if (res.data.valid) {
          store
            .dispatch("equity&bond/getCopySelectedTrade", {
              auth: this.user,
              tradeId: item.ID,
              tcUti: item.TcUti,
            })
            .then((res) => {
              if (res.data.info == "Pass") {
                if (this.getChoice()) {
                  this.$refs.refCopyTrade.getAllSecurityDesc();
                  this.$refs.refCopyTrade.fetchedTradeData = res.data.trade;
                  this.$refs.refCopyTrade.copyTradeModal = true;
                  this.$refs.refCopyTrade.getAllAccounts();

                  //set timeline datas
                  let actionsArray = [];
                  if (
                    res.data.trade.SavedBy != null &&
                    res.data.trade.SavedBy != null
                  ) {
                    actionsArray.push({
                      type: "Saved",
                      user: res.data.trade.SavedBy ?? null,
                      date:
                        res.data.trade.SaveDate.split(" ")[0] +
                        " " +
                        res.data.trade.SaveTime,
                      reason: "-",
                    });
                  }

                  if (
                    res.data.trade.AmendedBy != null &&
                    res.data.trade.AmendedBy != null
                  ) {
                    actionsArray.push({
                      type: "Amended",
                      user: res.data.trade.AmendedBy ?? null,
                      date:
                        res.data.trade.AmendmentDate.split(" ")[0] +
                        " " +
                        res.data.trade.AmendmentTime,
                      reason: res.data.trade.AmendmentReason,
                    });
                  }
                  if (res.data.actions.length > 0) {
                    if (
                      res.data.actions[0].VerificationUser != null &&
                      res.data.actions[0].VerificationUser != null
                    ) {
                      actionsArray.push({
                        type: "Verified",
                        user: res.data.actions[0].VerificationUser ?? null,
                        date: res.data.actions[0].VerificationDateTime,
                        reason: "-",
                      });
                    }
                  }

                  if (res.data.actions.length > 0) {
                    if (
                      res.data.actions[0].JournalUser != null &&
                      res.data.actions[0].JournalUser != null
                    ) {
                      actionsArray.push({
                        type: "Journal Created",
                        user: res.data.actions[0].JournalUser ?? null,
                        date: res.data.actions[0].JournalDateTime,
                        reason: "-",
                      });
                    }
                  }

                  if (
                    res.data.trade.CancelledBy != null &&
                    res.data.trade.CancelledBy != null
                  ) {
                    actionsArray.push({
                      type: "Canceled",
                      user: res.data.trade.CancelledBy ?? null,
                      date:
                        res.data.trade.CancelDate.split(" ")[0] +
                        " " +
                        res.data.trade.CancelTime,
                      reason: res.data.trade.CancelReason,
                    });
                  }

                  if (res.data.actions.length > 0) {
                    if (
                      res.data.actions[0].ReversalInitiatedBy != null &&
                      res.data.actions[0].ReversalInitiatedBy != null
                    ) {
                      actionsArray.push({
                        type: "Reversal Initiated",
                        user: res.data.actions[0].ReversalInitiatedBy ?? null,
                        date: res.data.actions[0].ReversalInitiatedDateTime,
                        reason: res.data.actions[0].ReversalInitiatedReason,
                      });
                    }
                  }

                  if (res.data.actions.length > 0) {
                    if (
                      res.data.actions[0].ReversedBy != null &&
                      res.data.actions[0].ReversedBy != null
                    ) {
                      actionsArray.push({
                        type: "Reversed",
                        user: res.data.actions[0].ReversedBy ?? null,
                        date: res.data.actions[0].ReversedDateTime,
                        reason: "-",
                      });
                    }
                  }

                  this.$refs.refCopyTrade.actionsData = actionsArray;
                } else {
                  let verifyList = [];
                  verifyList.push(item);
                  this.tradeMultipleSelectCopyOpr(1, verifyList);
                }
              } else {
                this.notFoundMessage();
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          this.notAuthToastMessage();
        }
      });
    },
    getAmendDetails(item) {
      this.$checkAccessRight("EquityBond", "View").then((res) => {
        if (res.data.valid) {
          if (item.Cancelled == 0) {
            if (item.ID != 0) {
              this.$router.push({
                path: `/equity&bond/amend-details/${item.ID}`,
              });
            } else {
              this.tradeIDDoesntZeroMessage();
            }
          } else {
            this.canceledToastMessage();
          }
        }
      });
    },
    viewJournalEntries(item) {
      this.$checkAccessRight("Journal", "View").then((res) => {
        if (res.data.valid) {
          if (item.Cancelled == 0) {
            this.checkJournalCreated("JournalDisplay", item.TcUti).then(
              (res) => {
                if (res.data.info == true) {
                  store
                    .dispatch("equity&bond/getViewJournalTrade", {
                      auth: this.user,
                      tradeId: item.ID,
                      tcUti: item.TcUti,
                      trade: item,
                    })
                    .then((res) => {
                      if (res.data.info == "Pass") {
                        this.$refs.refViewJournalEntries.journalEntries =
                          res.data.entries;

                        this.$refs.refViewJournalEntries.createJournalOperation = false;

                        //set timeline datas
                        let actionsArray = [];
                        if (
                          res.data.trade.SavedBy != null &&
                          res.data.trade.SavedBy != null
                        ) {
                          actionsArray.push({
                            type: "Saved",
                            user: res.data.trade.SavedBy ?? null,
                            date:
                              res.data.trade.SaveDate.split(" ")[0] +
                              " " +
                              res.data.trade.SaveTime,
                            reason: "-",
                          });
                        }

                        if (
                          res.data.trade.AmendedBy != null &&
                          res.data.trade.AmendedBy != null
                        ) {
                          actionsArray.push({
                            type: "Amended",
                            user: res.data.trade.AmendedBy ?? null,
                            date:
                              res.data.trade.AmendmentDate.split(" ")[0] +
                              " " +
                              res.data.trade.AmendmentTime,
                            reason: res.data.trade.AmendmentReason,
                          });
                        }
                        if (res.data.actions.length > 0) {
                          if (
                            res.data.actions[0].VerificationUser != null &&
                            res.data.actions[0].VerificationUser != null
                          ) {
                            this.$refs.refViewJournalEntries.checkVerify = true;
                            actionsArray.push({
                              type: "Verified",
                              user:
                                res.data.actions[0].VerificationUser ?? null,
                              date: res.data.actions[0].VerificationDateTime,
                              reason: "-",
                            });
                          }
                        }

                        if (res.data.actions.length > 0) {
                          if (
                            res.data.actions[0].JournalUser != null &&
                            res.data.actions[0].JournalUser != null
                          ) {
                            actionsArray.push({
                              type: "Journal Created",
                              user: res.data.actions[0].JournalUser ?? null,
                              date: res.data.actions[0].JournalDateTime,
                              reason: "-",
                            });
                          }
                        }

                        if (
                          res.data.trade.CancelledBy != null &&
                          res.data.trade.CancelledBy != null
                        ) {
                          actionsArray.push({
                            type: "Canceled",
                            user: res.data.trade.CancelledBy ?? null,
                            date:
                              res.data.trade.CancelDate.split(" ")[0] +
                              " " +
                              res.data.trade.CancelTime,
                            reason: res.data.trade.CancelReason,
                          });
                        }

                        if (res.data.actions.length > 0) {
                          if (
                            res.data.actions[0].ReversalInitiatedBy != null &&
                            res.data.actions[0].ReversalInitiatedBy != null
                          ) {
                            actionsArray.push({
                              type: "Reversal Initiated",
                              user:
                                res.data.actions[0].ReversalInitiatedBy ?? null,
                              date:
                                res.data.actions[0].ReversalInitiatedDateTime,
                              reason:
                                res.data.actions[0].ReversalInitiatedReason,
                            });
                          }
                        }

                        if (res.data.actions.length > 0) {
                          if (
                            res.data.actions[0].ReversedBy != null &&
                            res.data.actions[0].ReversedBy != null
                          ) {
                            actionsArray.push({
                              type: "Reversed",
                              user: res.data.actions[0].ReversedBy ?? null,
                              date: res.data.actions[0].ReversedDateTime,
                              reason: "-",
                            });
                          }
                        }

                        this.$refs.refViewJournalEntries.actionsData = actionsArray;
                        this.$refs.refViewJournalEntries.viewJournalTradeModal = true;
                      } else {
                        this.notFoundMessage();
                      }
                    })
                    .catch((error) => {
                      console.log(error);
                    });
                } else {
                  this.checkJournalCreatedMessage(res.data.message);
                }
              }
            );
          } else {
            this.canceledToastMessage();
          }
        } else {
          this.notAuthToastMessage();
        }
      });
    },
    checkTradeOperations(trade) {
      return new Promise((resolve, reject) => {
        store
          .dispatch("equity&bond/checkTradeVerifyOperation", {
            auth: this.user,
            trade: trade,
          })
          .then((res) => {
            if (res.data.info == "not_verified") {
              store
                .dispatch("equity&bond/checkTradeUserOperation", {
                  auth: this.user,
                  trade: trade,
                })
                .then((res) => {
                  if (res.data.info == "user_doesnt_same") {
                    if (trade.EntryType == "Order") {
                      this.$swal({
                        title: this.$t("equity_bond.swal.order_trade"),
                        text: this.$t(
                          "equity_bond.swal.entry_saved_order_want_amend_trade"
                        ),
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonColor: "#3085d6",
                        cancelButtonColor: "#d33",
                        confirmButtonText: this.$t(
                          "equity_bond.swal.confirm_button"
                        ),
                        cancelButtonText: this.$t(
                          "equity_bond.swal.cancel_button"
                        ),
                        customClass: {
                          confirmButton: "btn btn-success mr-1",
                          cancelButton: "btn btn-danger",
                          denyButton: "btn btn-primary",
                        },
                        showClass: {
                          popup: "animate__animated animate__fadeInDown",
                        },
                        hideClass: {
                          popup: "animate__animated animate__fadeOutUp",
                        },
                      }).then((result) => {
                        if (result.isConfirmed) {
                          this.amendUpdate(trade);
                        }
                      });
                    } else {
                      resolve(true);
                    }
                  } else if (
                    res.data.info == "same_user_amend" ||
                    res.data.info == "same_user_save"
                  ) {
                    this.checkTradeUserOperationMessage(res.data.message, 8000);
                  } else {
                    this.checkTradeUserOperationMessage(res.data.message, 8000);
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              if (res.data.info == "verified") {
                this.checkTradeVerifyOperationLangMessage(
                  res.data.user,
                  res.data.date
                );
              } else {
                this.checkTradeVerifyOperationMessage(res.data.message);
              }
            }
          })
          .catch((error) => {
            console.log(error);
          });
      });
    },

    checkTradeVerifyOperationMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkTradeVerifyOperationLangMessage(user, date) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text:
            this.$t("backend_language_message.verify_trade1") +
            " " +
            user +
            " " +
            this.$t("backend_language_message.verify_trade_on") +
            " " +
            date,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkTradeUnVerifyOperationLangMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("backend_language_message.un_verify_message"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkTradeUserOperationMessage(message, timeout) {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Warning",
            text: message,
            icon: "AlertTriangleIcon",
            variant: "warning",
          },
        },
        {
          timeout,
        }
      );
    },
    checkJournalCreated(messageType, tcUti) {
      if (tcUti != null && tcUti != null) {
        return new Promise((resolve, reject) => {
          store
            .dispatch("equity&bond/checkTCUtiJournalCreated", {
              auth: this.user,
              type: messageType,
              tcUti: tcUti,
            })
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } else {
        this.errorMessage(this.$t("equity_bond.messages.tc_uti_not_found"));
      }
    },
    checkEntryReversed(messageType, tcUti) {
      if (tcUti != null && tcUti != null) {
        return new Promise((resolve, reject) => {
          store
            .dispatch("equity&bond/checkTCUtiReserved", {
              auth: this.user,
              type: messageType,
              tcUti: tcUti,
            })
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } else {
        this.errorMessage(this.$t("equity_bond.messages.tc_uti_not_found"));
      }
    },
    checkReversalInitiated(messageType, tcUti) {
      if (tcUti != null && tcUti != null) {
        return new Promise((resolve, reject) => {
          store
            .dispatch("equity&bond/checkReversalInitiated", {
              auth: this.user,
              type: messageType,
              tcUti: tcUti,
            })
            .then((res) => {
              resolve(res);
            })
            .catch((error) => {
              console.log(error);
              reject(error);
            });
        });
      } else {
        this.errorMessage(this.$t("equity_bond.messages.tc_uti_not_found"));
      }
    },
    checkJournalCreatedMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkEntryReversedMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    checkReversalInitiatedMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    databaseErrorMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t("equity_bond.toast_messages.database_connection_error"),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    errorMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    notFoundMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: this.$t("equity_bond.toast_messages.data_not_found"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    canceledToastMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("equity_bond.messages.trade_cancelled_message"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    cancelOpearionNotDoneMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("equity_bond.messages.cancelled_operation_not_done"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    multiSelectAllProcesesSuccessMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t("multiCheckBoxTitles.multiselect_processes_success"),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    multiSelectAllProcesesWarningMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("multiCheckBoxTitles.multiselect_processes_warning"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    multiSelectCopyWarningMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("multiCheckBoxTitles.multiselect_copy_warning"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    multiSelectAllTradesCopySuccessMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t("multiCheckBoxTitles.multiselect_copy_success"),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },
    sendedMailSuccess() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t("transfers.mail.mail_send_success"),
          icon: "AlertTriangleIcon",
          variant: "success",
        },
      });
    },
    tradeCancelledErrorMessage(message) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    },
    wrongToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          text: val,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    warningToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: val,
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    notFoundSearchData() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Not Found Search Data",
          text: this.$t("equity_bond.messages.not_found_search_data"),
          icon: "UserXIcon",
          variant: "warning",
        },
      });
    },
    notAuthToastMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Not Authorized",
          text: this.$t("equity_bond.general_messages.not_authorized_message"),
          icon: "UserXIcon",
          variant: "warning",
        },
      });
    },
    tradeCancelledMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Successfully",
          text: this.$t("equity_bond.toast_messages.trade_cancelled_success"),
          icon: "ThumbsUpIcon",
          variant: "success",
        },
      });
    },

    tradeIDDoesntZeroMessage(val) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("equity_bond.messages.trade_id_cannot_zero"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    validReasonTextMessage() {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Warning",
          text: this.$t("equity_bond.reason.enter_valid_reason"),
          icon: "AlertTriangleIcon",
          variant: "warning",
        },
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    dateFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("YYYY-MM-DD");
      }
    },
    dateFormatWithTime(val) {
      if (val != undefined) {
        return moment(String(val)).format("MM-DD-YYYY HH:mm:ss");
      }
    },
    DMYdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("DD-MM-YYYY");
      }
    },
    timeFormat(val) {
      if (val != undefined) {
        return moment(String(val)).format("HH:mm:ss");
      }
    },
    HmstimeFormat(val) {
      if (val != undefined) {
        return moment(String(val), "HH:mm:ss")._i;
      }
    },
    formatPrice(value, val) {
      if (value != null) {
        let val = (value / 1).toFixed(2).replace(",", ".");
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },
    YMDdateFormat(val) {
      if (val != undefined) {
        return moment(String(val), "DD-MM-YYYY").format("YYYY-MM-DD");
      }
    },
    clearTradeDate() {
      this.filterTradeDate = null;
      (this.tradeRangeDate = null),
        (this.filterTradeDateStart = null),
        (this.filterTradeDateEnd = null),
        this.runLastSearch();
    },
    clearSettlementDate() {
      this.filterSettlementDate = null;
      (this.settleRangeDate = null),
        (this.filterSettleDateStart = null),
        (this.filterSettleDateEnd = null),
        this.runLastSearch();
    },
    handleScroll(e) {
      // this.windowTop = window.top.scrollY
      // var sticky = this.$refs.refEquityBondTable.$el.offsetTop;
      // if(this.windowTop + -30 > sticky) {
      //   this.$refs.refEquityBondTable.$el.children[0].children[0].classList.add("fixed_header_sticky")
      //   //console.log(this.$refs.refEquityBondTable.$el.children[0].children[0])
      // } else {
      //   this.$refs.refEquityBondTable.$el.children[0].children[0].classList.remove("fixed_header_sticky")
      // }
    },
  },
  watch: {
    perPage: {
      handler: function(val, before) {
        localStorage.setItem("equityShow", val);
      },
    },
    tradeRangeDate: {
      handler: function(val, before) {
        if (val) {
          if (val.includes("to")) {
            this.filterTradeDateStart = this.tradeRangeDate.split(" to ")[0];
            this.filterTradeDateEnd = this.tradeRangeDate.split(" to ")[1];

            // this.getFilterTradeDate();
            if (
              this.filterTradeDateStart != "null" &&
              this.filterTradeDateEnd != "null"
            ) {
              //this.getFilterTradeDate();
            } else {
              this.filterTradeDateStart = null;
              this.filterTradeDateEnd = null;
            }
          }
        }
      },
    },
    settleRangeDate: {
      handler: function(val, before) {
        if (val) {
          if (val.includes("to")) {
            this.filterSettleDateStart = this.settleRangeDate.split(" to ")[0];
            this.filterSettleDateEnd = this.settleRangeDate.split(" to ")[1];

            // this.listRefresh();
            if (
              this.filterSettleDateStart != "null" &&
              this.filterSettleDateEnd != "null"
            ) {
              //this.listRefresh();
            } else {
              this.filterSettleDateStart = null;
              this.filterSettleDateEnd = null;
            }
          }
        }
      },
    },
    treeTablecolumns: {
      handler: function(val, before) {
        this.loading = true;
        if (val.length > 0) {
          let filteredColumns = [];
          filteredColumns.push({
            stickyColumn: true,
            key: "ID",
            label: "ID",
            // thClass: "text-left",
            tdClass: "text-center ID_background",
            // thStyle: 'width: 30%',
            // sortable: true,
            class: "text-start sticky-column",
            variant: "primary",
          });
          this.tableOrjinalColumns.forEach((o) => {
            if (val.find((v) => v.value == o.key)) {
              filteredColumns.push(o);
            }
          });
          this.tableColumns = filteredColumns;
        } else {
          this.tableColumns = this.tableOrjinalColumns;
        }
        this.loading = false;
      },
    },
    treeStatus: {
      handler: function(val, before) {
        let allFilteredData = [];

        if (val.length > 0) {
          val.forEach((s) => {
            if (s.value == "Execution") {
              let allRawData = JSON.parse(
                JSON.stringify(this.allEquityBondNotFilterData)
              );
              let filteredData = _.filter(allRawData, {
                EntryType: "Execution",
              });
              if (filteredData.length > 0) {
                filteredData.forEach((d) => {
                  allFilteredData.push(d);
                });
              }
            } else if (s.value == "Verified") {
              let allRawData = JSON.parse(
                JSON.stringify(this.allEquityBondNotFilterData)
              );
              let filteredData = _.filter(allRawData, {
                Verified: 1,
                JournalCreated: 0,
                ReversalInitiated: 0,
                Reversed: 0,
                Cancelled: 0,
              });
              if (filteredData.length > 0) {
                filteredData.forEach((d) => {
                  allFilteredData.push(d);
                });
              }
            } else if (s.value == "Journal Created") {
              let allRawData = JSON.parse(
                JSON.stringify(this.allEquityBondNotFilterData)
              );
              let filteredData = _.filter(allRawData, {
                Verified: 1,
                JournalCreated: 1,
                ReversalInitiated: 0,
                Reversed: 0,
                Cancelled: 0,
              });
              if (filteredData.length > 0) {
                filteredData.forEach((d) => {
                  allFilteredData.push(d);
                });
              }
            } else if (s.value == "Reverse Initiated") {
              let allRawData = JSON.parse(
                JSON.stringify(this.allEquityBondNotFilterData)
              );
              let filteredData = _.filter(allRawData, {
                Verified: 1,
                JournalCreated: 1,
                ReversalInitiated: 1,
                Reversed: 0,
                Cancelled: 0,
              });
              if (filteredData.length > 0) {
                filteredData.forEach((d) => {
                  allFilteredData.push(d);
                });
              }
            } else if (s.value == "Reversed") {
              let allRawData = JSON.parse(
                JSON.stringify(this.allEquityBondNotFilterData)
              );
              let filteredData = _.filter(allRawData, {
                Verified: 1,
                JournalCreated: 1,
                ReversalInitiated: 1,
                Reversed: 1,
                Cancelled: 0,
              });
              if (filteredData.length > 0) {
                filteredData.forEach((d) => {
                  allFilteredData.push(d);
                });
              }
            } else if (s.value == "Cancelled") {
              let allRawData = JSON.parse(
                JSON.stringify(this.allEquityBondNotFilterData)
              );
              let filteredData = _.filter(allRawData, {
                Cancelled: 1,
              });

              if (filteredData.length > 0) {
                filteredData.forEach((d) => {
                  allFilteredData.push(d);
                });
              }
            }
          });

          let uniqueData = _.uniqBy(allFilteredData, function(e) {
            return e.ID;
          });

          this.allEquityBondsData = uniqueData;
          this.totalRows = uniqueData.length;
        } else {
          this.allEquityBondsData = this.allEquityBondNotFilterData;
          this.totalRows = this.allEquityBondNotFilterData.length;
        }
      },
    },
    selectedMultipleTrades: {
      handler: function(val, old) {
        if (val.length > 0) {
          this.multiSelectListStatusData = [];
          if (
            _.filter(val, {
              EntryType: "Execution",
              Verified: 0,
              JournalCreated: 0,
              ReversalInitiated: 0,
              Reversed: 0,
              Cancelled: 0,
            }).length > 0
          ) {
            this.multiSelectListStatusData.push({
              key: "Verify",
              title: this.$t("multiCheckBoxTitles.verify"),
              icon: "CheckSquareIcon",
              color: "green",
            });
          }

          if (
            _.filter(val, {
              EntryType: "Execution",
              Verified: 1,
              JournalCreated: 0,
              ReversalInitiated: 0,
              Reversed: 0,
              Cancelled: 0,
            }).length > 0
          ) {
            this.multiSelectListStatusData.push({
              key: "Create Journal",
              title: this.$t("multiCheckBoxTitles.journal"),
              icon: "ClipboardIcon",
              color: "#0b4666",
            });
          }

          if (
            _.filter(val, {
              EntryType: "Execution",
              Verified: 1,
              JournalCreated: 1,
              ReversalInitiated: 0,
              Reversed: 0,
              Cancelled: 0,
            }).length > 0
          ) {
            this.multiSelectListStatusData.push({
              key: "Revers Inititated",
              title: this.$t("multiCheckBoxTitles.revers_initiated"),
              icon: "PlayIcon",
              color: "rgb(255, 255, 153)",
            });
          }

          if (
            _.filter(val, {
              EntryType: "Execution",
              Verified: 1,
              JournalCreated: 1,
              ReversalInitiated: 1,
              Reversed: 0,
              Cancelled: 0,
            }).length > 0
          ) {
            this.multiSelectListStatusData.push({
              key: "Reversed",
              title: this.$t("multiCheckBoxTitles.reversed"),
              icon: "CornerUpLeftIcon",
              color: "coral",
            });
          }

          if (
            _.filter(val, {
              EntryType: "Execution",
              Verified: 1,
              JournalCreated: 1,
              ReversalInitiated: 1,
              Reversed: 0,
              Cancelled: 0,
            }).length > 0
          ) {
            this.multiSelectListStatusData.push({
              key: "Undo Initiate Reversal",
              title: this.$t("multiCheckBoxTitles.undo_initiate_reversal"),
              icon: "ChevronsLeftIcon",
              color: "black",
            });
          }

          if (
            _.filter(val, {
              Cancelled: 0,
            }).length > 0
          ) {
            this.multiSelectListStatusData.push({
              key: "Cancel",
              title: this.$t("multiCheckBoxTitles.cancel"),
              icon: "XSquareIcon",
              color: "#ea5455",
            });
          }
        } else {
          this.multiSelectListStatusData = [];
        }
      },
    },
  },
};
</script>

<style>
.treeSelect .vue-treeselect__control {
  height: 38px !important;
  max-height: 38px !important;
}

.table-executed {
  background-color: #e9fafa !important;
}

.table-verified {
  background-color: #00ff7f !important;
}

.table-journalCreate {
  background-color: #e1d7f5 !important;
}

.table-expiredOrder {
  background-color: silver !important;
}

.table-reverseInitiated {
  background-color: #ffff99 !important;
}

.table-reversed {
  background-color: #f8ac91 !important;
}

.table-canceled {
  background-color: #ffb8b8 !important;
}

.TcUti {
  width: 100%;
}

.column_search_input {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  min-width: 150px !important;
  min-height: 38.59px !important;
}

.ID_column_search_input {
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #d8d6de;
  border-radius: 0.357rem;
  min-height: 38.59px !important;
}

.sticky-column {
  position: sticky;
  background-color: #fff;
  z-index: 1;
}
.sticky-column:nth-child(1) {
  left: 0;
}
.sticky-column:nth-child(2) {
  left: 50px;
}
.other-col {
  z-index: 0;
}

#table-container {
  display: flex;

  flex-wrap: nowrap;

  overflow-x: auto;
}

#table-container::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.ID_background {
  background-color: gainsboro !important;
}
</style>

<style lang="scss" scoped>
.top-btns {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "~vue-context/src/sass/vue-context";
</style>
